import React, { useState, useEffect } from 'react';
import DOMPurify from 'dompurify';
import { useCompanyContext } from '../../Contexts/CompanyContext';
import './TermsConditions.css';
import MobileFooter from '../MobileFooter/MobileFooter';
import Footer from '../Footer/Footer';

const UserAgreement = () => {
    const { companyDetails } = useCompanyContext();
    const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const sanitizedHtml = companyDetails?.agreement
        ? DOMPurify.sanitize(companyDetails.agreement)
        : "Not available";

    const terms = companyDetails?.agreement || "Not available";

    return (
        <>
            <div
                className="terms-container"
                style={{
                    padding: isMobileView ? '20px' : '40px 80px',
                    position: 'relative',
                    minHeight: '600px',
                }}
            >
                <div className="terms-content">
                    <h2 className="terms-title">User Agreement</h2>
                    <div className="terms-clauses" dangerouslySetInnerHTML={{ __html: sanitizedHtml }}>
                        {/* {terms.split('\n').map((clause, index) => (
                            <div key={index} className="terms-clause">
                                <p>{clause}</p>
                            </div>
                        ))} */}
                        {/* {terms.split(/(?=\d+\.)/).map((clause, index) => (
                            <div key={index} className="terms-clause">
                                <p>{clause}</p>
                            </div>
                        ))} */}
                        {/* <div className="terms-clause">
                            {terms.split('\n').map((clause, index) => (
                                <p key={index}>{clause}</p>
                            ))}
                        </div> */}
                    </div>
                </div>
            </div>
            <Footer />
            {isMobileView && <MobileFooter />}
        </>
    );
};

export default UserAgreement;
