import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Form from 'react-bootstrap/Form';
import Swal from 'sweetalert2';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import TextField from '@mui/material/TextField'; // Import TextField from Material-UI
import './ForgotPassword.css';
import image from './eShop-LOGO-BlackE.png';
import Grid from '@mui/material/Grid';
import { useCompanyContext } from '../../Contexts/CompanyContext';
import NoLogo from './No_logo.png';

const Password = () => {
    const apiUrl = process.env.REACT_APP_API_BASE_URL;
    const forgot_password = '/forgot_password';
    const forgot_password_url = `${apiUrl}${forgot_password}`;
    const update_password = '/update_password';
    const update_password_url = `${apiUrl}${update_password}`;
    const [newAddress, setNewAddress] = useState({
        email: '',
        new_password: '',
        confirm_new_password: ''
    });
    const [emailExists, setEmailExists] = useState(true);
    const companyID = localStorage.getItem('companyID');
    const companyName = localStorage.getItem('companyName');
    const navigate = useNavigate();
    const location = useLocation();
    const [emailVerified, setEmailVerified] = useState(false);
    const [otpVerified, setOtpVerified] = useState(false);
    const [otpFromServer, setOtpFromServer] = useState('');
    const [timer, setTimer] = useState(60);
    const [otpSent, setOtpSent] = useState(false);
    const [otpExpired, setOtpExpired] = useState(false);
    const { companyDetails } = useCompanyContext();
    const dynamicURL = companyID ? `/home/${companyID}` : '/';
    const logoSrc = companyDetails ? `data:image/png;base64,${atob(companyDetails.company_image)}` : '';

    useEffect(() => {
        let interval;
        if (otpSent && timer > 0 && !otpVerified) {
            interval = setInterval(() => {
                setTimer(prevTimer => prevTimer - 1);
            }, 1000);
        } else if (timer === 0) {
            clearInterval(interval);
            setOtpExpired(true);
        }
        return () => clearInterval(interval);
    }, [otpSent, timer, otpVerified]);

    const handleReChange = (e) => {
        setNewAddress({
            ...newAddress,
            [e.target.name]: e.target.value
        });
    };

    const submitNewPassword = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(forgot_password_url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email: newAddress.email, companyName: companyName })
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();

            if (data.result.success === 'NO') {
                alert(data.result.message, 'data of the forgot password');
                setEmailExists(false);
            }
            if (data.result.success === 'YES') {
                Swal.fire({
                    icon: 'success',
                    title: 'Otp Sent! Please check your mail',
                    customClass: {
                        confirmButton: 'custom-confirm-button'
                    }
                });
                setEmailExists(true); // Email exists, set to true
                setEmailVerified(true);
                setOtpFromServer(data.result.emailOtp);
                setOtpSent(true);
                setTimer(60); // Reset the timer to 60 seconds
            }
        } catch (error) {
            console.error("Failed to add address:", error);
        }
    };

    const validateOtp = () => {
        if (newAddress.passcode === otpFromServer) {
            setOtpVerified(true);
            setTimer(0); // Stop the timer when OTP is correct
            Swal.fire({
                icon: 'success',
                title: 'OTP Verified',
                text: 'Please reset your password',
                customClass: {
                    confirmButton: 'custom-confirm-button'
                }
            });
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'OTP Invalid',
                customClass: {
                    confirmButton: 'custom-confirm-button'
                }
            });
        }
    };

    const updatePassword = async (e) => {
        e.preventDefault();

        if (newAddress.new_password !== newAddress.confirm_new_password) {
            Swal.fire({
                icon: 'warning',
                text: 'Passwords do not match',
                customClass: {
                    confirmButton: 'custom-confirm-button'
                }
            });
            return;
        }

        const response = await fetch(update_password_url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email: newAddress.email,
                password: newAddress.new_password,
                confirmPassword: newAddress.confirm_new_password,
                companyName: companyName,
            }),
        });

        const responseData = await response.json();

        if (responseData.result.success) {
            Swal.fire({
                icon: 'success',
                title: 'Password Updated',
                customClass: {
                    confirmButton: 'custom-confirm-button'
                }
            }).then(() => {
                navigate('/login');
            });
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Password cannot be updated',
                customClass: {
                    confirmButton: 'custom-confirm-button'
                }
            });
        }
    };

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
    };

    return (
        <>
            <nav className="navbar">
                <div className="navbar-image">
                    <Link to={dynamicURL}>
                        {logoSrc ? <img src={logoSrc} className="logo" alt="Company Logo"
                            style={{ height: '60px', width: '60px', borderRadius: '50%', marginTop: '4px', border: '1px solid black', marginBottom: '1px' }} /> : <img src={NoLogo} className="logo" alt="Company Logo"
                                style={{ height: '60px', width: '60px', borderRadius: '50%', marginTop: '4px', border: '1px solid black', marginBottom: '1px' }} />}
                    </Link>
                </div>
            </nav>
            <div className='forget-page-container'>
                <div className="e_login"
                // style={{ marginTop: '150px', marginBottom: '150px' }}
                >
                    <div className="login">
                        <div className="e_login_content">
                            {/* <div className="">
                            <img src={image} alt="" />
                            </div> */}
                            <div className="">
                                <Link to={dynamicURL}>
                                    {logoSrc ? <img src={logoSrc} style={{ width: '60px', height: '60px', borderRadius: '50%', border: '1px solid gray' }} alt="Company Logo" /> : <img src={NoLogo} style={{ width: '60px', height: '60px', borderRadius: '50%', border: '1px solid gray' }} alt="No logo" />}
                                </Link>
                            </div>
                            <br />
                            <h1 className="e_head">Reset Password</h1>
                            <Form>
                                <Grid container spacing={1} alignItems="center" style={{ width: '100%' }}>
                                    <Grid item xs={10}>
                                        <TextField
                                            autoFocus
                                            margin="normal"
                                            id="email"
                                            name="email"
                                            label="Email"
                                            type="email"
                                            size='small'
                                            value={newAddress.email}
                                            onChange={handleReChange}
                                            fullWidth
                                            required
                                            disabled={otpVerified}
                                            error={!emailExists}
                                            helperText={!emailExists ? "Email does not exist" : ""}
                                            style={{
                                                borderColor: emailVerified ? 'green' : undefined,
                                                '&:hover': {
                                                    borderColor: emailVerified ? 'green' : undefined,
                                                },
                                                '&:focus': {
                                                    borderColor: emailVerified ? 'green' : undefined,
                                                },
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={2} sx={{ mt: 0, mb: 0 }}>
                                        {!emailVerified && (
                                            <Button variant='contained' sx={{ marginTop: '7px' }} style={{ backgroundColor: '#ff7043' }} onClick={(e) => submitNewPassword(e)}>Send</Button>
                                        )}
                                    </Grid>
                                </Grid>
                                <>
                                    {emailVerified && (
                                        <>
                                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                                <TextField
                                                    autoFocus
                                                    margin="dense"
                                                    id="passcode"
                                                    name="passcode"
                                                    label="Enter OTP"
                                                    size='small'
                                                    type="text"
                                                    value={newAddress.passcode}
                                                    onChange={handleReChange}
                                                    fullWidth
                                                    required
                                                    disabled={otpVerified}
                                                />
                                                {!otpVerified && (
                                                    <Button
                                                        variant='contained'
                                                        onClick={(e) => submitNewPassword(e)}
                                                        disabled={timer > 0}
                                                        style={{ backgroundColor: '#ff7043' }}>
                                                        Resend
                                                    </Button>
                                                )}
                                            </div>
                                            {!otpVerified && (
                                                <span>{formatTime(timer)}</span>
                                            )}
                                        </>
                                    )}
                                    {otpVerified && (
                                        <>
                                            <TextField
                                                autoFocus
                                                margin="dense"
                                                id="new_password"
                                                name="new_password"
                                                label="New Password"
                                                type="password"
                                                size='small'
                                                value={newAddress.new_password}
                                                onChange={handleReChange}
                                                fullWidth
                                                required
                                            />
                                            <TextField
                                                autoFocus
                                                margin="dense"
                                                id="confirm_new_password"
                                                name="confirm_new_password"
                                                label="Confirm New Password"
                                                type="password"
                                                size='small'
                                                value={newAddress.confirm_new_password}
                                                onChange={handleReChange}
                                                fullWidth
                                                required
                                            />
                                        </>
                                    )}
                                </>

                                {/* {!emailVerified && (
                                <>
                                    <br />
                                    <Button variant='contained' sx={{ marginTop: '7px' }} onClick={(e) => submitNewPassword(e)}>Send OTP</Button>
                                </>
                            )} */}
                                {emailVerified && !otpVerified && timer > 0 && (
                                    <Button variant='contained' sx={{ marginTop: '7px', marginLeft: '157px' }} onClick={validateOtp} style={{ backgroundColor: '#ff7043' }}>Validate OTP</Button>
                                )}
                                {otpVerified && (
                                    <Button variant='contained' sx={{ marginTop: '7px' }} onClick={(e) => updatePassword(e)} style={{ backgroundColor: '#ff7043', width: '100%' }}>Reset</Button>
                                )}
                                <span className="clearfix" />
                                {/* <button className="e_visited">Register</button> */}
                            </Form>
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
};

export default Password;

