import React, { useState, useEffect } from 'react';
import { useProductContext } from '../../Contexts/ProductContext';
import { useCartContext } from '../../Contexts/CartContext';
import { Link } from 'react-router-dom';
import './Wishlist.css';
import { useNavigate } from 'react-router-dom';
import delIcon from './delete_6861294.png';
import emptywishlist from './emptywishlist.png';
import Button from '@mui/material/Button';
import MobileFooter from '../MobileFooter/MobileFooter';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { IoMdCart } from "react-icons/io";
import { BsFillCartCheckFill } from "react-icons/bs";
import Trending from '../Trending/Trending';
import Footer from '../Footer/Footer';
import NoImg from './No-Image.png';


export const Wishlist = () => {
    const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768);
    const loggedinUser = localStorage.getItem('user');
    const [wishlistItems, setWishlistItems] = useState([]);
    const [wishlistProducts, setWishlistProducts] = useState([]);
    const user = JSON.parse(localStorage.getItem('user'));
    const { products } = useProductContext();
    const { addToCart } = useCartContext();
    const { cartItems } = useCartContext();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [dataFetched, setDataFetched] = React.useState(false);

    const apiUrl = process.env.REACT_APP_API_BASE_URL;
    const removeFromWishlistUrl = `${apiUrl}/remove_from_wishlist`;

    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // useEffect(() =>{
    const fetchWishlist = async (productDetail) => {
        if (user) {
            const apiUrl = process.env.REACT_APP_API_BASE_URL;
            const endpoint = '/fetch_wishlist';
            const url = `${apiUrl}${endpoint}`;

            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ user })
            };
            // setLoading(true);
            fetch(url, requestOptions)
                .then(response => response.json())
                .then(data => {
                    setWishlistItems(data.result.wishlist);
                })
                .catch(error => {
                    console.error('Error fetching wishlist:', error);
                })
                .finally(() => {
                    setLoading(false); // Stop loading
                    setDataFetched(true);
                });
        }
    };
    // }, [wishlistItems]);

    useEffect(() => {
        fetchWishlist();
    }, []);

    useEffect(() => {
        if (wishlistItems.length > 0 && products.length > 0) {
            const filteredProducts = products.filter(product => {
                return wishlistItems.includes(product.name);
            });
            setWishlistProducts(filteredProducts);
        }
    }, [wishlistItems, products]);

    const isInCart = (productId) => {
        return cartItems.some(item => item.id === productId);
    };

    const truncateNameForURL = (name, maxLength) => {
        const words = name.split(' ');
        if (words.length <= maxLength) {
            return words.join('-');
        }
        return words.slice(0, maxLength).join('-');
    };

    const addToCartWithApi = async (productDetail) => {
        try {

            addToCart(productDetail);

            const currentUser = JSON.parse(localStorage.getItem('user'));

            if (currentUser && currentUser.user) {
                const response = await fetch(`${apiUrl}/add_to_cart`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ user_id: currentUser.user_id, cartItems: productDetail }),
                });

                if (!response.ok) {
                    throw new Error('Failed to add product to cart');
                }

            } else {
                console.error('User not logged in'); // Log an error if user is not logged in
            }
        } catch (error) {
            console.error('Error adding product to cart:', error);
        }
    };

    const handleAddToCart = async (product) => {
        addToCartWithApi(product);

        try {
            const currentUserData = localStorage.getItem('user');
            const currentUser = JSON.parse(currentUserData);

            if (currentUser && currentUser.user_id) {
                const response = await fetch(removeFromWishlistUrl, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ user_id: currentUser.user_id, product_detail: product }),
                });

                if (!response.ok) {
                    throw new Error('Failed to remove from wishlist');
                }

                // If successful, update the wishlist state
                const updatedWishlistItems = wishlistItems.filter(item => item.id !== product.id);
                if (wishlistItems.length === 1) {
                    window.location.reload();
                }
                fetchWishlist();
                setWishlistItems(updatedWishlistItems);
                // window.location.reload();
            } else {
                console.error('User ID not found in currentUser data');
                navigate('/login');
            }
        } catch (error) {
            console.error('Error removing from wishlist:', error);
        }
    };

    const handleRemoveFromWishlist = async (product) => {
        try {
            const currentUserData = localStorage.getItem('user');
            const currentUser = JSON.parse(currentUserData);

            if (currentUser && currentUser.user_id) {
                const response = await fetch(removeFromWishlistUrl, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ user_id: currentUser.user_id, product_detail: product }),
                });

                if (!response.ok) {
                    throw new Error('Failed to remove from wishlist');
                }

                // If successful, update the wishlist state
                const updatedWishlistItems = wishlistItems.filter(item => item.id !== product.id);
                if (wishlistItems.length === 1) {
                    window.location.reload();
                }
                fetchWishlist();
                setWishlistItems(updatedWishlistItems);

                // window.location.reload();
            } else {
                console.error('User ID not found in currentUser data');
                navigate('/login');
            }
        } catch (error) {
            console.error('Error removing from wishlist:', error);
        }
    };

    if (!loggedinUser) {
        return (
            <div className="whislist" style={{ padding: isMobileView ? '0px 20px' : '0px 80px' }}>
                <div className="container">
                    {/* <h2 className="wishlist-title" style={{textAlign: 'center' }}>Wishlist</h2> */}
                    <div className="sidebar_widget wishlist">
                        <div className="empty-wishlist" style={{ minHeight: '1000px', textAlign: 'center' }}>
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />

                            <h5 style={{ textAlign: 'center', fontWeight: '300' }}>Please login to view your wishlist.</h5>
                            <br />
                            <Link to='/login'><Button size='small' variant='contained' style={{ backgroundColor: '#375E97' }}>Login</Button></Link>
                        </div>
                    </div>
                </div>
                {isMobileView && (
                    <MobileFooter />
                )}
            </div>
        );
    }

    if (loading) {
        return (
            // <Box display="flex" justifyContent="center" alignItems="center" style={{ height: '100vh' }}>
            //     <CircularProgress />
            // </Box>
            <div className='loader' style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: '9999' }}>
                <Box sx={{ display: 'flex' }}>
                    <CircularProgress size={60} />
                </Box>
            </div>
        );
    }

    if (!loading && wishlistProducts.length === 0) {
        return (
            <div className="whislist" style={{ padding: isMobileView ? '0px 20px' : '0px 80px' }}>
                <div className="container">
                    {/* <h2 className="wishlist-title" style={{textAlign: 'center' }}>Wishlist</h2> */}
                    <div className="sidebar_widget wishlist">
                        <div className="empty-wishlist" style={{ minHeight: '1000px', textAlign: 'center' }}>
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            {/* <img src={emptywishlist} alt="Empty Wishlist" style={{ maxWidth: '100%', height: 'auto' }}/> */}
                            <h5 style={{ textAlign: 'center', fontWeight: '300' }}>Your Wishlist is looking a little empty!<br /> Start adding your favorite items and make it uniquely yours. Happy shopping! </h5>
                            <br />
                            <Link to='/'><Button size='small' variant='contained' style={{ backgroundColor: '#ff7043' }}>Continue Shopping</Button></Link>
                        </div>
                    </div>
                </div>
                {isMobileView && (
                    <MobileFooter />
                )}
            </div>
        );
    }

    return (
        <>
            <div className="whislist" style={{ padding: isMobileView ? '0px 20px' : '0px 80px', position: 'relative' }}>
                <div className="container">
                    <h2 className="wishlist-title">My Wishlist</h2>
                    <div className="sidebar_widget wishlist mb-35">
                        {wishlistProducts.map((product, index) => (
                            <div className="cart_item" key={index}>


                                <div className="cart_img">
                                    <Link to={`/${product.category_hierarchy.join('/')}?product=${encodeURIComponent(truncateNameForURL(product.name, 4))}-${encodeURIComponent(product.id)}`}>
                                        <div className="image-containers">
                                            {product.sold && <div className="wishlist-ribbon">Sold Out</div>}
                                            <img src={product.image ? `data:image/jpeg;base64,${atob(product.image)}` : NoImg}
                                                alt={product.name} />
                                        </div>
                                    </Link>
                                </div>

                                <div className="cart_info">
                                    <Link to={`/${product.category_hierarchy.join('/')}?product=${encodeURIComponent(truncateNameForURL(product.name, 4))}-${encodeURIComponent(product.id)}`}>
                                        {product.name}
                                    </Link>
                                    <p style={{ fontSize: '13px', margin: '0px 0px' }}>{product.product_label ? product.product_label : "1 piece"}</p>
                                    <div className="cart_desc">{product.description}</div>
                                </div>
                                <div style={{ width: '2%' }}>
                                    <span className="cart_price">₹{product.price}</span>
                                </div>
                                <div className="cart_add">
                                    {/* {!product.sold && isInCart(product.id) ? (
                                        <div style={{ border: '0.5px solid #ff7043', borderRadius: '50%', padding: '2px 6px', height: '30px', width: '30px' }}>
                                            <BsFillCartCheckFill style={{ fill: '#ff7043' }} />
                                        </div>
                                        <Button variant='outlined' disabled style={{ borderColor: '#ff7043' }}>
                                            Added to Cart<BsFillCartCheckFill style={{ fill: '#ff7043' }} />
                                        </Button>
                                    ) : */}
                                    {!product.sold ? (
                                        <>
                                            <div style={{ border: '0.5px solid #ff7043', borderRadius: '50%', padding: '2px 6px', height: '30px', width: '30px', cursor: 'pointer' }} onClick={() => handleAddToCart(product)}>
                                                <IoMdCart style={{ fill: '#ff7043' }} />
                                            </div>
                                            {/* <Button variant='outlined' onClick={() => handleAddToCart(product)} style={{ borderColor: '#ff7043' }}>
                                                <IoMdCart style={{ fill: '#ff7043' }} />
                                            </Button> */}
                                        </>
                                    ) : (
                                        <div style={{ border: '0.5px solid grey', borderRadius: '50%', padding: '2px 6px', height: '30px', width: '30px' }}>
                                            <IoMdCart style={{ fill: 'grey' }} />
                                        </div>
                                    )}
                                </div>

                                <div className="cart_remove">
                                    <button className='btn btn-sm' title="Remove this item" onClick={() => handleRemoveFromWishlist(product)} style={{ marginTop: '0px' }}>
                                        <img src={delIcon} alt="delIconimg" height={20} />
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

            </div>
            <Trending />
            <br />
            <br />
            <Footer />
            {isMobileView && (
                <MobileFooter />
            )}
        </>
    );
};
