import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import Swal from 'sweetalert2';

const RedirectPayment = () => {
  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // Get transactionId from query params
    const queryParams = new URLSearchParams(location.search);
    const transactionId = queryParams.get('transactionId');


    // Fetch payment status from backend
    const fetchPaymentStatus = async () => {
      try {
        const response = await axios.post(apiUrl + '/api/payment/status/checker', {
          transactionId: transactionId
        });
        const status = response.data.result.payment_status;

        if (status === 'COMPLETED') {
          localStorage.removeItem('cartItems');
          Swal.fire({
            icon: 'success',
            title: 'Payment Successful!',
            text: 'Your payment was completed successfully.',
            confirmButtonText: 'OK',
            customClass: {
              confirmButton: 'custom-confirm-button'
            }
          }).then(() => {
            setTimeout(() => {
              navigate('/myorders');
              window.location.reload();
            }, 1000);
          });
        } else if (status === 'failed') {
          Swal.fire({
            icon: 'error',
            title: 'Payment Failed!',
            text: 'There was an issue with your payment. Please try again.',
            confirmButtonText: 'OK',
            customClass: {
              confirmButton: 'custom-confirm-button'
            }
          }).then(() => {
            setTimeout(() => {
              navigate('/');
            }, 1000);
          });
        } else {
          Swal.fire({
            icon: 'warning',
            title: 'Unknown Status',
            text: 'Something went wrong!',
            confirmButtonText: 'OK',
            customClass: {
              confirmButton: 'custom-confirm-button'
            }
          }).then(() => {
            setTimeout(() => {
              navigate('/');
            }, 1000);
          });
        }

        setLoading(false);
      } catch (error) {
        console.error('Error fetching payment status:', error);
        Swal.fire({
          icon: 'error',
          title: 'Error!',
          text: 'An error occurred while fetching payment status.',
          confirmButtonText: 'OK',
          customClass: {
            confirmButton: 'custom-confirm-button'
          }
        }).then(() => {
          setTimeout(() => {
            navigate('/');
          }, 1000);
        });
        setLoading(false);
      }
    };

    if (transactionId) {
      fetchPaymentStatus();
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'No transaction ID found!',
        confirmButtonText: 'OK',
        customClass: {
          confirmButton: 'custom-confirm-button'
        }
      }).then(() => {
        setTimeout(() => {
          navigate('/');
        }, 1000);
      });
      setLoading(false);
    }
  }, [location]);

  return (
    <div>
      {loading && (
        <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Redirecting...</span>
          </Spinner>
        </div>
      )}
    </div>
  );
};

export default RedirectPayment;
