import React, { useState, useEffect, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Cart.css'
import { useCartContext } from '../../Contexts/CartContext';
import { useProductContext } from '../../Contexts/ProductContext';
import emptyCart from './empty-cart.webp';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import StepBar from '../StepBar/StepBar';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import MobileFooter from '../MobileFooter/MobileFooter';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { FaChevronCircleLeft, FaChevronCircleRight } from "react-icons/fa";
import { IoMdCart } from "react-icons/io";
import Footer from '../Footer/Footer';
import NoImg from './No-Image.png';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import Swal from 'sweetalert2';

const CustomPrevArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick} style={{ display: 'block', zIndex: 1 }}>
      <FaChevronCircleLeft style={{ color: '#375E97', fontSize: '28px' }} />
    </div>
  );
};

const CustomNextArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick} style={{ display: 'block', zIndex: 1 }}>
      <FaChevronCircleRight style={{ color: '#375E97', fontSize: '28px' }} />
    </div>
  );
};

export default function Cart() {
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768);
  const { products } = useProductContext();
  const { addToCart, cartItems, removeFromCart, decreaseQuantity, increaseQuantity, setQuantity } = useCartContext();
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  const endpoint = '/checkout';
  const checkouturl = `${apiUrl}${endpoint}`;
  const [isInWishlist, setIsInWishlist] = useState(false);
  const [uniqueCategories, setUniqueCategories] = useState([]);
  const [showQuantitySnackbar, setShowQuantitySnackbar] = useState(false);
  const companyName = localStorage.getItem('companyName');
  const [wishlist, setWishlist] = useState([]);
  const wishlist_endpoint = '/add_to_wishlist';
  const wishlistUrl = `${apiUrl}${wishlist_endpoint}`;
  const currentUser = JSON.parse(localStorage.getItem('user'));
  const removeFromWishlistUrl = `${apiUrl}/remove_from_wishlist`;
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [showRemoveSnackbar, setShowRemoveSnackbar] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const filteredProducts = useMemo(() => {
    const categories = new Set(cartItems.map(item => item.category));
    return products.filter(product => categories.has(product.category));
  }, [cartItems, products]);

  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    arrows: true,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  // Function to group products into chunks of 4
  const groupProductsForMobile = (products) => {
    const groupedProducts = [];
    for (let i = 0; i < products.length; i += 4) {
      groupedProducts.push(products.slice(i, i + 4));
    }
    return groupedProducts;
  };

  const mobileGroupedProducts = groupProductsForMobile(filteredProducts);

  const decodeBase64Image = (base64) => {
    const decodedImage = new Image();
    decodedImage.src = `data:image/jpeg;base64,${atob(base64)}`;
    return decodedImage.src;
  };


  // const handleRemoveFromCart = (productId) => {
  //   removeFromCart(productId);
  // };

  const addToCartWithApi = async (productDetail) => {
    try {

      addToCart(productDetail);

      const currentUser = JSON.parse(localStorage.getItem('user'));

      if (currentUser && currentUser.user) {
        const response = await fetch(`${apiUrl}/add_to_cart`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ user_id: currentUser.user_id, cartItems: productDetail }),
        });

        if (!response.ok) {
          throw new Error('Failed to add product to cart');
        }

      } else {
        console.error('User not logged in'); // Log an error if user is not logged in
      }
    } catch (error) {
      console.error('Error adding product to cart:', error);
    }
  };

  const removeFromCartWithApi = async (productId) => {
    try {
      removeFromCart(productId);

      const currentUser = JSON.parse(localStorage.getItem('user'));

      if (currentUser && currentUser.user) {
        const response = await fetch(`${apiUrl}/remove_from_cart`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ user_id: currentUser.user_id, productId }),
        });

        if (!response.ok) {
          throw new Error('Failed to remove product from cart');
        }

      } else {
        console.error('User not logged in');
      }
    } catch (error) {
      console.error('Error removing product from cart:', error);
    }
  };

  const handleIncreaseQuantity = async (productId) => {
    const product = products.find(p => p.id === productId);
    const productInCart = cartItems.find(item => item.id === productId);

    increaseQuantity(productId);

    const currentUser = JSON.parse(localStorage.getItem('user'));

    if (currentUser && currentUser.user_id) {
      const response = await fetch(`${apiUrl}/increase_product_qty_in_cart`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ user_id: currentUser.user_id, productId }),
      });

      if (response.ok) {
        const responseData = await response.json();
      } else {
        console.error('Failed to increase quantity');
      }
    } else {
      console.error('User not logged in');
    }
  };

  const handleDecreaseQuantityBackend = async (productId) => {
    decreaseQuantity(productId); // Update the quantity in the local state

    const currentUser = JSON.parse(localStorage.getItem('user'));

    // Check if the current user is logged in
    if (currentUser && currentUser.user) {
      try {
        // Send a POST request to the backend to update the quantity
        const response = await fetch(`${apiUrl}/decrease_product_qty_in_cart`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ user_id: currentUser.user_id, productId }),
        });

        if (!response.ok) {
          throw new Error('Failed to decrease quantity');
        }

        const responseData = await response.json();
      } catch (error) {
        console.error('Error decrease quantity:', error);
      }
    }
  };

  const totalAmount = cartItems.reduce((total, item) => total + (item.price * item.quantity), 0);

  //proceed to checkout function
  const handleProceedToCheckout = async () => {
    const isLoggedIn = localStorage.getItem('user');

    if (isLoggedIn) {
      try {
        const body = {
          user: isLoggedIn ? JSON.parse(isLoggedIn).user : null,
          email: isLoggedIn ? JSON.parse(isLoggedIn).email : null,
          user_id: isLoggedIn ? JSON.parse(isLoggedIn).user_id : null,
          companyName: companyName,
          cartItems: cartItems.map(item => ({
            id: item.id,
            variant_id: item.variantId,
            name: item.name,
            price: item.price,
            quantity: item.quantity
          }))
        };

        const response = await fetch(checkouturl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),

        });

        const data = await response.json();
        navigate('/checkout', { state: { cartItems, totalAmount, additionalData: data, filteredProducts: filteredProducts } }); //navigate to payment page

      } catch (error) {
        console.error('Error:', error);
        // Handle error if API call fails
      }
    } else {
      // If the user is not logged in
      navigate('/login', { state: { fromCheckout: true } });
    }
  };

  const fetchWishlist = async () => {
    const currentUser = JSON.parse(localStorage.getItem('user'));

    const response = await fetch(`${apiUrl}/check_wishlist/user/app`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ user_id: currentUser.user_id })
    });

    if (response.ok) {
      const result = await response.json();
      const parsedResult = JSON.parse(result.result);
      setWishlist(parsedResult.wishlist);
    } else {
      console.error('Failed to fetch wishlist');
    }
  };

  useEffect(() => {
    if (currentUser) {
      fetchWishlist();
    }
  }, []);

  const wishlistNames = new Set(wishlist.map(item => item.product_name));

  const addToWishlist = async (productDetail) => {
    try {
      const currentUserData = localStorage.getItem('user');
      const currentUser = JSON.parse(currentUserData);

      if (currentUser && currentUser.user_id) {
        const response = await fetch(wishlistUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ user_id: currentUser.user_id, product_detail: productDetail }),
        });
        if (!response.ok) {
          throw new Error('Failed to add to wishlist');
        }
        setShowSnackbar(true);
        fetchWishlist();
      } else {
        console.error('User ID not found in currentUser data');
        Swal.fire({
          html: `
          Hi there! Log in to your account or create one today to enjoy a personalized shopping experience!
          `,
          showCloseButton: false,
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonText: `
            Login
          `,
          confirmButtonAriaLabel: "Login",
          cancelButtonText: `
            Register
          `,
          cancelButtonAriaLabel: "Register",
          customClass: {
            confirmButton: 'custom-confirm-button',
            cancelButton: 'custom-confirm-button'
          }
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = '/login'; // Redirect to login page
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            window.location.href = '/register'; // Redirect to register page
          }
        });
        // navigate('/login');
      }
    } catch (error) {
      console.error('Error adding to wishlist:', error);
    }
  };

  const removeFromWishlist = async (productDetail) => {
    try {
      const currentUserData = localStorage.getItem('user');
      const currentUser = JSON.parse(currentUserData);

      if (currentUser && currentUser.user_id) {
        const response = await fetch(removeFromWishlistUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ user_id: currentUser.user_id, product_detail: productDetail }),
        });

        if (!response.ok) {
          throw new Error('Failed to remove from wishlist');
        }
        setShowRemoveSnackbar(true);
        fetchWishlist();
      } else {
        console.error('User ID not found in currentUser data');
        navigate('/login');
      }
    } catch (error) {
      console.error('Error removing from wishlist:', error);
    }
  };

  const addToWishlistMain = async (productDetail) => {

    try {
      const currentUserData = localStorage.getItem('user');
      const currentUser = JSON.parse(currentUserData);

      if (currentUser && currentUser.user_id) {
        const response = await fetch(wishlistUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ user_id: currentUser.user_id, product_detail: productDetail }),
        });
        if (!response.ok) {
          throw new Error('Failed to add to wishlist');
        }
        setIsInWishlist(true);
        removeFromCartWithApi(productDetail.id);
      } else {
        console.error('User ID not found in currentUser data');
        navigate('/login');
      }
    } catch (error) {
      console.error('Error adding to wishlist:', error);
    }
  };


  const truncateNameForURL = (name, maxLength) => {
    const words = name.split(' ');
    if (words.length <= maxLength) {
      return words.join('-'); // If the number of words is less than or equal to maxLength, use all words
    }
    return words.slice(0, maxLength).join('-'); // Otherwise, use only the first maxLength words
  };


  return (
    <>
      <main style={{ padding: isMobileView ? '0px 20px' : '0px 80px' }}>
        {cartItems.length === 0 ? (
          <div className="empty-cart" style={{ minHeight: '1000px', textAlign: 'center' }}>
            <img src={emptyCart} alt="Empty Cart" style={{ maxWidth: '100%', height: 'auto', marginTop: '150px', marginBottom: '20px' }} />
            <br />
            <Link to='/'><Button variant='contained' style={{ backgroundColor: '#ff7043' }}>Continue Shopping</Button></Link>
            {isMobileView && (
              <MobileFooter />
            )}
          </div>
        ) : (
          <>
            <br />
            <br />
            <br />
            <br />
            <div className="e-add-cart" >
              <div className="e-add-cart">
                <div className="container-fluid">
                  <StepBar />
                  <div className="row">
                    <div className="col-lg-8">
                      <div className="table-content">
                        <table>
                          <thead>
                            <tr>
                              <th colSpan={2}>Product</th>
                              <th>Price</th>
                              <th>Quantity</th>
                              <th>Total</th>
                              <th />
                            </tr>
                          </thead>
                          <tbody>
                            {cartItems.map((item) => (
                              <tr key={item.id}>
                                <td className="ec-cart-pro-name">
                                  <Link
                                    to={`/${item.category_hierarchy.join('/')}?product=${encodeURIComponent(truncateNameForURL(item.name, 4))}-${encodeURIComponent(item.id)}`}
                                    style={{ textDecoration: 'none', color: 'inherit' }}>
                                    <img
                                      className="ec-cart-pro-img"
                                      src={item.image ? `data:image/jpeg;base64,${atob(item.image)}` : NoImg}
                                      alt=""
                                    />
                                  </Link>
                                  {/* <a href="">
                                  <img
                                    className="ec-cart-pro-img"
                                    src={`data:image/jpeg;base64,${atob(item.image)}`}
                                    alt=""
                                  />
                                </a> */}
                                </td>
                                <td data-label="Product">
                                  <Link
                                    to={`/${item.category_hierarchy.join('/')}?product=${encodeURIComponent(truncateNameForURL(item.name, 4))}-${encodeURIComponent(item.id)}`}
                                    style={{ textDecoration: 'none', color: 'inherit' }}>
                                    <span className="tilte" style={{ fontSize: '18px', fontWeight: '600' }}>{item.name}</span>
                                  </Link>
                                  <p style={{ fontSize: '13px', margin: '0px 0px' }}>{item.product_label ? item.product_label : "1 piece"}</p>
                                  {/* <br /> */}
                                  {/* <br /> */}
                                  <span className="subtitle"></span>
                                  <span className="subtitle"></span>
                                  <Stack sx={{ marginTop: '12px' }} direction="row" spacing={1}>
                                    <span>
                                      <Button variant='outlined' size="small" onClick={() => addToWishlistMain(item)}>Move to Wishlist</Button>
                                    </span>
                                    <span>
                                      <Button variant='outlined' size="small" color="error" onClick={() => removeFromCartWithApi(item.id)}>
                                        Delete
                                      </Button>
                                    </span>
                                  </Stack>
                                </td>
                                <td data-label="Price" className="ec-cart-pro-price">
                                  <span className="amount pr_dtls"><span className='pr_name'>	Price	</span><span style={{ fontSize: '16px' }}>₹{item.price}</span></span>
                                </td>
                                <td data-label="QTY">
                                  {/* <div className="qty-buttons pr_dtls">
                                    <span className='pr_dtls'>
                                      <span className='pr_name' style={{ lineHeight: '29px', paddingRight: '10px' }}>Quantity	</span>
                                      <span style={{ border: '1px solid #ff7043' }}>
                                        <button className="minus" onClick={() => handleDecreaseQuantityBackend(item.id)}>-</button>
                                        <span className="qty" style={{ padding: '7px' }}>{item.quantity}</span>
                                        <button className="plus" onClick={() => handleIncreaseQuantity(item.id)}>+</button></span></span>
                                  </div> */}
                                  <div className="qty-buttons-container-cart">
                                    <span className='pr_name' style={{ lineHeight: '29px', paddingRight: '10px' }}>Quantity	</span>
                                    <button
                                      className="qty-button"
                                      onClick={() => {
                                        handleDecreaseQuantityBackend(item.id);
                                      }}
                                    >
                                      -
                                    </button>
                                    <span className="qty" style={{
                                      width: '30px',
                                      height: '30px',
                                      textAlign: 'center',
                                      border: '1px solid #ff7043',
                                      padding: '2px 0px',
                                      color: '#000'
                                    }}>{item.quantity}</span>
                                    {/* <input
                                      type="number"
                                      value={item.quantity}
                                      min={1}
                                      onChange={(e) => {
                                        const newQuantity = Math.max(1, parseInt(e.target.value, 10) || 1);
                                        setQuantity(item.id, newQuantity); // Set quantity based on input
                                      }}
                                      style={{
                                        width: '30px',
                                        height: '30px',
                                        textAlign: 'center',
                                        border: '1px solid #ff7043',
                                        padding: '2px 0px',
                                        color: '#000'
                                      }}
                                    /> */}
                                    <button
                                      className="qty-button"
                                      onClick={() => handleIncreaseQuantity(item.id)}
                                    >
                                      +
                                    </button>
                                  </div>
                                </td>
                                <td data-label="Total" style={{ maxWidth: '80px', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}><span className='pr_dtls'><span className='pr_name'>Total	</span><span>₹{item.price * item.quantity}</span></span></td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      {/* <style
                dangerouslySetInnerHTML={{
                  __html:
                    "\n                      .ship-cart {\n                        padding: 20px;\n                        box-shadow: 0 30px 70px #010f1c1a\n                      }\n\n                      .ship-cart .pull-right {\n                        font-weight: 500;\n                        float: right !important;\n                      }\n\n                      .ship-cart h2 {\n                        font-size: 21px;\n                        font-weight: 700;\n                        border-bottom: 1px solid #E0E2E3;\n                        padding-bottom: 15px;\n                      }\n\n                      .ship-cart ul {\n                        padding-left: 0;\n                        list-style: none;\n                      }\n\n                      .ship-cart li {\n                        line-height: 30px;\n                      }\n\n                      .ship-cart li label {\n                        font-size: 16px;\n                        font-weight: 500;\n                      }\n\n                      .ship-total-price {\n                        padding-top: 10px;\n                        border-top: 1px solid #E0E2E3;\n                      }\n\n                      .ship-cart-checkout {\n                        font-size: 16px;\n                        font-weight: 500;\n                        color: white;\n                        background-color: #000;\n                        display: block;\n                        padding: 10px 30px;\n                        text-align: center;\n                        text-decoration: none;\n                      }\n\n                      .ship-cart-checkout:hover {\n                        color: white;\n                        background-color: #0989ff;\n                      }\n                "
                }}
              /> */}
                      <div className="ship-cart">
                        <div className="ship-cart-pr">
                          <h2>Shopping Cart</h2>
                          <ul>
                            <li>
                              <label>Cart subtotal</label>
                              <div className="value pull-right" style={{ color: "#555" }}>
                                ₹{totalAmount.toFixed(2)}
                              </div>
                            </li>
                            {/*<li>
                          <label>Shipping</label>
                          <div className="value pull-right" style={{ color: "#555" }}>
                            $0
                          </div>
                        </li>*/}
                          </ul>
                          <div className="hr-border" />
                          <ul className="ship-total-price">
                            <li>
                              <label style={{ fontWeight: 700 }}>Order total</label>
                              <div className="value pull-right" style={{ fontWeight: 700 }}>
                                ₹{totalAmount.toFixed(2)}
                              </div>
                            </li>
                          </ul>
                          <div className="ship-cart-shop">
                            <Button variant="contained" className="checkout-btn" onClick={handleProceedToCheckout} sx={{ width: '100%', backgroundColor: '#ff7043' }}
                            >
                              Proceed to Checkout
                            </Button>
                            <br />
                            <br />
                            {/* <Link to={'/'}>
                          <Button variant="outlined" size='large'>
                            Continue Shopping
                          </Button>
                        </Link> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div>
              <br />
              <h3 style={{ textAlign: 'center' }}>Related products</h3>
              <br />

              {/* Desktop and Tablet View */}
              <div className="cart-desktop-carousel">
                <Slider {...sliderSettings}>
                  {filteredProducts.map((item, index) => {
                    const productInCart = cartItems.find((itemInCart) => itemInCart.id === item.id);
                    const isInWishlist = wishlistNames.has(item.name);
                    return (
                      <div key={index} className="item">
                        <div className="shop2">

                          <div className="image-containers">
                            {item.sold && <div className="ribbon">Sold Out</div>}
                            <div className="heart-icon">
                              {isInWishlist ? (
                                <FavoriteIcon style={{ color: 'red', marginLeft: '7px' }} onClick={() => removeFromWishlist(item)} />
                              ) : (
                                <FavoriteBorderIcon style={{ color: 'red', marginLeft: '7px' }} onClick={() => addToWishlist(item)} />
                              )}
                            </div>
                            <Link
                              to={`/${item.category_hierarchy.join('/')}?product=${encodeURIComponent(truncateNameForURL(item.name, 4))}-${encodeURIComponent(item.id)}`}
                              style={{ textDecoration: 'none', color: 'inherit' }}>
                              <img
                                src={item.image ? decodeBase64Image(item.image) : NoImg}
                                alt={item.name}
                                loading="lazy"
                                className="img-fluid"
                                style={{
                                  objectFit: 'cover',
                                  borderRadius: 0,
                                  height: '200px',
                                  width: '100%',
                                }}
                              />
                            </Link>
                          </div>

                          <div className="shop-content">
                            <h3>{item.name}</h3>
                            <p style={{ fontSize: '13px', margin: '0px 0px' }}>{item.product_label ? item.product_label : "1 piece"}</p>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                              <span className="old-price" style={{ fontSize: '16px', color: ' #007BFF', fontWeight: '600' }}>
                                ₹{item.price}
                              </span>
                              {/* {!item.sold && productInCart ? (
                                <>

                                  <div className="qty-buttons pr_dtls">
                                    <span className='pr_dtls'>
                                      <span style={{ border: '1px solid #ff7043', padding: isMobileView ? '0px 0px' : '3px 2px', }}>
                                        <button className="minus" onClick={() => {
                                          if (productInCart.quantity === 1) {
                                            removeFromCartWithApi(item.id);
                                          } else {
                                            handleDecreaseQuantityBackend(item.id);
                                          }
                                        }}>-</button>
                                        <span className="qty" style={{ padding: '7px' }}>{productInCart.quantity}</span>
                                        <button className="plus" onClick={() => handleIncreaseQuantity(item.id)}>+</button></span></span>
                                  </div>
                                </>
                              ) : !item.sold ? (
                                <div style={{ border: '0.5px solid #ff7043', borderRadius: '50%', padding: '2px 6px', height: '30px', width: '30px', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }} onClick={() => addToCartWithApi(item)}>
                                  <IoMdCart style={{ fill: '#ff7043' }} />
                                </div>
                              ) : <div
                                style={{
                                  border: '0.5px solid grey',
                                  borderRadius: '50%',
                                  padding: '2px 6px',
                                  height: '30px',
                                  width: '30px',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}>
                                <IoMdCart style={{ fill: 'grey' }} />
                              </div>
                              } */}
                              {!item.sold && productInCart ? (
                                <div className="qty-buttons-container">
                                  <button
                                    className="qty-button"
                                    onClick={() => {
                                      if (productInCart.quantity === 1) {
                                        removeFromCartWithApi(item.id);
                                      } else {
                                        handleDecreaseQuantityBackend(item.id);
                                      }
                                    }}
                                  >
                                    -
                                  </button>
                                  <span className="qty" style={{
                                    width: '30px',
                                    height: '30px',
                                    textAlign: 'center',
                                    border: '1px solid #ff7043',
                                    padding: '2px 0px',
                                    color: '#000'
                                  }}>{productInCart.quantity}</span>
                                  {/* <input
                                    type="number"
                                    value={productInCart.quantity}
                                    // min={1}
                                    onChange={(e) => {
                                      const newQuantity = Math.max(0, parseInt(e.target.value, 10) || 0);
                                      setQuantity(item.id, newQuantity); // Set quantity based on input
                                    }}
                                    style={{
                                      width: '30px',
                                      height: '30px',
                                      textAlign: 'center',
                                      border: '1px solid #ff7043',
                                      padding: '2px 0px',
                                      color: '#000'
                                    }}
                                  /> */}
                                  <button
                                    className="qty-button"
                                    onClick={() => handleIncreaseQuantity(item.id)}
                                  >
                                    +
                                  </button>
                                </div>
                              ) : !item.sold ? (
                                <div
                                  style={{
                                    border: '0.5px solid #ff7043',
                                    borderRadius: '50%',
                                    padding: '2px 6px',
                                    height: '30px',
                                    width: '30px',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                  }}
                                  onClick={() => addToCartWithApi(item)}
                                >
                                  <IoMdCart style={{ fill: '#ff7043' }} />
                                </div>
                              ) : (
                                <div
                                  style={{
                                    border: '0.5px solid grey',
                                    borderRadius: '50%',
                                    padding: '2px 6px',
                                    height: '30px',
                                    width: '30px',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                  }}
                                >
                                  <IoMdCart style={{ fill: 'grey' }} />
                                </div>
                              )}

                            </div>
                          </div>

                        </div>
                      </div>
                    )
                  })}
                </Slider>
              </div>

              {/* Mobile View Display 4 Products in One Div */}
              <div className="mobile-carousel">
                <Slider {...sliderSettings}>
                  {mobileGroupedProducts.map((group, index) => (
                    <div key={index} className="mobile-slide">
                      <div className="mobile-grid">
                        {group.map((item, idx) => (
                          <div key={idx} className="mobile-item">
                            <Link
                              to={`/${item.category_hierarchy.join('/')}?product=${encodeURIComponent(truncateNameForURL(item.name, 4))}-${encodeURIComponent(item.id)}`}
                              style={{ textDecoration: 'none', color: 'inherit' }}>
                              <img
                                src={item.image ? decodeBase64Image(item.image) : NoImg}
                                alt={item.name}
                                loading="lazy"
                                className="img-fluid"
                                style={{
                                  objectFit: 'contain',
                                  height: '120px',
                                  width: '100%',
                                }}
                              />
                              <div className="mobile-shop-content">
                                <h3>{item.name}</h3>
                                <h3>₹ {item.price}</h3>
                              </div>
                            </Link>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
            <br />
            <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              sx={{ marginTop: '50px' }}
              open={showQuantitySnackbar}
              autoHideDuration={3000}
              onClose={() => setShowQuantitySnackbar(false)}>
              <Alert
                onClose={() => setShowQuantitySnackbar(false)}
                severity="error"
                variant="filled"
                sx={{ width: '100%' }}
              >Oops! It looks like you've reached the maximum quantity for this item.</Alert>
            </Snackbar>

            <Snackbar
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              open={showSnackbar}
              autoHideDuration={1000}
              onClose={() => setShowSnackbar(false)}
              message="Added to Wishlist"
            />
            <Snackbar
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              open={showRemoveSnackbar}
              autoHideDuration={1000}
              onClose={() => setShowRemoveSnackbar(false)}
              message="Removed from Wishlist"
            />
            <br />

            {isMobileView && (
              <MobileFooter />
            )}
          </>
        )}
      </main>
      <Footer />
    </>
  );
}
