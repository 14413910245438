import React, { useState, useEffect } from "react";
import "./MyAccount.css";
import { useNavigate } from 'react-router-dom';
import { IoPersonCircleOutline } from "react-icons/io5";
import { FaAngleRight } from "react-icons/fa";
import axios from 'axios';
import { useCartContext } from '../../Contexts/CartContext';
import toast, { Toaster } from 'react-hot-toast';

const MyAccount = () => {
    const navigate = useNavigate();
    const { clearCart } = useCartContext();
    const apiUrl = process.env.REACT_APP_API_BASE_URL;
    const [username, setUsername] = useState('');
    const [profile, setProfile] = useState({
        name: '',
        email: '',
        phone: ''
    });

    const [isEditing, setIsEditing] = useState(false);
    const companyName = localStorage.getItem('companyName');

    const handleLogout = async () => {
        try {
            localStorage.removeItem('user');
            localStorage.removeItem('companyName');
            clearCart();
            navigate('/login');
            window.location.reload();
        } catch (error) {
            console.error('Error logging out:', error);
        }
    };

    const fetchProfile = async () => {
        try {
            const currentUser = JSON.parse(localStorage.getItem('user'));

            const profileResponse = await axios.post(`${apiUrl}/get_partner_info`, {
                user_id: currentUser.user_id,
            });

            if (profileResponse.data.result.success) {
                setUsername(profileResponse.data.result.name);
                setProfile({
                    name: profileResponse.data.result.name,
                    email: profileResponse.data.result.email,
                    phone: profileResponse.data.result.phone.replace("+91", ""),
                });
                console.log("response of data fetch==========", profileResponse.data.result)
                // await AsyncStorage.setItem('userName', profileResponse.data.result.name);
            } else {
                console.log('Error fetching profile:', profileResponse.data.error);
            }
        } catch (error) {
            console.log('Error fetching profile:', error);
        }
    };

    useEffect(() => {
        fetchProfile();
    }, []);

    const handleSaveProfile = async () => {
        try {
            const currentUser = JSON.parse(localStorage.getItem('user'));

            console.log("in save profile ============");

            const response = await axios.post(`${apiUrl}/update_partner_info`, {
                user_id: currentUser.user_id,
                name: profile.name,
                phone: `+91${profile.phone.replace("+91", "")}`, 
                email: profile.email,
            });

            if (response.data.result.success) {
                toast.dismiss();
                toast.success("Profile updated successfully.", { position: "top-center" });
            } else {
                console.log('Error updating profile:', response.data.error);
            }
        } catch (error) {
            console.log('Error updating profile:', error);
        } finally {
            fetchProfile();
            setIsEditing(false);
        }
    };

    const handleDeleteAccount = async () => {
        try {
            const response = await axios.post(`${apiUrl}/delete_account`, {
                email: profile.email,
                companyName: companyName,
            });

            console.log("response in delete account ==============", response)

            if (response.data.result.success) {
                toast.dismiss();
                toast.success("Account deleted successfully.", { position: "top-center" });
                localStorage.removeItem('user');
                localStorage.removeItem('companyName');
                clearCart();
                navigate('/login');
                // window.location.reload();
            } else {
                console.log('Error deleting account:', response.data.error);
            }
        } catch (error) {
            console.log('Error deleting account:', error);
        }
    };

    const handleEdit = () => {
        setIsEditing(true);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setProfile({ ...profile, [name]: value });
    };

    const handlePhoneChange = (e) => {
        const value = e.target.value;

        // Allow only digits, and restrict input to 10 characters
        if (/^\d{0,10}$/.test(value)) {
            setProfile({ ...profile, phone: value });
        }
    };

    return (
        <div className="account-page">
            <div className="main-content">
                <div className="profile-section">
                    <IoPersonCircleOutline fill="#ff7043" size={80} />
                    {/* <div className="avatar"><IoPersonCircleOutline fill="#007bff" size={80}/></div> */}
                    <h3>Hello, {username}</h3>
                </div>
                <h2>Personal Information</h2>
                <div className="form-groups">
                    <label>Name</label>
                    <input
                        type="text"
                        name="name"
                        value={profile.name}
                        onChange={handleChange}
                        disabled={!isEditing}
                    />
                </div>

                <div className="form-groups">
                    <label>Email Address</label>
                    <input
                        type="email"
                        name="email"
                        value={profile.email}
                        disabled
                    />
                </div>

                <div className="form-groups">
                    <label>Mobile Number</label>
                    <input
                        type="text"
                        name="phone"
                        value={profile.phone}
                        onChange={(e) => handlePhoneChange(e)}
                        disabled={!isEditing}
                    />
                </div>
                <div className="edit-button-box">
                    {isEditing ? (
                        <button className="edit-button" onClick={handleSaveProfile}>Save</button>
                    ) : (
                        <button className="edit-button" onClick={handleEdit}>Edit</button>
                    )}
                </div>

                <div className="account-handle-btn" style={{ marginBottom: '10px' }} onClick={handleLogout}>Logout</div>
                <div className="account-handle-btn" onClick={handleDeleteAccount}>Delete my account</div>
            </div>

            <div className="sidebar">
                <div className="btns" onClick={() => navigate('/myorders')}>My Orders <FaAngleRight /></div>
                <div className="btns" onClick={() => navigate('/wishlist')}>My Wishlist <FaAngleRight /></div>
                <div className="btns" onClick={() => navigate('/address')}>Manage Address <FaAngleRight /></div>
            </div>

            <Toaster />
        </div>
    );
};

export default MyAccount;

// onClick={() => navigate('/address')}
