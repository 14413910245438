// import React, { useMemo } from 'react';
// import './Carousel2.css';
// import { useProductContext } from '../../Contexts/ProductContext';
// import { Link } from 'react-router-dom';

// export const Carousel2 = ({ searchTerm }) => {
//   const { products } = useProductContext();

//   const subcategories = useMemo(() => {
//     // Create a Set to track unique subcategories
//     const uniqueSubcategoriesSet = new Set();

//     // Extract categories, filter out duplicates and apply searchTerm filter
//     const categories = products
//       .filter(product => product.carousel_2 && product.category_hierarchy.length > 1)
//       .map(product => ({
//         id: product.id,
//         subcategory: product.category_hierarchy[1],
//         image: product.category_icon,
//         parentCategory: product.category_hierarchy[0]
//       }))
//       .filter(({ subcategory }) => {
//         if (uniqueSubcategoriesSet.has(subcategory)) {
//           return false; // Skip if subcategory is already in the set
//         }
//         uniqueSubcategoriesSet.add(subcategory);
//         return true; // Include if subcategory is unique
//       })
//       .filter(({ subcategory }) => {
//         return subcategory.toLowerCase().includes(searchTerm.toLowerCase()); // Filter by search term
//       });

//     return categories; // return shuffleArray(categories);
//   }, [products, searchTerm]); // Include searchTerm in dependencies

//   return (
//     <div className="containerr-fluid">
//       <div className='carousel2-heading'>
//         <h2>
//           Explore Our Products
//         </h2>
//       </div>
//       <div className="product-grids">
//         {subcategories.map(subcategory => (
//           <div className="grid-item" key={subcategory.id}>
//             <div className="product-card">
//               <Link to={`/${encodeURIComponent(subcategory.parentCategory)}`}
//                 state={{ selectedCategory: subcategory.subcategory }}>
//                 <img
//                   src={`data:image/jpeg;base64,${atob(subcategory.image)}`}
//                   alt={subcategory.subcategory}
//                   className="product-image"
//                 />
//                 <div className="products-info">
//                   <div className="product-name">{subcategory.subcategory}</div>
//                 </div>
//               </Link>
//             </div>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default Carousel2;


// import React, { useMemo } from "react";
// import "./Carousel2.css";
// import { useProductContext } from "../../Contexts/ProductContext";
// import { Link } from "react-router-dom";
// import NoImg from './no_image.png';

// export const Carousel2 = ({ searchTerm, activeCategory, onCategorySelect }) => {
//   const { products } = useProductContext();

//   const subcategories = useMemo(() => {
//     const uniqueSubcategoriesSet = new Set();

//     const categories = products
//       .filter(
//         (product) =>
//           product.carousel_2 && product.category_hierarchy.length > 1
//       )
//       .map((product) => ({
//         id: product.id,
//         subcategory: product.category_hierarchy[1],
//         image: product.category_icon,
//         parentCategory: product.category_hierarchy[0],
//       }))
//       .filter(({ subcategory }) => {
//         if (uniqueSubcategoriesSet.has(subcategory)) {
//           return false;
//         }
//         uniqueSubcategoriesSet.add(subcategory);
//         return true;
//       })
//       .filter(({ subcategory }) =>
//         subcategory.toLowerCase().includes(searchTerm.toLowerCase())
//       );

//     return categories;
//   }, [products, searchTerm]);

//   return (
//     <div className="containerr-fluid">
//       <div className="carousel2-heading">
//         <h2>Explore Our Products</h2>
//       </div>
//       <div className="carousel2-product-grids">
//         {subcategories.map((subcategory) => (
//           <div
//             className={`grid-item ${activeCategory === subcategory.subcategory ? "active" : ""
//               }`}
//             key={subcategory.id}
//             onClick={() => onCategorySelect(subcategory.subcategory)}
//           >
//             <div className="product-cards">
//               {/* <Link
//                 to={`/${encodeURIComponent(subcategory.parentCategory)}`}
//                 state={{ selectedCategory: subcategory.subcategory }}
//               > */}
//                 {subcategory.image ?
//                   <img
//                     src={`data:image/jpeg;base64,${atob(subcategory.image)}`}
//                     alt={subcategory.subcategory}
//                     className="product-icon"
//                   /> : <img
//                     src={NoImg}
//                     alt={subcategory.subcategory}
//                     className="product-icon"
//                   />
//                 }
//                 <div className="product-name" style={{ color: activeCategory === subcategory.subcategory ? "#ff7043" : "black" }}>{subcategory.subcategory}</div>
//               {/* </Link> */}
//             </div>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default Carousel2;


import React, { useMemo, useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Carousel2.css";
import { useProductContext } from "../../Contexts/ProductContext";
import NoImg from "./no_image.png";
import { FaChevronCircleLeft, FaChevronCircleRight } from "react-icons/fa";

const CustomPrevArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick} style={{ display: 'block', zIndex: 1 }}>
      <FaChevronCircleLeft style={{ color: '#375E97', fontSize: '28px' }} />
    </div>
  );
};

const CustomNextArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick} style={{ display: 'block', zIndex: 1 }}>
      <FaChevronCircleRight style={{ color: '#375E97', fontSize: '28px' }} />
    </div>
  );
};

export const Carousel2 = ({ searchTerm, activeCategory: initialActiveCategory, onCategorySelect }) => {
  const { products } = useProductContext();
  const [activeCategory, setActiveCategory] = useState(initialActiveCategory || "");

  const subcategories = useMemo(() => {
    const uniqueSubcategoriesSet = new Set();

    const categories = products
      .filter(
        (product) =>
          product.carousel_2 && product.category_hierarchy.length > 1
      )
      .map((product) => ({
        id: product.id,
        subcategory: product.category_hierarchy[1],
        image: product.category_icon,
        parentCategory: product.category_hierarchy[0],
      }))
      .filter(({ subcategory }) => {
        if (uniqueSubcategoriesSet.has(subcategory)) {
          return false;
        }
        uniqueSubcategoriesSet.add(subcategory);
        return true;
      })
      .filter(({ subcategory }) =>
        subcategory.toLowerCase().includes(searchTerm.toLowerCase())
      );

    return categories;
  }, [products, searchTerm]);

  // Automatically select the first category if none is already selected
  useEffect(() => {
    if (!activeCategory && subcategories.length > 0) {
      const firstSubcategory = subcategories[0].subcategory;
      setActiveCategory(firstSubcategory);
      onCategorySelect(firstSubcategory); // Notify parent of the selection
    }
  }, [subcategories, activeCategory, onCategorySelect]);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 8,
    slidesToScroll: 3,
    arrows: true,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="containerr-fluid">
      <div className="carousel2-heading">
        <h2>Explore Our Products</h2>
      </div>
      <Slider {...settings} className="carousel2-product-grids">
        {subcategories.map((subcategory) => (
          <div
            className={`grid-item ${activeCategory === subcategory.subcategory ? "active" : ""
              }`}
            key={subcategory.id}
            onClick={() => {
              setActiveCategory(subcategory.subcategory);
              onCategorySelect(subcategory.subcategory);
            }}
            style={{ minWidth: "100px" }}
          >
            <div className="product-cards">
              {subcategory.image ? (
                <img
                  src={`data:image/jpeg;base64,${atob(subcategory.image)}`}
                  alt={subcategory.subcategory}
                  className="product-icon"
                />
              ) : (
                <img
                  src={NoImg}
                  alt={subcategory.subcategory}
                  className="product-icon"
                />
              )}
              <div
                className="products-name"
                style={{
                  color:
                    activeCategory === subcategory.subcategory
                      ? "#ff7043"
                      : "black",
                  fontSize: "14px",
                  fontWeight: "600",
                }}
              >
                {subcategory.subcategory}
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Carousel2;




// import React, { useMemo, useState, useEffect } from "react";
// import "./Carousel2.css";
// import { useProductContext } from "../../Contexts/ProductContext";
// import NoImg from './no_image.png';

// export const Carousel2 = ({ searchTerm, activeCategory: initialActiveCategory, onCategorySelect }) => {
//   const { products } = useProductContext();
//   const [activeCategory, setActiveCategory] = useState(initialActiveCategory || "");

//   const subcategories = useMemo(() => {
//     const uniqueSubcategoriesSet = new Set();

//     const categories = products
//       .filter(
//         (product) =>
//           product.carousel_2 && product.category_hierarchy.length > 1
//       )
//       .map((product) => ({
//         id: product.id,
//         subcategory: product.category_hierarchy[1],
//         image: product.category_icon,
//         parentCategory: product.category_hierarchy[0],
//       }))
//       .filter(({ subcategory }) => {
//         if (uniqueSubcategoriesSet.has(subcategory)) {
//           return false;
//         }
//         uniqueSubcategoriesSet.add(subcategory);
//         return true;
//       })
//       .filter(({ subcategory }) =>
//         subcategory.toLowerCase().includes(searchTerm.toLowerCase())
//       );

//     return categories;
//   }, [products, searchTerm]);

//   // Automatically select the first category if none is already selected
//   useEffect(() => {
//     if (!activeCategory && subcategories.length > 0) {
//       const firstSubcategory = subcategories[0].subcategory;
//       setActiveCategory(firstSubcategory);
//       onCategorySelect(firstSubcategory); // Notify parent of the selection
//     }
//   }, [subcategories, activeCategory, onCategorySelect]);

//   return (
//     <div className="containerr-fluid">
//       <div className="carousel2-heading">
//         <h2>Explore Our Products</h2>
//       </div>
//       <div className="carousel2-product-grids">
//         {subcategories.map((subcategory) => (
//           <div
//             className={`grid-item ${activeCategory === subcategory.subcategory ? "active" : ""}`}
//             key={subcategory.id}
//             onClick={() => {
//               setActiveCategory(subcategory.subcategory);
//               onCategorySelect(subcategory.subcategory);
//             }}
//             style={{ minWidth: "100px" }}
//           >
//             <div className="product-cards">
//               {subcategory.image ? (
//                 <img
//                   src={`data:image/jpeg;base64,${atob(subcategory.image)}`}
//                   alt={subcategory.subcategory}
//                   className="product-icon"
//                 />
//               ) : (
//                 <img
//                   src={NoImg}
//                   alt={subcategory.subcategory}
//                   className="product-icon"
//                 />
//               )}
//               <div
//                 className="products-name"
//                 style={{
//                   color: activeCategory === subcategory.subcategory ? "#ff7043" : "black", fontSize: '14px', fontWeight: '600'
//                 }}
//               >
//                 {subcategory.subcategory}
//               </div>
//             </div>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default Carousel2;
