// import React, { createContext, useContext, useState, useEffect } from 'react';

// const CompanyContext = createContext();

// export const useCompanyContext = () => useContext(CompanyContext);

// export const CompanyProvider = ({ children }) => {
//     const [companyDetails, setCompanyDetails] = useState(null);
//     const apiUrl = process.env.REACT_APP_API_BASE_URL;

//     useEffect(() => {
//         const companyName = "general-stores";
//         // const endpoint = `/get/company_details?companyName=${companyName}`;
//         // // const endpoint = `/get/company_details`;
//         // const getCompanyUrl = `${apiUrl}${endpoint}`;
//         localStorage.setItem('companyName', companyName);
//         const getCompanyDetails = async () => {
//             try {
//                 console.log("in comapny context=========");
//                 console.log("companyName in comapny context=========", companyName);

//                 // const response = await fetch(getCompanyUrl, {
//                 //     method: 'POST',
//                 //     headers: {
//                 //         'Content-Type': 'application/json',
//                 //     },
//                 //     body: JSON.stringify({ companyName: companyName })
//                 // });

//                 const response = await fetch(`${apiUrl}/get/company_details/web`, {
//                     method: 'POST',
//                     headers: {
//                         'Content-Type': 'application/json',
//                     },
//                     body: JSON.stringify({ companyName: companyName }),
//                 });

//                 const data = await response.json();
//                 console.log("response in comapny-=====================", data)
//                 // const response = await fetch(getCompanyUrl, {
//                 //     method: 'GET',
//                 // });

//                 console.log("details in comapny-=====================", data.result.company_details)
//                 if (data.result.success) {
//                     setCompanyDetails(data.result.company_details);
//                 }

//                 // const data = await response.json();
//                 // if (data.success && data.company_details) {
//                 //     setCompanyDetails(data.company_details);
//                 //     localStorage.setItem('companyId', data.company_details.company_id);
//                 // } 
//                 else {
//                     console.error("ERROR Fetching company details.....");
//                 }
//             } catch (error) {
//                 console.error('Error fetching data:', error);
//             }
//         };

//         getCompanyDetails();
//     }, [apiUrl]);

//     return (
//         <CompanyContext.Provider value={{ companyDetails }}>
//             {children}
//         </CompanyContext.Provider>
//     );
// };


import React, { createContext, useContext, useState, useEffect } from 'react';

const CompanyContext = createContext();

export const useCompanyContext = () => useContext(CompanyContext);

export const CompanyProvider = ({ children }) => {
    const [companyDetails, setCompanyDetails] = useState(null);
    const apiUrl = process.env.REACT_APP_API_BASE_URL;

    useEffect(() => {
        const hostname = window.location.hostname; // e.g., "www.hondashop.digitaldukanworld.com"
        const companyName = hostname.split('.')[0].replace('www', ''); // Extract "hondashop"
        localStorage.setItem('companyName', companyName);
        const getCompanyDetails = async () => {
            try {
                const response = await fetch(`${apiUrl}/get/company_details/web`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ companyName: companyName }),
                });

                const data = await response.json();

                if (data.result.success) {
                    setCompanyDetails(data.result.company_details);

                    if (data.result.company_details.company_image) {
                        localStorage.setItem('companyImage', data.result.company_details.company_image);
                    }
                }
                else {
                    console.error("ERROR Fetching company details........");
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        getCompanyDetails();
    }, [apiUrl]);

    return (
        <CompanyContext.Provider value={{ companyDetails }}>
            {children}
        </CompanyContext.Provider>
    );
};



// import React, { createContext, useContext, useState, useEffect } from 'react';

// const CompanyContext = createContext();

// export const useCompanyContext = () => useContext(CompanyContext);

// export const CompanyProvider = ({ children }) => {
//     const [companyDetails, setCompanyDetails] = useState(null);
//     const apiUrl = process.env.REACT_APP_API_BASE_URL;

//     useEffect(() => {
//         // Extract the subdomain (company name) from the hostname
//         const hostname = window.location.hostname; // e.g., "www.hondashop.digitaldukanworld.com"
//         const companyName = hostname.split('.')[0].replace('www', ''); // Extract "hondashop"

//         console.log("company name in context================", companyName)

//         // Set companyName in localStorage (if needed)
//         if (companyName) {
//             localStorage.setItem('companyName', companyName);

//             const endpoint = `/get/company_details?companyName=${encodeURIComponent(companyName)}`;
//             const getCompanyUrl = `${apiUrl}${endpoint}`;

//             console.log("getCompanyUrl in context================", getCompanyUrl)

//             const getCompanyDetails = async () => {
//                 try {
//                     const response = await fetch(getCompanyUrl, {
//                         method: 'GET',
//                     });

//                     console.log("response in company context===========",response)

//                     const data = await response.json();

//                     console.log("data in company context===========",data)
//                     if (data.success && data.company_details) {
//                         setCompanyDetails(data.company_details);
//                         localStorage.setItem('companyId', data.company_details.company_id);

//                         if (data.company_details.company_image) {
//                             localStorage.setItem('companyImage', data.company_details.company_image);
//                         }
//                     } else {
//                         console.error("ERROR Fetching company details.....");
//                     }
//                 } catch (error) {
//                     console.error('Error fetching data:', error);
//                 }
//             };

//             getCompanyDetails();
//         }
//     }, [apiUrl]);
//     // localStorage.setItem('companyImage', companyDetails.company_image);

//     return (
//         <CompanyContext.Provider value={{ companyDetails }}>
//             {children}
//         </CompanyContext.Provider>
//     );
// };