import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom'; // For extracting URL params
import './MyOrdersForm.css';
import axios from 'axios';
import { useCompanyContext } from '../../Contexts/CompanyContext';
import NoImg from './No-Image.png';

const MyOrdersForm = () => {
    const { companyDetails } = useCompanyContext();
    const { orderid } = useParams(); // Extract the order_id from the URL
    const [orderDetails, setOrderDetails] = useState(null);
    const apiUrl = process.env.REACT_APP_API_BASE_URL;
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (orderid) {
            axios.post(apiUrl + '/myorder/form/details', { order_id: orderid })
                .then((response) => {
                    if (response.data.result.success) {
                        setOrderDetails(response.data.result.order_details);
                        setLoading(false);
                    } else {
                        console.log("error fetching data")
                    }
                })
                .catch((error) => {
                    setError('Failed to fetch order details');
                    setLoading(false);
                });
        }
    }, []);

    const truncateNameForURL = (name, maxLength) => {
        const words = name.split(' ');
        if (words.length <= maxLength) {
            return words.join('-'); // If the number of words is less than or equal to maxLength, use all words
        }
        return words.slice(0, maxLength).join('-'); // Otherwise, use only the first maxLength words
    };

    return (
        <div className="myorder-form">
            <div className="container">
                <div className="myorder-fcont">
                    <div className="myorder_con myorder_p">
                        <h2>Order summary</h2>
                        <div className="myorder_arrive">
                            {orderDetails?.order_name || 'Loading...'}
                        </div>
                    </div>

                    <div className="myorder_items myorder_borderb">
                        <h3>{orderDetails?.order_lines?.length || 0} items in this order</h3>
                        {orderDetails?.order_lines?.map((item, index) => (
                            <div key={index} className="myorder-box myorder-flex">
                                <div className="myorder-flex" style={{ gap: 10 }}>
                                    <div className="myorder_primg">
                                        {item.product_name !== 'Shipping' ?
                                            <Link
                                                to={`/${item.category_hierarchy.join('/')}?product=${encodeURIComponent(truncateNameForURL(item.product_name, 4))}-${encodeURIComponent(item.product_template_id)}`}
                                            >
                                                <img
                                                    src={item.product_image ? `data:image/jpeg;base64,${item.product_image}` : NoImg}
                                                    alt={item?.name}
                                                    className="dk-pro"
                                                />
                                            </Link> :
                                            <img
                                                src={item.product_image ? `data:image/jpeg;base64,${item.product_image}` : NoImg}
                                                alt={item?.name}
                                                className="dk-pro"
                                            />
                                        }
                                    </div>
                                    <div>
                                        <div>{item.product_name}</div>
                                        <div style={{ marginTop: 14 }}>
                                            <span>{item.quantity} x ₹{item.price_unit}</span>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ marginTop: 44, fontWeight: 700 }}>
                                    ₹{item.subtotal}
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className="myorder_items myorder_borderb">
                        <div className="myorder_bill">
                            <h3>Bill Details</h3>
                            <div className="myorder_sl myorder_sp">
                                <div>Total Amount</div>
                                <div>₹{orderDetails?.total_amount || 0}</div>
                            </div>
                        </div>
                    </div>
                    {orderDetails?.order_type === 'takeaway' &&
                        <div className="myorder_items myorder_borderb">
                            <div className="myorder_bill">
                                <h3>Dukan Address</h3>
                                <div className="myorder_sl myorder_sp">
                                    <div>{companyDetails?.company_address?.street},{companyDetails?.company_address?.city},{companyDetails?.company_address?.zip}, {companyDetails?.company_address?.country}</div>
                                </div>
                            </div>
                        </div>
                    }

                </div>
            </div>
        </div>


    )
}

export default MyOrdersForm