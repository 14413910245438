import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import './Address.css';
import Button from '@mui/material/Button';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Snackbar, Alert, Grid } from '@mui/material';
import MobileFooter from '../MobileFooter/MobileFooter';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { FaChevronCircleLeft, FaChevronCircleRight } from "react-icons/fa";
import { useProductContext } from '../../Contexts/ProductContext';
import toast, { Toaster } from 'react-hot-toast';
import { FaLocationCrosshairs } from "react-icons/fa6";
import { GrMapLocation } from "react-icons/gr";

// Custom arrows
const CustomPrevArrow = (props) => {
    const { className, onClick } = props;
    return (
        <div className={className} onClick={onClick} style={{ display: 'block', zIndex: 1 }}>
            <FaChevronCircleLeft style={{ color: '#375E97', fontSize: '28px' }} />
        </div>
    );
};

const CustomNextArrow = (props) => {
    const { className, onClick } = props;
    return (
        <div className={className} onClick={onClick} style={{ display: 'block', zIndex: 1 }}>
            <FaChevronCircleRight style={{ color: '#375E97', fontSize: '28px' }} />
        </div>
    );
};

const decodeBase64Image = (base64) => {
    const decodedImage = new Image();
    decodedImage.src = `data:image/jpeg;base64,${atob(base64)}`;
    return decodedImage.src;
};

const apiUrl = process.env.REACT_APP_API_BASE_URL;
const getRateEndpoint = '/get_rate';
const createCheckoutEndpoint = '/create-checkout-session';
const url = `${apiUrl}${getRateEndpoint}`;
const checkouturl = `${apiUrl}${createCheckoutEndpoint}`;


export const Address = () => {
    const { products } = useProductContext();
    const [addresses, setAddresses] = useState([]);
    const [address, setAddress] = useState({
        street: '',
        city: '',
        country: '',
        state: '',
        zip: ''
    });
    const [open, setOpen] = useState(false);
    const [newAddress, setNewAddress] = useState({
        street: '',
        city: '',
        pincode: '',
        country: 'India',
        state: ''
    });
    const [errors, setErrors] = useState({});
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768);
    const { state } = useLocation();
    const { cartItems = [], totalAmount = 0, additionalData = {} } = state || {};
    const [rate, setRate] = useState(null);
    const isLoggedIn = localStorage.getItem('user');
    const [location, setLocation] = useState({ latitude: null, longitude: null });
    const [error, setError] = useState('');

    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const validateFields = () => {
        let tempErrors = {};
        if (!newAddress.street) tempErrors.street = "Street is required";
        if (!newAddress.city) tempErrors.city = "City is required";
        if (!newAddress.pincode) tempErrors.pincode = "Pincode is required";
        if (!newAddress.country) tempErrors.country = "Country is required";
        if (!newAddress.state) tempErrors.state = "State is required";
        return tempErrors;
    };

    const handleSnackbarOpen = (message) => {
        setSnackbarMessage(message);
        setSnackbarOpen(true);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    const deleteAddress = async (addressId) => {
        try {
            const response = await fetch(`${apiUrl}/delete_address`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ id: addressId, user: isLoggedIn })
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();
            window.location.reload();
        } catch (error) {
            console.error("Failed to delete address:", error);
        }
    }

    useEffect(() => {
        const listAddresses = async () => {
            const listAddressesurl = apiUrl + '/list_addresses';
            try {
                const response = await fetch(listAddressesurl, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ user: isLoggedIn })
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok ' + response.status);
                }

                const addresses = await response.json();
                setAddresses(addresses.result.addresses);
            } catch (error) {
                console.error('There was a problem with the fetch operation:', error);
            }
        };

        if (isLoggedIn) {
            listAddresses();
        }
    }, [isLoggedIn, apiUrl]);

    const capitalizeWords = (str) => {
        return str
            .toLowerCase()
            .split(" ")
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ");
    };

    const reverseGeocode = async (latitude, longitude) => {
        try {
            const apiKey = 'EwaKsVz8Mx4bhaCEvyDcxzN8yMfd7vmTAqDY3emJ';
            const url = `https://api.olamaps.io/places/v1/reverse-geocode?latlng=${latitude},${longitude}&api_key=${apiKey}`;

            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                },
            });

            // console.log("response in reverse geocode========", response)

            if (!response.ok) {
                throw new Error(`Error: ${response.status} ${response.statusText}`);
            }

            const data = await response.json();
            const formattedAddress = data.results[0].formatted_address;
            const addressComponents = data.results[0].address_components;

            const parseAddress = (formattedAddress) => {
                const parts = formattedAddress.split(",").map((part) => part.trim());
                return {
                    country: parts.pop(),
                    pincode: parts.pop(),
                    state: parts.pop(),
                    city: parts.pop(),
                    street: parts.join(", ")
                };
            }

            const addressParts = parseAddress(formattedAddress);

            const newAddress = {
                pincode: addressParts.pincode,
                country: addressParts.country,
                state: capitalizeWords(addressParts.state),
                city: addressParts.city,
                street: addressParts.street
            };

            // const newAddress = {
            //     pincode: addressComponents[addressComponents.length - 1].short_name,
            //     country: addressComponents[0].short_name,
            //     state: capitalizeWords(addressComponents[1].short_name),
            //     city: addressComponents[2].short_name,
            //     street: addressComponents.slice(3, addressComponents.length - 1).map(component => component.short_name).join(", ")
            // };

            setNewAddress((prevAddress) => ({
                ...prevAddress,
                ...newAddress
            }));


            // const parseAddress = (address) => {
            //   const parts = address.split(",").map(part => part.trim());
            //   return {
            //     street: parts[0], // e.g., "B 31, Block B, Industrial Area, Sector 62"
            //     city: parts[1],   // e.g., "Noida"
            //     state: parts[2],  // e.g., "Uttar Pradesh"
            //     pincode: parts[3],// e.g., "201309"
            //     country: parts[4] // e.g., "India"
            //   };
            // };

            // const addressParts = parseAddress(formattedAddress);

            // setNewAddress((prevAddress) => ({
            //   ...prevAddress,
            //   street: addressParts.street,
            //   city: addressParts.city,
            //   pincode: addressParts.pincode,
            //   country: addressParts.country,
            //   state: addressParts.state
            // }));

            console.log("response data in reverse geocode========", data)
            console.log("response data in reverse geocode========", data.results[0])
        } catch (err) {
            setError(err.message);
        }
    };

    const getLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    console.log("latitude data in reverse geocode========", latitude)
                    console.log("longitude data in reverse geocode========", longitude)
                    setLocation({ latitude, longitude });
                    reverseGeocode(latitude, longitude);
                    setError(''); // Clear any previous errors
                },
                (err) => {
                    toast.dismiss();
                    toast('Please grant permission to access your location.', {
                        icon: <GrMapLocation size={40} />,
                    });
                    // toast.error("Please grant permission to access your location.", { position: "top-center" });
                    setError(err.message);
                },
                {
                    enableHighAccuracy: true, // Request high-accuracy coordinates
                    timeout: 10000, // Maximum time (ms) to wait for a response
                    maximumAge: 5000, // Don't use cached positions
                }
            );
        } else {
            toast.dismiss();
            toast.error("Geolocation is not supported by this browser.", { position: "top-center" });
            setError('Geolocation is not supported by this browser.');
        }
    };


    const submitNewAddress = async () => {
        if (addresses.length >= 5) {
            handleSnackbarOpen("Cannot add more addresses. Please delete an existing address.");
            return;
        }

        const tempErrors = validateFields();
        if (Object.keys(tempErrors).length > 0) {
            setErrors(tempErrors);
            return;
        }

        try {
            const response = await fetch(apiUrl + '/add_address', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ newAddress: newAddress, user: isLoggedIn })
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();
            handleClose();
            window.location.reload();
        } catch (error) {
            console.error("Failed to add address:", error);
        }
    };

    const handleOpen = (event) => {
        event.preventDefault();
        if (addresses.length >= 5) {
            handleSnackbarOpen("Cannot add more addresses. Please delete an existing address.");
            return;
        }
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setErrors({});
    };

    const handleChange = (e) => {
        setNewAddress({
            ...newAddress,
            [e.target.name]: e.target.value
        });
        if (errors[e.target.name]) {
            setErrors({
                ...errors,
                [e.target.name]: null
            });
        }
    };

    const handleSelectAddress = (selectedAddress) => {
        setAddress(selectedAddress);
    };

    const handlePincodeChange = (event) => {
        const { value, name } = event.target;
        const cleanedValue = value.replace(/[^0-9-\s]+/g, '');
        setNewAddress(prev => ({
            ...prev,
            [name]: cleanedValue
        }));

        if (errors[name]) {
            setErrors({
                ...errors,
                [name]: null
            });
        }
    };

    const sliderSettings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        arrows: true,
        prevArrow: <CustomPrevArrow />,
        nextArrow: <CustomNextArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };

    // Function to group products into chunks of 4
    const groupProductsForMobile = (products) => {
        const groupedProducts = [];
        for (let i = 0; i < products.length; i += 4) {
            groupedProducts.push(products.slice(i, i + 4));
        }
        return groupedProducts;
    };

    const mobileGroupedProducts = groupProductsForMobile(products);

    const truncateNameForURL = (name, maxLength) => {
        const words = name.split(' ');
        if (words.length <= maxLength) {
            return words.join('-'); // If the number of words is less than or equal to maxLength, use all words
        }
        return words.slice(0, maxLength).join('-'); // Otherwise, use only the first maxLength words
    };


    return (
        <main style={{ padding: isMobileView ? '0px 20px' : '0px 80px' }}>
            <div className="dlvr_adds" style={{ 'paddingTop': '30px' }}>
                <div className="container-fluid">
                    <div className="dlvr_contt">
                        <h2>Manage Address</h2>
                        <form className="shipp_address">
                            {addresses.map((address, index) => (
                                <div className="form-group" key={index}>
                                    <div style={{ padding: '10px 5px 8px 5px' }}>
                                        <input
                                            type="radio"
                                            name="accent-group"
                                            className="accent-black black accent-color"
                                            id={`accent-black${index}`}
                                            onChange={() => handleSelectAddress(address)}
                                            style={{ display: 'inline', width: 'auto' }}
                                        />
                                        <label htmlFor={`accent-black${index}`} style={{ display: 'inline' }}>
                                            {address.street}, {address.city}, {address.zip}, {address.country}
                                        </label>
                                    </div>
                                    <div style={{ padding: '6px 5px 8px 0px' }}>
                                        <Button variant='outlined' color='error' size='small'
                                            onClick={() => deleteAddress(address.id)}
                                            sx={{ marginTop: '5px', height: '24px' }}>Delete</Button>
                                    </div>
                                </div>
                            ))}
                            <div className="form__confirmation">
                                <Button style={{ backgroundColor: '#375E97' }} variant='contained' size='small' onClick={handleOpen}>+ Add New Address</Button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Add New Address</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="street"
                        name="street"
                        label="Street"
                        type="text"
                        fullWidth
                        required
                        value={newAddress.street}
                        onChange={handleChange}
                        error={!!errors.street}
                        helperText={errors.street}
                    />
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="city"
                                name="city"
                                label="City"
                                type="text"
                                fullWidth
                                required
                                value={newAddress.city}
                                onChange={handleChange}
                                error={!!errors.city}
                                helperText={errors.city}
                                sx={{ marginBottom: '15px' }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                margin="dense"
                                id="pincode"
                                name="pincode"
                                label="Zipcode"
                                type="text"
                                fullWidth
                                required
                                value={newAddress.pincode}
                                onChange={handlePincodeChange}
                                error={!!errors.pincode}
                                helperText={errors.pincode}
                                sx={{ marginBottom: '15px' }}
                            />
                        </Grid>
                    </Grid>
                    <RegionDropdown
                        country={newAddress.country}
                        value={newAddress.state}
                        onChange={(val) => setNewAddress({ ...newAddress, state: val })}
                        classes="form-control"
                        blankOptionLabel="Select State"
                        style={{
                            borderColor: errors.state ? 'red' : undefined, height: '56px',
                            padding: '10px 14px', borderRadius: '4px'
                        }}
                    />
                    {errors.state && <p style={{
                        color: '#d32f2f', fontSize: '0.75rem', marginLeft: '14px', fontWeight: '400',
                        marginTop: '3px'
                    }}>{errors.state}</p>}
                    <CountryDropdown
                        value={newAddress.country}
                        disabled
                        onChange={(val) => setNewAddress({ ...newAddress, country: val })}
                        classes="form-control"
                        style={{
                            borderColor: errors.country ? 'red' : undefined, height: '56px',
                            padding: '10px 14px', borderRadius: '4px', marginTop: '15px'
                        }}
                    />
                    {errors.country && <p style={{
                        color: '#d32f2f', fontSize: '0.75rem', marginLeft: '14px', fontWeight: '400',
                        marginTop: '3px'
                    }}>{errors.country}</p>}
                </DialogContent>
                <DialogActions>
                    <div style={{ width: '57%', textAlign: 'start', color: '#1976d2', cursor: 'pointer', fontWeight: '600', alignItems: 'baseline' }} onClick={getLocation} color="primary"><FaLocationCrosshairs /> Get Current Location</div>
                    <Button onClick={handleClose} color="primary">Cancel</Button>
                    <Button onClick={submitNewAddress} color="primary">Add Address</Button>
                </DialogActions>
            </Dialog>
            <Snackbar open={snackbarOpen} autoHideDuration={2000} onClose={handleSnackbarClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert onClose={handleSnackbarClose} severity="error" sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
            {/* <div>
                <h3 style={{ textAlign: 'center' }}>Our products</h3>
                <br />
                <div className="desktop-carousel">
                    <Slider {...sliderSettings}>
                        {products.map((item, index) => (
                            <div key={index} className="item">
                                <div className="shop1">
                                    <Link
                                        to={`/${item.category_hierarchy.join('/')}?product=${encodeURIComponent(truncateNameForURL(item.name, 4))}-${encodeURIComponent(item.id)}`}
                                        style={{ textDecoration: 'none', color: 'inherit' }}>
                                        <img
                                            src={decodeBase64Image(item.image)}
                                            alt={item.name}
                                            loading="lazy"
                                            className="img-fluid"
                                            style={{
                                                objectFit: 'contain',
                                                borderRadius: 0,
                                                height: '150px',
                                                width: '100%',
                                            }}
                                        />
                                        <div className="shop-content">
                                            <h3>{item.name}</h3>
                                            <span className="old-price" style={{ fontSize: '20px', color: 'black' }}>
                                                <span style={{
                                                    fontSize: '14px',
                                                    position: 'relative',
                                                    top: '-7px',
                                                    paddingRight: '3px'
                                                }}>₹</span>{item.price}
                                            </span>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>

                //  Mobile View Display 4 Products in One Div 
                <div className="mobile-carousel">
                    <Slider {...sliderSettings}>
                        {mobileGroupedProducts.map((group, index) => (
                            <div key={index} className="mobile-slide">
                                <div className="mobile-grid">
                                    {group.map((item, idx) => (
                                        <div key={idx} className="mobile-item">
                                            <Link
                                                to={`/${item.category_hierarchy.join('/')}?product=${encodeURIComponent(truncateNameForURL(item.name, 4))}-${encodeURIComponent(item.id)}`}
                                                style={{ textDecoration: 'none', color: 'inherit' }}>
                                                <img
                                                    src={decodeBase64Image(item.image)}
                                                    alt={item.name}
                                                    loading="lazy"
                                                    className="img-fluid"
                                                    style={{
                                                        objectFit: 'contain',
                                                        height: '120px',
                                                        width: '100%',
                                                    }}
                                                />
                                                <div className="mobile-shop-content">
                                                    <h3>{item.name}</h3>
                                                    <h3>₹ {item.price}</h3>
                                                </div>
                                            </Link>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
            </div> */}
            {isMobileView && (
                <MobileFooter />
            )}
            <Toaster />
        </main>
    );
};

export default Address;
