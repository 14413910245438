import React, { useState, useEffect, useRef } from "react";
import "./LoginNew.css";
import NoLogo from './No_logo.png';
import { useCompanyContext } from '../../Contexts/CompanyContext';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { MdOutlinePhoneAndroid, MdOutlinePassword } from "react-icons/md";

const LoginNewPhone = () => {
    const apiUrl = process.env.REACT_APP_API_BASE_URL;
    const endpoint = '/login/phone';
    const otpEndpoint = '/login/phone/otp';
    const loginUrl = `${apiUrl}${endpoint}`;
    const { companyDetails } = useCompanyContext();
    const companyID = localStorage.getItem('companyID');
    const companyName = localStorage.getItem('companyName');
    const dynamicURL = companyID ? `/home/${companyID}` : '/';
    const [formData, setFormData] = useState({
        phone: '',
        otp: '',
    });
    const [otpSent, setOtpSent] = useState(false);
    const logoSrc = companyDetails ? `data:image/png;base64,${atob(companyDetails.company_image)}` : '';
    const navigate = useNavigate();
    const location = useLocation();

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === "otp" && (!/^\d*$/.test(value) || value.length > 6)) {
            return;
        }

        setFormData({
            ...formData,
            [name]: value,
        });
    };

    formData.companyName = companyName;

    const handleSendOtp = async (e) => {
        e.preventDefault();
        if (!formData.phone) {
            Swal.fire({
                icon: 'error',
                title: 'Invalid Phone',
                text: 'Please enter a valid phone number.',
                customClass: {
                    confirmButton: 'custom-confirm-button'
                }
            });
            return;
        }
        try {
            const response = await fetch(`${apiUrl}${otpEndpoint}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    phone: formData.phone,
                    companyName,
                }),
            });

            console.log("response =============", response);

            const data = await response.json();

            if (response.ok && data.result.success) {
                Swal.fire({
                    icon: 'success',
                    title: 'OTP Sent',
                    text: 'Please check your whatsApp for the OTP.',
                    customClass: {
                        confirmButton: 'custom-confirm-button'
                    }
                });
                setOtpSent(true);
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: data.result.message || 'Failed to send OTP. Please try again.',
                    customClass: {
                        confirmButton: 'custom-confirm-button'
                    }
                });
            }
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Internal server error. Please try again later.',
                customClass: {
                    confirmButton: 'custom-confirm-button'
                }
            });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (formData.otp.length !== 6) {
            Swal.fire({
                icon: 'error',
                title: 'Invalid OTP',
                text: 'Please enter a valid OTP.',
                customClass: {
                    confirmButton: 'custom-confirm-button'
                }
            });
            return;
        }

        try {
            const response = await fetch(loginUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    phone: formData.phone,
                    otp: formData.otp,
                    companyName,
                }),
            });

            if (response.ok) {
                const responseData = await response.json();
                if (responseData.result.success) {
                    localStorage.setItem('user', JSON.stringify({ user: responseData.result.username, email: responseData.result.user_email, user_id: responseData.result.id }));
                    // Redirect to home page after successful login

                    if (location.state && location.state.fromCheckout) {
                        navigate('/cart');
                    } else {
                        // Redirect to home page after successful login
                        window.location.href = '/';
                    }
                }else{
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: responseData.result.message,
                        customClass: {
                            confirmButton: 'custom-confirm-button'
                        }
                    });
                }
            } else {
                localStorage.removeItem('user');
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Internal server error while submit Form.',
                    customClass: {
                        confirmButton: 'custom-confirm-button'
                    }
                });
            }
        } catch (error) {
            localStorage.removeItem('user');
            console.error('An error occurred:', error);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Internal Sever Error.',
                customClass: {
                    confirmButton: 'custom-confirm-button'
                }
            });
        }
    };

    const statelocation = location.state;


    return (
        <>
            <div className="signup-container">
                <div className="signup-card">
                    <div className="logo">
                        <Link to={dynamicURL}>
                            {logoSrc ? <img src={logoSrc} style={{ width: '60px', height: '60px', borderRadius: '50%', border: '1px solid gray' }} alt="Company Logo" /> : <img src={NoLogo} style={{ width: '60px', height: '60px', borderRadius: '50%', border: '1px solid gray' }} alt="No logo" />}
                        </Link>
                    </div>
                    <h2>Great to see you!</h2>
                    <p>Sign In to your account</p>
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <span className="input-icon"><MdOutlinePhoneAndroid /></span>
                            <input
                                type="phone"
                                name="phone"
                                placeholder="Phone"
                                value={formData.phone}
                                onChange={handleChange}
                                maxLength="10"
                                required
                                disabled={otpSent}
                            />
                        </div>
                        {otpSent &&
                            <div className="form-group">
                                <span className="input-icon"><MdOutlinePassword /></span>
                                <input
                                    type="text"
                                    name="otp"
                                    placeholder="OTP"
                                    value={formData.otp}
                                    onChange={handleChange}
                                    maxLength="6"
                                    // pattern="\d{6}"
                                    required
                                />
                            </div>
                        }
                        {!otpSent ?
                            <button className="submit-btn" onClick={handleSendOtp}>
                                Send OTP
                            </button> :
                            <button className="submit-btn" onClick={handleSubmit}>
                                Sign In
                            </button>}
                    </form>
                    <div className="divider">
                        <span>Or</span>
                    </div>
                    <p className="phone-btn" onClick={() => navigate("/login")}>
                        Sign In with email
                    </p>
                    <p className="signin-link">
                        New Customer? <a href="/register">Sign Up</a>
                    </p>
                </div>

            </div>
        </>
    );
};

export default LoginNewPhone;
