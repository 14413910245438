import React, { useMemo, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useCartContext } from '../../Contexts/CartContext';
import './Search.css';
import { useProductContext } from '../../Contexts/ProductContext';
import { IoMdCart } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';
import NoImg from './No-Image.png';
import NoResult from './noResult.avif';

export const Search = () => {
    const { products } = useProductContext();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const searchTerm = searchParams.get('query') || '';
    const { addToCart, decreaseQuantity, removeFromCart, increaseQuantity, cartItems } = useCartContext();
    const navigate = useNavigate();
    const apiUrl = process.env.REACT_APP_API_BASE_URL;
    const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const truncateNameForURL = (name, maxLength) => {
        const words = name.split(' ');
        if (words.length <= maxLength) {
            return words.join('-');
        }
        return words.slice(0, maxLength).join('-');
    };

    const decodeBase64Image = (base64) => {
        const decodedImage = new Image();
        decodedImage.src = `data:image/jpeg;base64,${atob(base64)}`;
        return decodedImage.src;
    };

    const filteredProducts = useMemo(() => {
        return products.filter(product =>
            product.name.toLowerCase().includes(searchTerm.toLowerCase())
        );
    }, [products, searchTerm]);

    const addToCartWithApi = async (productDetail) => {
        try {
            addToCart(productDetail);

            const currentUser = JSON.parse(localStorage.getItem('user'));

            if (currentUser && currentUser.user) {
                const response = await fetch(`${apiUrl}/add_to_cart`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ user_id: currentUser.user_id, cartItems: productDetail }),
                });

                if (!response.ok) {
                    throw new Error('Failed to add product to cart');
                }

            } else {
                console.error('User not logged in'); // Log an error if user is not logged in
            }
        } catch (error) {
            console.error('Error adding product to cart:', error);
        }
    };

    const removeFromCartWithApi = async (productId) => {
        try {
            handleRemoveFromCart(productId);

            const currentUser = JSON.parse(localStorage.getItem('user'));

            if (currentUser && currentUser.user) {
                const response = await fetch(`${apiUrl}/remove_from_cart`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ user_id: currentUser.user_id, productId }),
                });

                if (!response.ok) {
                    throw new Error('Failed to remove product from cart');
                }

            } else {
                console.error('User not logged in');
            }
        } catch (error) {
            console.error('Error removing product from cart:', error);
        }
    };

    const handleIncreaseQuantity = async (productId) => {
        const product = products.find(p => p.id === productId);
        const productInCart = cartItems.find(item => item.id === productId);
        increaseQuantity(productId);

        const currentUser = JSON.parse(localStorage.getItem('user'));

        if (currentUser && currentUser.user_id) {
            const response = await fetch(`${apiUrl}/increase_product_qty_in_cart`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ user_id: currentUser.user_id, productId }),
            });

            if (response.ok) {
                const responseData = await response.json();
            } else {
                console.error('Failed to increase quantity');
            }
        } else {
            console.error('User not logged in');
        }
    };

    const handleDecreaseQuantityBackend = async (productId) => {
        decreaseQuantity(productId); // Update the quantity in the local state

        const currentUser = JSON.parse(localStorage.getItem('user'));

        // Check if the current user is logged in
        if (currentUser && currentUser.user) {
            try {
                // Send a POST request to the backend to update the quantity
                const response = await fetch(`${apiUrl}/decrease_product_qty_in_cart`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ user_id: currentUser.user_id, productId }),
                });

                if (!response.ok) {
                    throw new Error('Failed to decrease quantity');
                }

                const responseData = await response.json();
            } catch (error) {
                console.error('Error decrease quantity:', error);
            }
        }
    };

    const handleRemoveFromCart = (productId) => {
        removeFromCart(productId);
    };

    return (
        <div className="search-fluid" style={{ height: '100vh' }}>
            {filteredProducts.length === 0 ?
                (<div className="no-results" style={{ textAlign: 'center' }}>
                    <img
                        src={NoResult}
                        alt="No Results Found"
                        className="no-results-image"
                    // style={{ height: '650px', width: '100%' }}
                    />
                    <h2 style={{ color: '#555' }}>No results found</h2>
                    <p style={{ color: '#777' }}>Try adjusting your search or explore other categories.</p>
                </div>
                ) : (
                    <div className="products-grid">
                        {filteredProducts.map((item) => {
                            const productInCart = cartItems.find((itemInCart) => itemInCart.id === item.id);
                            return (
                                <div key={item.id} className="product-main-card">

                                    <div className="category-product-card">
                                        <Link
                                            to={`/${item.category_hierarchy.join('/')}?product=${encodeURIComponent(truncateNameForURL(item.name, 4))}-${encodeURIComponent(item.id)}`}>
                                            {item.image ?
                                                <img
                                                    src={decodeBase64Image(item.image)}
                                                    alt={item.name}
                                                    loading="lazy"
                                                    className="img-fluid"
                                                    style={{
                                                        objectFit: 'cover',
                                                        borderRadius: 0,
                                                        height: '200px',
                                                        width: '100%',
                                                    }}
                                                /> :
                                                <img
                                                    src={NoImg}
                                                    alt={item.name}
                                                    loading="lazy"
                                                    className="img-fluid"
                                                    style={{
                                                        objectFit: 'cover',
                                                        borderRadius: 0,
                                                        height: '200px',
                                                        width: '100%',
                                                    }}
                                                />
                                            }

                                        </Link>
                                        <div className="shop-content">
                                            <h3>{item.name}</h3>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <span className="old-price" style={{ fontSize: '16px', color: '#0096FF', fontWeight: '600' }}>
                                                    ₹{item.price}
                                                </span>
                                                {productInCart ? (
                                                    <>
                                                        <div className="qty-buttons pr_dtls">
                                                            <span className='pr_dtls'>
                                                                <span style={{ border: '1px solid #ff7043', padding: isMobileView ? '0px 0px' : '3px 2px', }}>
                                                                    <button className="minus" onClick={() => {
                                                                        if (productInCart.quantity === 1) {
                                                                            removeFromCartWithApi(item.id);
                                                                        } else {
                                                                            handleDecreaseQuantityBackend(item.id);
                                                                        }
                                                                    }}>-</button>
                                                                    <span className="qty" style={{ padding: '7px' }}>{productInCart.quantity}</span>
                                                                    <button className="plus" onClick={() => handleIncreaseQuantity(item.id)}>+</button></span></span>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <div style={{ border: '0.5px solid #ff7043', borderRadius: '50%', padding: '2px 6px', height: '30px', width: '30px', justifyContent: 'center', alignItems: 'center' }} onClick={() => addToCartWithApi(item)}>
                                                        <IoMdCart style={{ fill: '#ff7043' }} />
                                                    </div>
                                                )}

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            )
                        })}
                    </div>
                )
            }
            {/* <div className="product-grids">
                {filteredProducts.map(product => (
                    <div className="grid-item" key={product.id}>
                        <div className="product-card">
                            <Link
                                // to={`/${(product.categoryHierarchy?.join('/') || '')}/?product=${encodeURIComponent(truncateNameForURL(product.name, 4))}-${encodeURIComponent(product.id)}`}
                                to={`/${product.category_hierarchy.join('/')}?product=${encodeURIComponent(truncateNameForURL(product.name, 4))}-${encodeURIComponent(product.id)}`}
                            >
                                <img
                                    src={`data:image/jpeg;base64,${atob(product.image)}`}
                                    alt={product.name}
                                    className="product-image"
                                />
                                <div className="products-info">
                                    <div className="product-name">{product.name}</div>
                                </div>
                            </Link>
                        </div>
                    </div>
                ))}
            </div> */}
        </div>
    );
};

export default Search;



// import React, { useMemo } from 'react';
// import './Search.css';
// import { useProductContext } from '../../Contexts/ProductContext';
// import { Link } from 'react-router-dom';
// import SearchIllus from './search_illus.jpg';
// import NoProd from './noprod.avif';

// export const Search = ({ searchTerm }) => {
//     const { products } = useProductContext();

//     const truncateNameForURL = (name, maxLength) => {
//         const words = name.split(' ');
//         if (words.length <= maxLength) {
//             return words.join('-');
//         }
//         return words.slice(0, maxLength).join('-');
//     };

//     const filteredProducts = useMemo(() => {
//         if (!searchTerm.trim()) {
//             return [];
//         }
//         return products.filter(product =>
//             product.name.toLowerCase().includes(searchTerm.toLowerCase())
//         );
//     }, [products, searchTerm]);

//     return (
//         <div className="search-fluid">
//             {searchTerm.trim() === '' ? (
//                 <div className="no-products-message">
//                     <img
//                         src={SearchIllus}
//                     />
//                     <h2>Search for products</h2>
//                 </div>
//             ) : filteredProducts.length === 0 ? (
//                 <div className="no-products-message">
//                     <img
//                         src={NoProd}
//                     />
//                     <h2>No products found</h2>
//                 </div>
//             ) : (
//                 <div className="product-grids">
//                     {filteredProducts.map(product => (
//                         <div className="grid-item" key={product.id}>
//                             <div className="product-card">
//                                 <Link
//                                     to={`/${(product.categoryHierarchy?.join('/') || '')}/?product=${encodeURIComponent(truncateNameForURL(product.name, 4))}-${encodeURIComponent(product.id)}`}
//                                 >
//                                     <img
//                                         src={`data:image/jpeg;base64,${atob(product.image)}`}
//                                         alt={product.name}
//                                         className="product-image"
//                                     />
//                                     <div className="products-info">
//                                         <div className="product-namee">{product.name}</div>
//                                     </div>
//                                 </Link>
//                             </div>
//                         </div>
//                     ))}
//                 </div>
//             )}
//         </div>
//     );
// };

// export default Search;
