import React, { useState, useEffect, useRef } from "react";
import "./LoginNew.css";
import { ImEye } from "react-icons/im";
import { PiEyeClosedBold } from "react-icons/pi";
import NoLogo from './No_logo.png';
import { useCompanyContext } from '../../Contexts/CompanyContext';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import PasswordStrengthBar from 'react-password-strength-bar';
import { MdOutlineMail } from "react-icons/md";
import { IoIosLock } from "react-icons/io";

const LoginNew = () => {
    const apiUrl = process.env.REACT_APP_API_BASE_URL;
    const endpoint = '/login';
    const loginUrl = `${apiUrl}${endpoint}`;
    const { companyDetails } = useCompanyContext();
    const companyID = localStorage.getItem('companyID');
    const companyName = localStorage.getItem('companyName');
    const dynamicURL = companyID ? `/home/${companyID}` : '/';
    const [formData, setFormData] = useState({
        useremail: '',
        password: '',
    });
    const [showPassword, setShowPassword] = useState(false);
    const logoSrc = companyDetails ? `data:image/png;base64,${atob(companyDetails.company_image)}` : '';
    const navigate = useNavigate();
    const location = useLocation();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    formData.companyName = companyName;

    const handleSubmit = async (e) => {
        e.preventDefault();

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(formData.useremail.trim())) {
            Swal.fire({
                icon: 'error',
                title: 'Invalid Email',
                text: 'Please enter a valid email address.',
                customClass: {
                    confirmButton: 'custom-confirm-button'
                }
            });
            return;
        }

        try {
            const response = await fetch(loginUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                const responseData = await response.json();
                const resultData = JSON.parse(responseData.result);
                if (resultData.success === 'YES') {
                    localStorage.setItem('user', JSON.stringify({ user: resultData.username, email: resultData.user_email, user_id: resultData.id }));
                    // Redirect to home page after successful login

                    if (location.state && location.state.fromCheckout) {
                        navigate('/cart');
                    } else {
                        // Redirect to home page after successful login
                        window.location.href = '/';
                    }
                }
                if (resultData.success === 'NO') {
                    localStorage.removeItem('user');
                    // Handle failed login
                    Swal.fire({
                        icon: 'info',
                        title: 'Error',
                        text: resultData.message,
                        customClass: {
                            confirmButton: 'custom-confirm-button'
                        }
                    });
                }
            } else {
                console.error('Failed to submit form:', response.statusText);
                localStorage.removeItem('user');
                // Handle server error
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Internal server error while submit Form.',
                    customClass: {
                        confirmButton: 'custom-confirm-button'
                    }
                });
            }
        } catch (error) {
            localStorage.removeItem('user');
            console.error('An error occurred:', error);
            // Handle internal server error
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Internal Sever Error.',
                customClass: {
                    confirmButton: 'custom-confirm-button'
                }
            });
        }
    };

    const statelocation = location.state;


    return (
        <>
            {/* <nav className="navbar">
                <div className="navbar-image">
                    <Link to={dynamicURL}>
                        {logoSrc ? <img src={logoSrc} className="logo" alt="Company Logo"
                            style={{ height: '60px', width: '60px', borderRadius: '50%', marginTop: '4px', border: '1px solid black', marginBottom: '1px' }} /> : <img src={NoLogo} className="logo" alt="Company Logo"
                                style={{ height: '60px', width: '60px', borderRadius: '50%', marginTop: '4px', border: '1px solid black', marginBottom: '1px' }} />}
                    </Link>
                </div>
            </nav> */}
            <div className="signup-container">
                <div className="signup-card">
                    <div className="logo">
                        <Link to={dynamicURL}>
                            {logoSrc ? <img src={logoSrc} style={{ width: '60px', height: '60px', borderRadius: '50%', border: '1px solid gray' }} alt="Company Logo" /> : <img src={NoLogo} style={{ width: '60px', height: '60px', borderRadius: '50%', border: '1px solid gray' }} alt="No logo" />}
                        </Link>
                    </div>
                    <h2>Great to see you!</h2>
                    <p>Sign In to your account</p>
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <span className="input-icon"><MdOutlineMail /></span>
                            <input
                                type="email"
                                name="useremail"
                                placeholder="Email Address"
                                vvalue={formData.useremail}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <span className="input-icon"><IoIosLock /></span>
                            <input
                                type={showPassword ? "text" : "password"}
                                name="password"
                                placeholder="Password"
                                value={formData.password}
                                onChange={handleChange}
                                required
                            />
                            <button
                                type="button"
                                className="toggle-visibility"
                                onClick={() => setShowPassword(!showPassword)}
                            >
                                {showPassword ? <PiEyeClosedBold /> : <ImEye />}
                            </button>
                        </div>
                        {formData.password !== '' &&
                            <PasswordStrengthBar
                                password={formData.password}
                                minLength={8}
                                shortScoreWord="Too Short"
                                scoreWords={["Weak", "Fair", "Good", "Strong", "Very Strong"]}
                            />
                        }
                        <div class="forget"><Link to='/forgot_password'>Forgot Password?</Link></div>

                        <button className="submit-btn" onClick={handleSubmit}>
                            Sign In
                        </button>
                    </form>
                    <div className="divider">
                        <span>Or</span>
                    </div>
                    <p className="phone-btn" onClick={()=> navigate("/login/phone")}>
                        Sign In with phone
                    </p>
                    <p className="signin-link">
                        New Customer? <a href="/register">Sign Up</a>
                    </p>
                </div>

            </div>
        </>
    );
};

export default LoginNew;
