// // TrendingItemsCarousel.js
// import React from 'react';
// import OwlCarousel from 'react-owl-carousel';
// import 'owl.carousel/dist/assets/owl.carousel.css';
// import 'owl.carousel/dist/assets/owl.theme.default.css';
// import { useProductContext } from '../../Contexts/ProductContext';
// import { Link } from 'react-router-dom';
// import './Trending.css';

// const TrendingItemsCarousel = () => {
//   const { products } = useProductContext();

//   const decodeBase64Image = (base64) => {
//     const decodedImage = new Image();
//     decodedImage.src = `data:image/jpeg;base64,${atob(base64)}`;
//     return decodedImage.src;
//   };

//   const trendingProducts = products.filter((product) => product.trending);


//   const carouselOptions = {
//     items: 4,
//     margin: 20,
//     // nav: true,
//     dots: false,
//     responsive: {
//       0: {
//         items: 2 // Show only one item in mobile view
//       },
//       479: {
//         items: 2 // Show two items in tablet view
//       },
//       700: {
//         items: 3
//       },
//       800: {
//         items: 4 // Show four items in desktop view
//       },
//       1093: {
//         items: 5
//       }
//     }
//   };

//   const truncateNameForURL = (name, maxLength) => {
//     const words = name.split(' ');
//     if (words.length <= maxLength) {
//       return words.join('-'); // If the number of words is less than or equal to maxLength, use all words
//     }
//     return words.slice(0, maxLength).join('-'); // Otherwise, use only the first maxLength words
//   };

//   return (
//     <div className="carousel-box pt-3 pb-5">
//       <div className="container-fluid">
//         <h2 className="text-start mb-5">Shop Our Trending Items</h2>
//         <OwlCarousel className="owl-carousel3 owl-theme" {...carouselOptions}>
//           {trendingProducts.map((item, index) => (
//             <div key={index} className="item">
//               <div className="shop1">
//                 <Link
//                   to={`/${item.category_hierarchy.join('/')}?product=${encodeURIComponent(truncateNameForURL(item.name, 4))}-${encodeURIComponent(item.id)}`}
//                   style={{ textDecoration: 'none', color: 'inherit' }}>
//                   <img
//                     src={decodeBase64Image(item.image)}
//                     alt=""
//                     title=""
//                     loading="lazy"
//                     className="img-fluid"
//                     style={{
//                       objectFit: 'contain',
//                       borderRadius: 0,
//                     }}
//                   />
//                   <div className=""></div>
//                   <div className="shop-content">
//                     <h3>{item.name}</h3>
//                     <span className="old-price" style={{ fontSize: '24px', color: 'black' }}>
//                       <span style={{
//                         fontSize: '14px',
//                         position: 'relative',
//                         top: '-7px',
//                         fontSize: '13px',
//                         paddingRight: '3px'
//                       }}>$</span>{item.price}
//                     </span>
//                   </div>
//                 </Link>
//               </div>
//             </div>
//           ))}
//         </OwlCarousel>
//       </div>
//     </div>
//   );
// };

// export default TrendingItemsCarousel;




// import React from 'react';
// import Slider from 'react-slick';
// import { useProductContext } from '../../Contexts/ProductContext';
// import { Link } from 'react-router-dom';
// import './Trending.css';
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';
// import { FaChevronCircleLeft, FaChevronCircleRight } from "react-icons/fa";


// // Custom arrows
// const CustomPrevArrow = (props) => {
//   const { className, onClick } = props;
//   return (
//     <div className={className} onClick={onClick} style={{ display: 'block', zIndex: 1 }}>
//       <FaChevronCircleLeft style={{ color: '#4f73f7', fontSize: '28px' }} />
//     </div>
//   );
// };

// const CustomNextArrow = (props) => {
//   const { className, onClick } = props;
//   return (
//     <div className={className} onClick={onClick} style={{ display: 'block', zIndex: 1 }}>
//       <FaChevronCircleRight style={{ color: '#4f73f7', fontSize: '28px' }} />
//     </div>
//   );
// };

// const TrendingItemsCarousel = () => {
//   const { products } = useProductContext();

//   const decodeBase64Image = (base64) => {
//     const decodedImage = new Image();
//     decodedImage.src = `data:image/jpeg;base64,${atob(base64)}`;
//     return decodedImage.src;
//   };

//   const trendingProducts = products.filter((product) => product.trending);

//   const truncateNameForURL = (name, maxLength) => {
//     const words = name.split(' ');
//     if (words.length <= maxLength) {
//       return words.join('-');
//     }
//     return words.slice(0, maxLength).join('-');
//   };

//   const sliderSettings = {
//     dots: false,
//     infinite: false,
//     speed: 500,
//     slidesToShow: 5,
//     slidesToScroll: 1,
//     arrows: true,
//     prevArrow: <CustomPrevArrow />,
//     nextArrow: <CustomNextArrow />,
//     responsive: [
//       {
//         breakpoint: 1024,
//         settings: {
//           slidesToShow: 4,
//         },
//       },
//       {
//         breakpoint: 768,
//         settings: {
//           slidesToShow: 2,
//         },
//       },
//       {
//         breakpoint: 480,
//         settings: {
//           slidesToShow: 1,
//         },
//       },
//     ],
//   };

//   return (
//     <div className="trending-carousel-box pt-3 pb-5">
//       <div className="container-fluid">
//         <h2 className="text-start mb-5">Shop Our Trending Items</h2>
//         <Slider {...sliderSettings}>
//           {trendingProducts.map((item, index) => (
//             <div key={index} className="item">
//               <div className="shop1">
//                 <Link
//                   to={`/${item.category_hierarchy.join('/')}?product=${encodeURIComponent(truncateNameForURL(item.name, 4))}-${encodeURIComponent(item.id)}`}
//                   style={{ textDecoration: 'none', color: 'inherit' }}>
//                   <img
//                     src={decodeBase64Image(item.image)}
//                     alt={item.name}
//                     loading="lazy"
//                     className="img-fluid"
//                     style={{
//                       objectFit: 'contain',
//                       borderRadius: 0,
//                       height: '150px',
//                       width: '100%',
//                     }}
//                   />
//                   <div className="shop-content">
//                     <h3>{item.name}</h3>
//                     <span className="old-price" style={{ fontSize: '24px', color: 'black' }}>
//                       <span style={{
//                         fontSize: '14px',
//                         position: 'relative',
//                         top: '-7px',
//                         paddingRight: '3px'
//                       }}>$</span>{item.price}
//                     </span>
//                   </div>
//                 </Link>
//               </div>
//             </div>
//           ))}
//         </Slider>
//       </div>
//     </div>
//   );
// };

// export default TrendingItemsCarousel;






import React, { useMemo, useState, useEffect } from "react";
import { useProductContext } from '../../Contexts/ProductContext';
import { useCartContext } from '../../Contexts/CartContext';
import { Link, useNavigate } from 'react-router-dom';
import './Trending.css';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { FaChevronCircleLeft, FaChevronCircleRight } from "react-icons/fa";
import { IoMdCart } from "react-icons/io";
import NoImg from './No-Image.png';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import Swal from 'sweetalert2';
import Snackbar from '@mui/material/Snackbar';

// Custom arrows
const CustomPrevArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick} style={{ display: 'block', zIndex: 1 }}>
      <FaChevronCircleLeft style={{ color: '#375E97', fontSize: '28px' }} />
    </div>
  );
};

const CustomNextArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick} style={{ display: 'block', zIndex: 1 }}>
      <FaChevronCircleRight style={{ color: '#375E97', fontSize: '28px' }} />
    </div>
  );
};

const TrendingItemsCarousel = () => {
  const { products } = useProductContext();
  const { addToCart, decreaseQuantity, removeFromCart, increaseQuantity, cartItems, setQuantity } = useCartContext();
  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  const navigate = useNavigate();
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768);
  const [wishlist, setWishlist] = useState([]);
  const wishlist_endpoint = '/add_to_wishlist';
  const wishlistUrl = `${apiUrl}${wishlist_endpoint}`;
  const currentUser = JSON.parse(localStorage.getItem('user'));
  const removeFromWishlistUrl = `${apiUrl}/remove_from_wishlist`;
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [showRemoveSnackbar, setShowRemoveSnackbar] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const fetchWishlist = async () => {
    const currentUser = JSON.parse(localStorage.getItem('user'));

    const response = await fetch(`${apiUrl}/check_wishlist/user/app`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ user_id: currentUser.user_id })
    });

    if (response.ok) {
      const result = await response.json();
      const parsedResult = JSON.parse(result.result);
      setWishlist(parsedResult.wishlist);
    } else {
      console.error('Failed to fetch wishlist');
    }
  };

  useEffect(() => {
    if (currentUser) {
      fetchWishlist();
    }
  }, []);

  const decodeBase64Image = (base64) => {
    const decodedImage = new Image();
    decodedImage.src = `data:image/jpeg;base64,${atob(base64)}`;
    return decodedImage.src;
  };

  const trendingProducts = products.filter((product) => product.trending);

  if (trendingProducts.length === 0) {
    return null;
  }

  const truncateNameForURL = (name, maxLength) => {
    const words = name.split(' ');
    if (words.length <= maxLength) {
      return words.join('-');
    }
    return words.slice(0, maxLength).join('-');
  };

  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    arrows: true,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  // Function to group products into chunks of 4
  const groupProductsForMobile = (products) => {
    const groupedProducts = [];
    for (let i = 0; i < products.length; i += 4) {
      groupedProducts.push(products.slice(i, i + 4));
    }
    return groupedProducts;
  };

  const mobileGroupedProducts = groupProductsForMobile(trendingProducts);

  const addToCartWithApi = async (productDetail) => {
    try {
      addToCart(productDetail);

      const currentUser = JSON.parse(localStorage.getItem('user'));

      if (currentUser && currentUser.user) {
        const response = await fetch(`${apiUrl}/add_to_cart`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ user_id: currentUser.user_id, cartItems: productDetail }),
        });

        if (!response.ok) {
          throw new Error('Failed to add product to cart');
        }

      } else {
        console.error('User not logged in'); // Log an error if user is not logged in
      }
    } catch (error) {
      console.error('Error adding product to cart:', error);
    }
  };

  const removeFromCartWithApi = async (productId) => {
    try {
      handleRemoveFromCart(productId);

      const currentUser = JSON.parse(localStorage.getItem('user'));

      if (currentUser && currentUser.user) {
        const response = await fetch(`${apiUrl}/remove_from_cart`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ user_id: currentUser.user_id, productId }),
        });

        if (!response.ok) {
          throw new Error('Failed to remove product from cart');
        }

      } else {
        console.error('User not logged in');
      }
    } catch (error) {
      console.error('Error removing product from cart:', error);
    }
  };

  const handleIncreaseQuantity = async (productId) => {
    const product = products.find(p => p.id === productId);
    const productInCart = cartItems.find(item => item.id === productId);
    increaseQuantity(productId);

    const currentUser = JSON.parse(localStorage.getItem('user'));

    if (currentUser && currentUser.user_id) {
      const response = await fetch(`${apiUrl}/increase_product_qty_in_cart`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ user_id: currentUser.user_id, productId }),
      });

      if (response.ok) {
        const responseData = await response.json();
      } else {
        console.error('Failed to increase quantity');
      }
    } else {
      console.error('User not logged in');
    }
  };

  const handleDecreaseQuantityBackend = async (productId) => {
    decreaseQuantity(productId); // Update the quantity in the local state

    const currentUser = JSON.parse(localStorage.getItem('user'));

    // Check if the current user is logged in
    if (currentUser && currentUser.user) {
      try {
        // Send a POST request to the backend to update the quantity
        const response = await fetch(`${apiUrl}/decrease_product_qty_in_cart`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ user_id: currentUser.user_id, productId }),
        });

        if (!response.ok) {
          throw new Error('Failed to decrease quantity');
        }

        const responseData = await response.json();
      } catch (error) {
        console.error('Error decrease quantity:', error);
      }
    }
  };

  const handleRemoveFromCart = (productId) => {
    removeFromCart(productId);
  };

  const wishlistNames = new Set(wishlist.map(item => item.product_name));

  const addToWishlist = async (productDetail) => {
    try {
      const currentUserData = localStorage.getItem('user');
      const currentUser = JSON.parse(currentUserData);

      if (currentUser && currentUser.user_id) {
        const response = await fetch(wishlistUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ user_id: currentUser.user_id, product_detail: productDetail }),
        });
        if (!response.ok) {
          throw new Error('Failed to add to wishlist');
        }
        setShowSnackbar(true);
        fetchWishlist();
      } else {
        console.error('User ID not found in currentUser data');
        Swal.fire({
          html: `
          Hi there! Log in to your account or create one today to enjoy a personalized shopping experience!
          `,
          showCloseButton: false,
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonText: `
            Login
          `,
          confirmButtonAriaLabel: "Login",
          cancelButtonText: `
            Register
          `,
          cancelButtonAriaLabel: "Register",
          customClass: {
            confirmButton: 'custom-confirm-button',
            cancelButton: 'custom-confirm-button'
          }
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = '/login'; // Redirect to login page
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            window.location.href = '/register'; // Redirect to register page
          }
        });
        // navigate('/login');
      }
    } catch (error) {
      console.error('Error adding to wishlist:', error);
    }
  };

  const removeFromWishlist = async (productDetail) => {
    try {
      const currentUserData = localStorage.getItem('user');
      const currentUser = JSON.parse(currentUserData);

      if (currentUser && currentUser.user_id) {
        const response = await fetch(removeFromWishlistUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ user_id: currentUser.user_id, product_detail: productDetail }),
        });

        if (!response.ok) {
          throw new Error('Failed to remove from wishlist');
        }
        setShowRemoveSnackbar(true);
        fetchWishlist();
      } else {
        console.error('User ID not found in currentUser data');
        navigate('/login');
      }
    } catch (error) {
      console.error('Error removing from wishlist:', error);
    }
  };

  // pb-5 classname
  return (
    <div className="trending-carousel-box pt-3 ">
      {trendingProducts !== 0 && <div className="container-fluid" style={{ padding: 0 }}>
        <h2 className="text-center mb-5">Shop Our Trending Items</h2>

        {/* Desktop and Tablet View */}
        <div className="trending-desktop-carousel">
          <Slider {...sliderSettings}>
            {trendingProducts.map((item, index) => {
              const productInCart = cartItems.find((itemInCart) => itemInCart.id === item.id);
              const isInWishlist = wishlistNames.has(item.name);
              return (
                <div key={index} className="item">
                  <div className="shop2">

                    <div className="image-containers">
                      {item.sold && <div className="ribbon">Sold Out</div>}
                      <div
                        className="heart-icon"
                      >
                        {isInWishlist ? (
                          <FavoriteIcon style={{ color: 'red', marginLeft: '7px' }} onClick={() => removeFromWishlist(item)} />
                        ) : (
                          <FavoriteBorderIcon style={{ color: 'red', marginLeft: '7px' }} onClick={() => addToWishlist(item)} />
                        )}
                      </div>
                      <Link
                        to={`/${item.category_hierarchy.join('/')}?product=${encodeURIComponent(truncateNameForURL(item.name, 4))}-${encodeURIComponent(item.id)}`}
                        style={{ textDecoration: 'none', color: 'inherit' }}>
                        {item.image ?
                          <img
                            src={decodeBase64Image(item.image)}
                            alt={item.name}
                            loading="lazy"
                            className="img-fluid"
                            style={{
                              objectFit: 'cover',
                              borderRadius: 0,
                              height: '200px',
                              width: '100%',
                            }}
                          /> :
                          <img
                            src={NoImg}
                            alt={item.name}
                            loading="lazy"
                            className="img-fluid"
                            style={{
                              objectFit: 'cover',
                              borderRadius: 0,
                              height: '200px',
                              width: '100%',
                            }}
                          />
                        }
                      </Link>
                    </div>

                    <div className="shop-content">
                      <h3>{item.name}</h3>
                      <p style={{ fontSize: '13px', margin: '0px 0px' }}>{item.product_label ? item.product_label : "1 piece"}</p>
                      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <span className="old-price" style={{ fontSize: '16px', color: '#007BFF', fontWeight: '600' }}>
                          ₹{item.price}
                        </span>
                        {/* {!item.sold && productInCart ? (
                          <>

                            <div className="qty-buttons pr_dtls">
                              <span className='pr_dtls'>
                                <span style={{ border: '1px solid #ff7043', padding: isMobileView ? '0px 0px' : '3px 2px', }}>
                                  <button className="minus" onClick={() => {
                                    if (productInCart.quantity === 1) {
                                      removeFromCartWithApi(item.id);
                                    } else {
                                      handleDecreaseQuantityBackend(item.id);
                                    }
                                  }}>-</button>
                                  <span className="qty" style={{ padding: '7px' }}>{productInCart.quantity}</span>
                                  <button className="plus" onClick={() => handleIncreaseQuantity(item.id)}>+</button></span></span>
                            </div>
                          </>
                        ) : !item.sold ? (
                          <div style={{ border: '0.5px solid #ff7043', borderRadius: '50%', padding: '2px 6px', height: '30px', width: '30px', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }} onClick={() => addToCartWithApi(item)}>
                            <IoMdCart style={{ fill: '#ff7043' }} />
                          </div>
                        ) : <div
                          style={{
                            border: '0.5px solid grey',
                            borderRadius: '50%',
                            padding: '2px 6px',
                            height: '30px',
                            width: '30px',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}>
                          <IoMdCart style={{ fill: 'grey' }} />
                        </div>
                        } */}
                        {!item.sold && productInCart ? (
                          <div className="qty-buttons-container">
                            <button
                              className="qty-button"
                              onClick={() => {
                                if (productInCart.quantity === 1) {
                                  removeFromCartWithApi(item.id);
                                } else {
                                  handleDecreaseQuantityBackend(item.id);
                                }
                              }}
                            >
                              -
                            </button>
                            <span className="qty" style={{
                              width: '30px',
                              height: '30px',
                              textAlign: 'center',
                              border: '1px solid #ff7043',
                              padding: '2px 0px',
                              color: '#000'
                            }}>{productInCart.quantity}</span>
                            {/* <input
                              type="number"
                              value={productInCart ? productInCart.quantity : 0}
                              // min={1}
                              onChange={(e) => {
                                const newQuantity = Math.max(0, parseInt(e.target.value, 10) || 0);
                                setQuantity(item.id, newQuantity); // Set quantity based on input
                              }}
                              style={{
                                width: '30px',
                                height: '30px',
                                textAlign: 'center',
                                border: '1px solid #ff7043',
                                padding: '2px 0px',
                                color: '#000'
                              }}
                            /> */}
                            <button
                              className="qty-button"
                              onClick={() => handleIncreaseQuantity(item.id)}
                            >
                              +
                            </button>
                          </div>
                        ) : !item.sold ? (
                          <div
                            style={{
                              border: '0.5px solid #ff7043',
                              borderRadius: '50%',
                              padding: '2px 6px',
                              height: '30px',
                              width: '30px',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                            onClick={() => addToCartWithApi(item)}
                          >
                            <IoMdCart style={{ fill: '#ff7043' }} />
                          </div>
                        ) : (
                          <div
                            style={{
                              border: '0.5px solid grey',
                              borderRadius: '50%',
                              padding: '2px 6px',
                              height: '30px',
                              width: '30px',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <IoMdCart style={{ fill: 'grey' }} />
                          </div>
                        )}
                      </div>
                    </div>

                  </div>
                </div>
              )
            })}
          </Slider>
        </div>

        {/* Mobile View Display 4 Products in One Div */}
        <div className="mobile-carousel">
          <Slider {...sliderSettings}>
            {mobileGroupedProducts.map((group, index) => (
              <div key={index} className="mobile-slide">
                <div className="mobile-grid">
                  {group.map((item, idx) => (
                    <div key={idx} className="mobile-item">
                      <Link
                        to={`/${item.category_hierarchy.join('/')}?product=${encodeURIComponent(truncateNameForURL(item.name, 4))}-${encodeURIComponent(item.id)}`}
                        style={{ textDecoration: 'none', color: 'inherit' }}>

                        {item.image ?
                          <img
                            src={decodeBase64Image(item.image)}
                            alt={item.name}
                            loading="lazy"
                            className="img-fluid"
                            style={{
                              objectFit: 'contain',
                              height: '120px',
                              width: '100%',
                            }}
                          /> :
                          <img
                            src={NoImg}
                            alt={item.name}
                            loading="lazy"
                            className="img-fluid"
                            style={{
                              objectFit: 'contain',
                              height: '120px',
                              width: '100%',
                            }}
                          />
                        }

                        <div className="mobile-shop-content">
                          <h3>{item.name}</h3>
                          <h3>₹ {item.price}</h3>
                        </div>
                      </Link>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>}

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={showSnackbar}
        autoHideDuration={1000}
        onClose={() => setShowSnackbar(false)}
        message="Added to Wishlist"
      />
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={showRemoveSnackbar}
        autoHideDuration={1000}
        onClose={() => setShowRemoveSnackbar(false)}
        message="Removed from Wishlist"
      />

    </div>
  );
};

export default TrendingItemsCarousel;
