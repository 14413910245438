// // ProductContext.js
// import React, { createContext, useContext, useState, useEffect } from 'react';
// import { openDB } from 'idb';
// import LZString from 'lz-string';

// const ProductContext = createContext();

// export const useProductContext = () => useContext(ProductContext);

// export const ProductProvider = ({ children }) => {
//   const [products, setProducts] = useState([]);
//   const apiUrl = process.env.REACT_APP_API_BASE_URL;
//   // const companyName = localStorage.getItem('companyName');
//   const companyName = "general-stores";
//   const dynamicURL = companyName ? `/get_products?companyName=${encodeURIComponent(companyName)}` : '/';
//   const getProductUrl = `${apiUrl}${dynamicURL}`;

//   const initDB = async () => {
//     return openDB('ProductDB', 1, {
//       upgrade(db) {
//         if (!db.objectStoreNames.contains('products')) {
//           db.createObjectStore('products', { keyPath: 'id' });
//         }
//       },
//     });
//   };

//   // useEffect(() => {
//   //   const getProducts = async () => {
//   //     const compressedData = localStorage.getItem('products');
//   //     if (compressedData) {
//   //       const decompressedData = JSON.parse(LZString.decompress(compressedData));
//   //       setProducts(decompressedData);
//   //     } else {
//   //       try {
//   //         const response = await fetch(getProductUrl, {
//   //           method: 'GET',
//   //         });
//   //         const data = await response.json();
//   //         if (data && data.length > 0) {
//   //           const compressed = LZString.compress(JSON.stringify(data));
//   //           localStorage.setItem('products', compressed);
//   //           setProducts(data);
//   //         }
//   //       } catch (error) {
//   //         console.error('Error fetching data:', error);
//   //       }
//   //     }
//   //   };

//   //   getProducts();
//   // }, [getProductUrl]);

//   useEffect(() => {
//     const getProducts = async () => {
//       const db = await initDB();
//       const tx = db.transaction('products', 'readonly');
//       const store = tx.objectStore('products');
//       const allProducts = await store.getAll();

//       if (allProducts.length > 0) {
//         setProducts(allProducts);
//       } else {
//         try {
//           const response = await fetch(getProductUrl, { method: 'GET' });
//           const data = await response.json();

//           if (data && data.length > 0) {
//             const compressed = LZString.compress(JSON.stringify(data));
//             const tx = db.transaction('products', 'readwrite');
//             const store = tx.objectStore('products');
//             for (const product of data) {
//               store.put(product);
//             }
//             await tx.done;
//             setProducts(data);
//           }
//         } catch (error) {
//           console.error('Error fetching data:', error);
//         }
//       }
//     };

//     getProducts();
//   }, [getProductUrl]);

//   return (
//     <ProductContext.Provider value={{ products }}>
//       {children}
//     </ProductContext.Provider>
//   );
// };



// // ProductContext.js
// import React, { createContext, useContext, useState, useEffect } from 'react';

// const ProductContext = createContext();

// export const useProductContext = () => useContext(ProductContext);

// export const ProductProvider = ({ children }) => {
//   const [products, setProducts] = useState([]);
//   const apiUrl = process.env.REACT_APP_API_BASE_URL;
//   const companyName = localStorage.getItem('companyName');
//   const dynamicURL = companyName ? `/get_products?companyName=${encodeURIComponent(companyName)}` : '/';

//   // console.log("dynamicURL in product context==========",dynamicURL)

//   const getProductUrl = `${apiUrl}${dynamicURL}`;

//   useEffect(() => {
//     const getProducts = async () => {
//       try {
//         const response = await fetch(getProductUrl, {
//           method: 'GET',
//         });
//         // console.log("response in product context==========",response)
//         const data = await response.json();
//         // console.log("data in product context==========",data)
//         if (data && data.length > 0) {
//           setProducts(data);
//         }
//       } catch (error) {
//         console.error('Error fetching data:', error);
//       }
//     };

//     getProducts();
//   }, [getProductUrl]);

//   return (
//     <ProductContext.Provider value={{ products }}>
//       {children}
//     </ProductContext.Provider>
//   );
// };










// import React, { createContext, useContext, useState, useEffect } from 'react';
// import { openDB } from 'idb';
// import LZString from 'lz-string';

// const ProductContext = createContext();

// export const useProductContext = () => useContext(ProductContext);

// export const ProductProvider = ({ children }) => {
//   const [products, setProducts] = useState([]);
//   const apiUrl = process.env.REACT_APP_API_BASE_URL;
//   // const companyName = localStorage.getItem('companyName');
//   const companyName = "general-stores";
//   const dynamicURL = companyName ? `/get_products?companyName=${encodeURIComponent(companyName)}` : '/';
//   const getProductUrl = `${apiUrl}${dynamicURL}`;

//   // Initialize IndexedDB
//   const initDB = async () => {
//     return openDB('ProductDB', 1, {
//       upgrade(db) {
//         if (!db.objectStoreNames.contains('products')) {
//           db.createObjectStore('products', { keyPath: 'id' });
//         }
//       },
//     });
//   };

//   // Clear the IndexedDB store
//   const clearDB = async () => {
//     const db = await initDB();
//     const tx = db.transaction('products', 'readwrite');
//     await tx.objectStore('products').clear();
//     await tx.done;
//   };

//   useEffect(() => {
//     const initializeData = async () => {
//       const db = await initDB();
//       const tx = db.transaction('products', 'readonly');
//       const store = tx.objectStore('products');
//       const allProducts = await store.getAll();

//       if (allProducts.length > 0) {
//         setProducts(allProducts); // Load data from IndexedDB
//       } else {
//         try {
//           const response = await fetch(getProductUrl, { method: 'GET' });
//           const data = await response.json();

//           if (data && data.length > 0) {
//             const tx = db.transaction('products', 'readwrite');
//             const store = tx.objectStore('products');
//             for (const product of data) {
//               store.put(product);
//             }
//             await tx.done;
//             setProducts(data);
//           }
//         } catch (error) {
//           console.error('Error fetching data:', error);
//         }
//       }
//     };

//     initializeData();

//     // Clear IndexedDB on tab close
//     const handleBeforeUnload = () => {
//       clearDB();
//     };

//     window.addEventListener('beforeunload', handleBeforeUnload);

//     return () => {
//       window.removeEventListener('beforeunload', handleBeforeUnload);
//     };
//   }, [getProductUrl]);

//   return (
//     <ProductContext.Provider value={{ products }}>
//       {children}
//     </ProductContext.Provider>
//   );
// };






import React, { createContext, useContext, useState, useEffect } from 'react';
import { openDB } from 'idb';
import LZString from 'lz-string';

const ProductContext = createContext();

export const useProductContext = () => useContext(ProductContext);

export const ProductProvider = ({ children }) => {
  const [products, setProducts] = useState([]);
  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  const companyName = localStorage.getItem('companyName');
  // const companyName = "general-stores";
  const dynamicURL = companyName ? `/get_products?companyName=${encodeURIComponent(companyName)}` : '/';
  const getProductUrl = `${apiUrl}${dynamicURL}`;

  const initDB = async () => {
    return openDB('ProductDB', 1, {
      upgrade(db) {
        if (!db.objectStoreNames.contains('products')) {
          db.createObjectStore('products', { keyPath: 'id' });
        }
      },
    });
  };

  const fetchAndStoreProducts = async (db) => {
    try {
      const response = await fetch(getProductUrl, { method: 'GET' });
      const data = await response.json();

      if (data && data.length > 0) {
        // Save in IndexedDB
        const tx = db.transaction('products', 'readwrite');
        const store = tx.objectStore('products');
        for (const product of data) {
          store.put(product);
        }
        await tx.done;

        setProducts(data);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    const getProducts = async () => {
      const db = await initDB();

      // Check if a new session is detected
      const isNewSession = !sessionStorage.getItem('sessionActive');
      if (isNewSession) {
        // Mark the session as active
        sessionStorage.setItem('sessionActive', 'true');

        // Fetch fresh data and store in IndexedDB
        await fetchAndStoreProducts(db);
        return;
      }

      // Load products from IndexedDB
      const tx = db.transaction('products', 'readonly');
      const store = tx.objectStore('products');
      const allProducts = await store.getAll();

      if (allProducts.length > 0) {
        setProducts(allProducts);
      } else {
        // Fetch fresh data and store in IndexedDB
        await fetchAndStoreProducts(db);
      }
    };

    getProducts();
  }, [getProductUrl]);

  return (
    <ProductContext.Provider value={{ products }}>
      {children}
    </ProductContext.Provider>
  );
};




// import React, { createContext, useContext, useState, useEffect } from 'react';
// import { openDB } from 'idb';
// import LZString from 'lz-string';

// const ProductContext = createContext();

// export const useProductContext = () => useContext(ProductContext);

// export const ProductProvider = ({ children }) => {
//   const [products, setProducts] = useState([]);
//   const apiUrl = process.env.REACT_APP_API_BASE_URL;
//   const companyName = "general-stores";
//   const dynamicURL = companyName ? `/get_products?companyName=${encodeURIComponent(companyName)}` : '/';
//   const getProductUrl = `${apiUrl}${dynamicURL}`;

//   const initDB = async () => {
//     return openDB('ProductDB', 1, {
//       upgrade(db) {
//         if (!db.objectStoreNames.contains('products')) {
//           db.createObjectStore('products', { keyPath: 'id' });
//         }
//       },
//     });
//   };

//   const clearDB = async () => {
//     const db = await initDB();
//     const tx = db.transaction('products', 'readwrite');
//     const store = tx.objectStore('products');
//     await store.clear();
//     await tx.done;
//     console.log("IndexedDB cleared at session end.");
//   };

//   const fetchAndStoreProducts = async (db) => {
//     try {
//       const response = await fetch(getProductUrl, { method: 'GET' });
//       const data = await response.json();

//       if (data && data.length > 0) {
//         // Save in IndexedDB
//         const tx = db.transaction('products', 'readwrite');
//         const store = tx.objectStore('products');
//         for (const product of data) {
//           store.put(product);
//         }
//         await tx.done;

//         setProducts(data);
//       }
//     } catch (error) {
//       console.error('Error fetching data:', error);
//     }
//   };

//   useEffect(() => {
//     const getProducts = async () => {
//       const db = await initDB();

//       // Check if this is the first load in a session
//       const sessionActive = sessionStorage.getItem('sessionActive');
//       if (!sessionActive) {
//         // Mark the session as active
//         sessionStorage.setItem('sessionActive', 'true');

//         // Fetch fresh data and store in IndexedDB
//         await fetchAndStoreProducts(db);
//         return;
//       }

//       // Load products from IndexedDB
//       const tx = db.transaction('products', 'readonly');
//       const store = tx.objectStore('products');
//       const allProducts = await store.getAll();

//       if (allProducts.length > 0) {
//         setProducts(allProducts);
//       } else {
//         // Fetch fresh data and store in IndexedDB
//         await fetchAndStoreProducts(db);
//       }
//     };

//     getProducts();

//     // Handle clearing IndexedDB only when the session ends (tab is closed)
//     const handleBeforeUnload = (event) => {
//       const isTabClosing = !document.visibilityState || document.visibilityState === 'hidden';
//       if (isTabClosing) {
//         clearDB();
//         sessionStorage.removeItem('sessionActive');
//       }
//     };

//     window.addEventListener('visibilitychange', handleBeforeUnload);

//     return () => {
//       window.removeEventListener('visibilitychange', handleBeforeUnload);
//     };
//   }, [getProductUrl]);

//   return (
//     <ProductContext.Provider value={{ products }}>
//       {children}
//     </ProductContext.Provider>
//   );
// };
