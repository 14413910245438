import React, { useState, useEffect, useRef } from "react";
import "./RegisterNew.css";
import { ImEye } from "react-icons/im";
import { PiEyeClosedBold } from "react-icons/pi";
import NoLogo from './No_logo.png';
import { useCompanyContext } from '../../Contexts/CompanyContext';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import PasswordStrengthBar from 'react-password-strength-bar';
import { MdOutlineMail, MdOutlinePhoneAndroid } from "react-icons/md";
import { IoIosLock, IoMdPerson } from "react-icons/io";

const RegisterNew = () => {
    const apiUrl = process.env.REACT_APP_API_BASE_URL;
    const endpoint = '/register';
    const registerUrl = `${apiUrl}${endpoint}`;
    const endpointOtp = '/verify';
    const validateOtpurl = '/validate/otp';
    const endpointOtpUrl = `${apiUrl}${endpointOtp}`;
    const endPointvalidateOtp = `${apiUrl}${validateOtpurl}`;
    const [isOtpVerified, setIsOtpVerified] = useState(false);
    const [isOtpValidated, setIsOtpValidated] = useState();
    const [otp, setOtp] = useState(0);
    const otpRefs = useRef([]);
    const [timer, setTimer] = useState(60);
    const [isTimerExpired, setIsTimerExpired] = useState(false);
    const { companyDetails } = useCompanyContext();
    const companyID = localStorage.getItem('companyID');
    const companyName = localStorage.getItem('companyName');
    const dynamicURL = companyID ? `/home/${companyID}` : '/';
    const [isSendClicked, setIsSendClicked] = useState(false);
    const [resendTriggered, setResendTriggered] = useState(false);
    const [isValidDomain, setIsValidDomain] = useState(true);
    const [isValidOTPButton, setIsValidOTPButton] = useState(true);
    const [isVerifying, setIsVerifying] = useState(false);
    const [formData, setFormData] = useState({
        username: '',
        email: '',
        passcode: Array(4).fill(""),
        password: '',
        repassword: '',
        phone: '',
        companyName: companyName,
    });
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const logoSrc = companyDetails ? `data:image/png;base64,${atob(companyDetails.company_image)}` : '';
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    const handleOtpChange = (e, index) => {
        const value = e.target.value;
        if (!/^\d$/.test(value) && value !== "") return; // Allow only digits

        const newOtp = [...formData.passcode];
        newOtp[index] = value;
        // setOtp(newOtp);
        setFormData({ ...formData, passcode: newOtp.join("") }); // Update passcode in formData

        // Move to the next input if a digit is entered
        if (value && index < otpRefs.current.length - 1) {
            otpRefs.current[index + 1].focus();
        }
    };

    // Handle keyboard events
    const handleOtpKeyDown = (e, index) => {
        if (e.key === "Backspace") {
            const newOtp = [...formData.passcode];
            newOtp[index] = ""; // Clear current input
            // setOtp(newOtp);
            setFormData({ ...formData, passcode: newOtp.join("") }); // Update passcode in formData

            // Move to the previous input if the current one is empty
            if (index > 0 && !formData.passcode[index]) {
                otpRefs.current[index - 1].focus();
            }
        } else if (e.key === "ArrowLeft" && index > 0) {
            otpRefs.current[index - 1].focus(); // Navigate to the previous input
        } else if (e.key === "ArrowRight" && index < otpRefs.current.length - 1) {
            otpRefs.current[index + 1].focus(); // Navigate to the next input
        }
    };

    const decrementTimer = () => {
        setTimer((prevTimer) => {
            const minutes = Math.floor(prevTimer / 60);
            const seconds = prevTimer % 60;
            if (prevTimer <= 0) {
                return 0;
            }
            return (minutes * 60) + seconds - 1;
        });
    };

    useEffect(() => {
        if (isOtpVerified || resendTriggered) {
            const timerId = setInterval(decrementTimer, 800);
            return () => {
                clearInterval(timerId);
            };
        }
    }, [isOtpVerified, resendTriggered]);

    useEffect(() => {
        if (timer === 0) {
            setIsTimerExpired(true);
        }
    }, [timer]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handlePhoneChange = (e) => {
        const value = e.target.value;
        if (/^\d{0,10}$/.test(value)) {
            setFormData({ ...formData, phone: value });
        }
    };

    const verifyOtp = async (e) => {
        e.preventDefault();
        setIsVerifying(true);

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(formData.email.trim())) {
            Swal.fire({
                icon: 'error',
                title: 'Invalid Email',
                text: 'Please enter a valid email address.',
                customClass: {
                    confirmButton: 'custom-confirm-button'
                }
            });
            setIsVerifying(false);
            return;
        }

        try {
            const response = await fetch(endpointOtpUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                const responseData = await response.json();
                const success = responseData.result.success;
                if (responseData.result.success === 'YES') {
                    const otp = responseData.result.emailOtp;
                    setOtp(otp);
                    setTimer(60);
                    setIsSendClicked(true);
                    setIsOtpVerified(true);
                    setIsPopupOpen(true);
                    Swal.fire({
                        icon: 'info',
                        title: 'Success',
                        text: responseData.result.message,
                        customClass: {
                            confirmButton: 'custom-confirm-button'
                        }
                    });
                }
                if (responseData.result.success === 'NO') {
                    localStorage.removeItem('user');
                    Swal.fire({
                        icon: 'info',
                        title: 'Error',
                        text: responseData.result.message,
                        customClass: {
                            confirmButton: 'custom-confirm-button'
                        }
                    });
                }
            } else {
                console.error('Failed to submit form:', response.statusText);
                localStorage.removeItem('user');
            }
        } catch (error) {
            localStorage.removeItem('user');
            console.error('An error occurred:', error);
        } finally {
            setIsVerifying(false);
        }
    };

    const validateOtp = async (e) => {
        e.preventDefault();
        if (formData.passcode !== otp) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: "Please enter correct OTP",
                customClass: {
                    confirmButton: 'custom-confirm-button'
                }
            });
        }
        try {
            const response = await fetch(endPointvalidateOtp, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ ...formData, otp: otp }),
            });
            if (response.ok) {
                const responseData = await response.json();
                const success = responseData.result.success;
                if (responseData.result.success === 'YES') {
                    setIsOtpValidated(true);
                    setIsPopupOpen(false);
                    setIsValidOTPButton(true);
                }
                if (responseData.result.success === 'NO') {
                    setIsValidOTPButton(false);
                }
            } else {
                console.error('Failed to submit form:', response.statusText);
                localStorage.removeItem('user');
            }
        } catch (error) {
            localStorage.removeItem('user');
            console.error('An error occurred:', error);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!formData.username.trim()) {
            Swal.fire({
                icon: 'Error',
                title: 'Error',
                text: 'Name is mandatory',
                customClass: {
                    confirmButton: 'custom-confirm-button'
                }

            })
            return;
        }
        if (!formData.password.trim() && !formData.repassword.trim()) {
            Swal.fire({
                icon: 'Error',
                title: 'Error',
                text: 'Password is Mandatory',
                customClass: {
                    confirmButton: 'custom-confirm-button'
                }
            })
            return;
        }
        try {
            const response = await fetch(registerUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });
            if (response.ok) {
                const responseData = await response.json();
                const resultData = JSON.parse(responseData.result);

                // Handle success or failure based on resultData
                if (resultData.success === 'YES') {
                    localStorage.setItem('user', JSON.stringify({ user: resultData.username, email: resultData.email, user_id: resultData.user_id }));
                    Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: resultData.message,
                        confirmButtonText: 'Continue',
                        customClass: {
                            confirmButton: 'custom-confirm-button'
                        }
                    }).then((result) => {
                        if (result.isConfirmed) {
                            if (location.state && location.state.fromCart.fromCheckout) {
                                navigate('/cart');
                            } else {
                                // Redirect to home page after successful login
                                window.location.href = '/';
                            }
                        }
                    });
                } else {
                    localStorage.removeItem('user');
                    Swal.fire({
                        icon: 'info',
                        title: 'Error',
                        text: resultData.message,
                        customClass: {
                            confirmButton: 'custom-confirm-button'
                        }
                    });
                }
            } else {
                console.error('Failed to submit form:', response.statusText);
                localStorage.removeItem('user');
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Internal server error while submit Form.',
                    customClass: {
                        confirmButton: 'custom-confirm-button'
                    }
                });
            }
        } catch (error) {
            localStorage.removeItem('user');
            console.error('An error occurred:', error);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Internal Sever Error.',
                customClass: {
                    confirmButton: 'custom-confirm-button'
                }
            });
        }
    };

    return (
        <>
            {/* <nav className="navbar">
                <div className="navbar-image">
                    <Link to={dynamicURL}>
                        {logoSrc ? <img src={logoSrc} className="logo" alt="Company Logo"
                            style={{ height: '60px', width: '60px', borderRadius: '50%', marginTop: '4px', border: '1px solid black', marginBottom: '1px' }} /> : <img src={NoLogo} className="logo" alt="Company Logo"
                                style={{ height: '60px', width: '60px', borderRadius: '50%', marginTop: '4px', border: '1px solid black', marginBottom: '1px' }} />}
                    </Link>
                </div>
            </nav> */}
            <div className="signup-container">
                <div className="signup-card">
                    <div className="logo">
                        <Link to={dynamicURL}>
                            {logoSrc ? <img src={logoSrc} style={{ width: '60px', height: '60px', borderRadius: '50%', border: '1px solid gray' }} alt="Company Logo" /> : <img src={NoLogo} style={{ width: '60px', height: '60px', borderRadius: '50%', border: '1px solid gray' }} alt="No logo" />}
                        </Link>
                    </div>
                    <h2>Let's Get Started</h2>
                    <p>Sign up your account</p>
                    <form>
                        <div className="form-group">
                            <span className="input-icon"><IoMdPerson /></span>
                            <input
                                type="text"
                                name="username"
                                placeholder="Full Name"
                                value={formData.username}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    // Allow only alphabets and spaces
                                    if (/^[a-zA-Z\s]*$/.test(value)) {
                                        handleChange(e);
                                    }
                                }}
                                // onChange = { handleChange }
                                required
                            />
                        </div>
                        <div className="form-group">
                            <span className="input-icon"><MdOutlinePhoneAndroid /></span>
                            <input
                                type="text"
                                name="phone"
                                placeholder="Mobile"
                                value={formData.phone}
                                onChange={(e) => handlePhoneChange(e)}
                                required
                                disabled={isOtpValidated}
                            />
                        </div>
                        <div className="form-group">
                            <span className="input-icon"><MdOutlineMail /></span>
                            <input
                                type="email"
                                name="email"
                                placeholder="Email Address"
                                value={formData.useremail}
                                onChange={handleChange}
                                disabled={isOtpValidated}
                                required
                            />
                        </div>
                        {isOtpValidated &&
                            <div className="form-group">
                                <span className="input-icon"><IoIosLock /></span>
                                <input
                                    type={showPassword ? "text" : "password"}
                                    name="password"
                                    placeholder="Password"
                                    value={formData.password}
                                    onChange={handleChange}
                                    required
                                    disabled={!isOtpValidated}
                                />
                                <button
                                    type="button"
                                    className="toggle-visibility"
                                    onClick={() => setShowPassword(!showPassword)}
                                >
                                    {showPassword ? <PiEyeClosedBold /> : <ImEye />}
                                </button>

                            </div>
                        }
                        {formData.password !== '' &&
                            <PasswordStrengthBar
                                password={formData.password}
                                minLength={8} // Adjust the minimum length as required
                                shortScoreWord="Too Short"
                                scoreWords={["Weak", "Fair", "Good", "Strong", "Very Strong"]}
                            />
                        }

                        {isOtpValidated &&
                            <div className="form-group">
                                <span className="input-icon"><IoIosLock /></span>
                                <input
                                    type={showConfirmPassword ? "text" : "password"}
                                    name="repassword"
                                    placeholder="Confirm Password"
                                    value={formData.confirm_password}
                                    onChange={handleChange}
                                    required
                                    disabled={!isOtpValidated}
                                />
                                <button
                                    type="button"
                                    className="toggle-visibility"
                                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                >
                                    {showConfirmPassword ? <PiEyeClosedBold /> : <ImEye />}
                                </button>
                            </div>
                        }

                        {!isOtpValidated ?
                            <button className="submit-btn" onClick={verifyOtp}>
                                Send OTP
                            </button> :
                            <button className="submit-btn" onClick={handleSubmit}>
                                Continue
                            </button>
                        }
                    </form>
                    <p className="terms">
                        By continuing you agree to our{" "} <br />
                        <a href="https://www.mydigitaldukan.com/terms" target="_blank">
                            Terms & Conditions
                        </a>
                    </p>
                    <p className="signin-link">
                        Already have an account? <a href="/login">Sign in</a>
                    </p>
                </div>

                {isPopupOpen && (
                    <div className="otp-popup-overlay">
                        <div className="otp-popup">
                            <button className="close-btn" onClick={() => setIsPopupOpen(false)}>&times;</button>
                            <h4>Email Verification</h4>
                            <p style={{ fontSize: '14px' }}>Please type the code we sent you in your email</p>
                            <div className="otp-inputs">
                                {Array(4).fill("").map((_, index) => (
                                    <input
                                        key={index}
                                        type="text"
                                        maxLength="1"
                                        className="otp-input"
                                        value={formData.passcode[index] || ""}
                                        onChange={(e) => handleOtpChange(e, index)}
                                        onKeyDown={(e) => handleOtpKeyDown(e, index)}
                                        ref={(el) => (otpRefs.current[index] = el)}
                                    />
                                ))}
                            </div>
                            <button className="submit-btn" onClick={validateOtp} style={{ width: '70%' }}>Verify</button>
                            <p className="timer">{`0${Math.floor(timer / 60)}:${timer % 60 < 10 ? `0${timer % 60}` : timer % 60}`}</p>
                            {timer === 0 &&
                                <p style={{ fontSize: '14px' }}>
                                    Didn't get code on your email? <span className="resend-link" onClick={verifyOtp}>Resend OTP</span>
                                </p>
                            }
                        </div>
                    </div>
                )}


            </div>
        </>
    );
};

export default RegisterNew;
