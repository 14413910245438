import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useProductContext } from '../../Contexts/ProductContext';
import { useCompanyContext } from '../../Contexts/CompanyContext';
import CategoryCarousel from '../CategoryCarousel/CategoryCarousel';
import TrendingItemsCarousel from '../Trending/Trending';
import { Carousel2 } from '../Carousel2/Carousel2';
import { Banner } from '../Banner/Banner';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import CategoryCarouselMobile from '../CategoryCarouselMobile/CategoryCarouselMobile';
import HomePageProductSlider from '../Carousel/Carousel';
import { HomePageExtend } from '../../Apps/HomePageExtend';
import './Homepage.css';
import MobileFooter from '../MobileFooter/MobileFooter';
import { Helmet } from 'react-helmet';
import NoProduct from './noproduct.png';
import Swal from 'sweetalert2';
// import banner1 from './banner.jpg';
import banner1 from './newBanner_1.webp';
import banner2 from './banner.jpg';
import CategoryWisePorducts from '../CategoryWisePorducts/CategoryWisePorducts';
import FilteredCategoryProduct from '../FilteredCategoryProduct/FilteredCategoryProduct';
import Footer from '../Footer/Footer';
import MapContainer from 'react-ola-maps-wrapper';

const Homepage = ({ searchTerm }) => {
  const navigate = useNavigate();
  const { products, loading } = useProductContext();
  const { companyDetails } = useCompanyContext();
  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  const [dataLoaded, setDataLoaded] = useState(false);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768);

  const placeholderArray = ['Search "sugar"', 'Search "flour"', 'Search "bread"', 'Search "milk"'];
  const [placeholderIndex, setPlaceholderIndex] = useState(0);
  const [currentPlaceholder, setCurrentPlaceholder] = useState(placeholderArray[0]);
  const companyName = localStorage.getItem('companyName');
  const companyImage = localStorage.getItem('companyImage');
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [location, setLocation] = useState({ latitude: null, longitude: null });
  const [error, setError] = useState('');
  // const [markers, setMarkers] = useState([
  //   {
  //     longitude: 77.6165,
  //     latitude: 12.9314,
  //     draggable: true,
  //   },
  // ]);
  const [markers, setMarkers] = useState([]);

  useEffect(() => {
    const hasReloaded = sessionStorage.getItem('hasReloaded');

    if (!hasReloaded) {
      sessionStorage.setItem('hasReloaded', 'true');
      window.location.reload();
    }

  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 553);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {

    if (!loading && products.length > 0) {
      setDataLoaded(true);
    }
  }, [loading, products]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setPlaceholderIndex(prevIndex => (prevIndex + 1) % placeholderArray.length);
    }, 1500); // 2000ms = 2 seconds

    setCurrentPlaceholder(placeholderArray[placeholderIndex]);

    return () => clearInterval(intervalId);
  }, [placeholderIndex, placeholderArray]);

  const handleMarkerDragEnd = (index, newLongitude, newLatitude) => {
    const updatedMarkers = [...markers];
    // setLocation({ latitude: newLatitude, longitude: newLongitude });
    updatedMarkers[index] = {
      ...updatedMarkers[index],
      longitude: newLongitude,
      latitude: newLatitude,
    };
    setMarkers(updatedMarkers);
    setLocation({ latitude: newLatitude, longitude: newLongitude });
  };

  const mapEventListeners = {
    onLoad: () => console.log('Map loaded successfully'),
    onFail: (error) => console.error('Map failed to load', error),
  };

  var abc;
  if (products && products.length > 0) {
    const bannerObjValDot = products[0];
    abc = bannerObjValDot['bannerObjVal'];
  }

  const decodeBase64Image = (base64) => {
    const decodedImage = new Image();
    decodedImage.src = `data:image/jpeg;base64,${atob(base64)}`;
    return decodedImage.src;
  };

  // const getLocation = () => {
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition(
  //       (position) => {
  //         const { latitude, longitude } = position.coords;
  //         setLocation({ latitude, longitude });
  //         reverseGeocode(latitude, longitude);
  //         setError(''); // Clear any previous errors
  //       },
  //       (err) => {
  //         setError(err.message);
  //       }
  //     );
  //   } else {
  //     setError('Geolocation is not supported by this browser.');
  //   }
  // };

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.permissions
        .query({ name: "geolocation" })
        .then(function (result) {
          console.log("geolocation permission==============", result);
        });
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLocation({ latitude, longitude });
          setMarkers([{ latitude, longitude, draggable: true }]);
          setError(''); // Clear error if successful
        },
        (err) => {
          if (err.code === 1) {
            setError(
              'Location access denied. Please enable it in your browser settings.'
            );
          } else if (err.code === 2) {
            setError('Location unavailable. Please try again.');
          } else if (err.code === 3) {
            setError('Location request timed out. Please try again.');
          } else {
            setError('An unknown error occurred.');
          }
        }
      );
    } else {
      setError('Geolocation is not supported by this browser.');
    }
  };

  const reverseGeocode = async (latitude, longitude) => {
    try {
      const apiKey = 'EwaKsVz8Mx4bhaCEvyDcxzN8yMfd7vmTAqDY3emJ';
      const url = `https://api.olamaps.io/places/v1/reverse-geocode?latlng=${latitude},${longitude}&api_key=${apiKey}`;

      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
        },
      });

      console.log("response in reverse geocode========", response)

      if (!response.ok) {
        throw new Error(`Error: ${response.status} ${response.statusText}`);
      }

      const data = await response.json();
      console.log("response data in reverse geocode========", data)
      console.log("response data in reverse geocode========", data.results[0])
      // setLocation(data);
    } catch (err) {
      setError(err.message);
    }
  };



  return (
    <div className="homepage-container" style={{ minHeight: '600px', position: 'relative', paddingBottom: 0 }}>
      {!dataLoaded && (
        <div className="overlay">
          {/* <div className='loader' style={{ position: 'absolute', top: '30%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: '9999' }}> */}

          <Box sx={{ display: 'flex' }}>
            <CircularProgress size={60} />
          </Box>
          {/* </div> */}
        </div>
      )
      }
      {/* <br /> */}
      {dataLoaded && (
        //   {products.length === 0 ? (
        //     <div className="no-products">
        //       <img src={NoProduct} alt='No Product' />
        //     </div>
        <>
          <div className='banner1'>
            {companyDetails && companyDetails.app_banner ?
              <img
                src={decodeBase64Image(companyDetails.app_banner)}
                alt="banner"
                loading="lazy"
                className="img-fluid circular-img"
                style={{ borderRadius: '30px' }}
              /> :
              <img
                src={banner1}
                alt="banner"
                loading="lazy"
                className="img-fluid circular-img"
                style={{ borderRadius: '30px' }}
              />
            }
          </div>

          {/* <div>
            <button onClick={getLocation}>Get Current Location</button>
            {location.latitude && location.longitude ? (

              <>
                <MapContainer
                  apiKey="EwaKsVz8Mx4bhaCEvyDcxzN8yMfd7vmTAqDY3emJ"
                  width="50%"
                  height="300px"
                  center={{ latitude: location.longitude, longitude: location.latitude }}
                  zoom={15}
                  markers={markers.map((marker, index) => ({
                    ...marker,
                    // Add dragend event handler for each marker
                    popUp: <div>Drag me!</div>,
                    onDragEnd: (event) => {
                      const { longitude, latitude } = event.target;
                      console.log("longitude =======================",longitude);
                      console.log("latitude =======================",latitude);
                      handleMarkerDragEnd(index, longitude, latitude);
                    },
                  }))}
                  mapEventListners={mapEventListeners}
                  showCompass={true}
                  showZoom={true}
                />
                <p>
                  Latitude: {location.latitude}, Longitude: {location.longitude}
                </p>
              </>
            ) : error ? (
              <p>Error: {error}</p>
            ) : (
              <p>Click the button to get your location.</p>
            )}
          </div> */}
          {/* {isMobileView ? (
            <CategoryCarouselMobile searchTerm={searchTerm} />
          ) : (
            <CategoryCarousel searchTerm={searchTerm} />
          )} */}
          <br />
          <br />
          <Carousel2 searchTerm={searchTerm} activeCategory={selectedCategory}
            onCategorySelect={setSelectedCategory} />

          <br />

          <FilteredCategoryProduct selectedCategory={selectedCategory} />
          <TrendingItemsCarousel />
          <div className='banner1'>
            {companyDetails && companyDetails.app_banner_2 ?
              <img
                src={decodeBase64Image(companyDetails.app_banner_2)}
                alt="banner"
                loading="lazy"
                className="img-fluid circular-img"
                style={{ borderRadius: '30px' }}
              /> :
              <img
                src={banner2}
                alt="banner"
                loading="lazy"
                className="img-fluid circular-img"
                style={{ borderRadius: '30px' }}
              />
            }
          </div>
          <CategoryWisePorducts />
          <br />
          <Footer />
        </>
      )
      }
      {
        isMobileView && (
          <MobileFooter />
        )
      }

    </div >
  );
}

export default Homepage;
