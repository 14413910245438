// import React, { useState, useEffect } from 'react';
// import { Link } from "react-router-dom";
// import { useCompanyContext } from '../../Contexts/CompanyContext';
// import logoblue from './eShop-LOGO-Blue.png'
// import './Footer.css';

// export default function Footer() {
//   // eslint-disable-next-line no-unused-vars
//   const [userName, setUserName] = useState('');
//   const companyID = localStorage.getItem('companyID');
//   const { companyDetails } = useCompanyContext();

//   const logoSrc = companyDetails ? `data:image/png;base64,${atob(companyDetails.company_image)}` : '';
//   const address = companyDetails ? companyDetails.company_address : {};
//   const companyName = companyDetails ? companyDetails.company_name : 'My Company';
//   const companyPhone = companyDetails ? companyDetails.company_phone : '1234567890';
//   const companyEmail = companyDetails ? companyDetails.company_email : 'info@yourcompany.com';

//   const addressLine1 = address.street ? `${address.street}${address.street2 ? ', ' + address.street2 : ''}` : '';
//   const addressLine2 = address.city ? `${address.city}${address.state ? ', ' + address.state : ''}${address.zip ? ' ' + address.zip : ''}` : '';
//   const fullAddress = `${addressLine1}, ${addressLine2}, ${address.country || ''}`;

//   useEffect(() => {
//     // Retrieve user information from localStorage
//     const storedUser = localStorage.getItem('user');
//     if (storedUser) {
//       const { user } = JSON.parse(storedUser);
//       setUserName(user);
//     }
//   }, []);

//   return (
//     <footer className="footer">
//       <div className="container">
//         <div className="row">
//           <div className="col-md-3">
//             <div className="footer-content">
//               {/* <h2>About Us</h2> */}
//               <div className="logo">{logoSrc ? <img src={logoSrc} className='header-logo' alt="Company Logo"
//                     style={{height:'1%',width:'65px'}} /> : <span>No Logo</span>}</div>
//               <div className="map bold" style={{ fontSize: 12 }}>
//                 <i
//                   className="fas fa-map-marker-alt mr-2"
//                   style={{ marginRight: 5, color: "#009aff" }}
//                 />
//                 <br />
//                 <b>Store Address</b>
//               </div>
//               <div className="a">{fullAddress}</div>
//               <div className="a">{companyPhone}</div>
//               <div className="a">{companyEmail}</div>
//             </div>
//           </div>
//           <div className="col-md-2">
//             <div className="footer-content">
//               <h2>INFOMATION</h2>
//               <ul>
//                 <li>
//                   <Link to="/about">About Us</Link>
//                 </li>
//                 {/* <li>
//                   <a href="">Brands</a>
//                 </li>
//                 <li>
//                   <a href="">Gift Vouchers</a>
//                 </li>
//                 <li>
//                   <a href="">Site Map</a>
//                 </li>
//                 <li>
//                   <a href="">Accessories</a>
//                 </li> */}
//               </ul>
//             </div>
//           </div>
//           <div className="col-md-2">
//             <div className="footer-content">
//               <h2>MY ACCOUNT</h2>
//               <ul>
//                 {/* <li>
//                   <a href="">My Account</a>
//                 </li>
//                 <li>
//                   <a href="">Order Tracking</a>
//                 </li> */}
//                 <li>
//                   <Link to="/wishlist">Wishlist</Link>
//                 </li>
//                 <li>
//                   <Link to="/cart">Cart</Link>
//                 </li>
//                 {/* <li>
//                   <a href="">Checkout</a>
//                 </li> */}
//               </ul>
//             </div>
//           </div>
//           <div className="col-md-2">
//             <div className="footer-content">
//               {/* <h2>CATEGORIES</h2>
//               <ul>
//                 <li>
//                   <a href="">Mobile</a>
//                 </li>
//                 <li>
//                   <a href="">Headphone</a>
//                 </li>
//                 <li>
//                   <a href="">Laptop</a>
//                 </li>
//                 <li>
//                   <a href="">New Arrivals</a>
//                 </li>
//                 <li>
//                   <a href="">All Categories</a>
//                 </li>
//               </ul> */}
//             </div>
//           </div>
//           <div className="col-md-3">
//             <div className="footer-content">
//               <h2>SUBSCRIBE TO OUR NEWSLETTER</h2>
//               <p>
//                 Please enter email address to create or update your profile and
//                 manage your subscriptions:
//               </p>
//               <form method="" className="relative d-flex align-items-center">
//                 <input
//                   type="email"
//                   name="email"
//                   id="email"
//                   placeholder="Your email*"
//                   className="form-control input-newletter"
//                   required=""
//                 />
//                 <span className="input-group-btn" style={{ marginBottom: '21px' }}>
//                   <input
//                     className="btn btn-newsletter"
//                     type="submit"
//                     defaultValue="Sign Up"
//                     style={{ color: 'white' }}
//                   />
//                 </span>
//               </form>
//               {/*<div className="social-links">
//                 <a href="#">
//                   <i className="fab fa-facebook-f" />
//                 </a>
//                 <a href="#">
//                   <i className="fab fa-twitter" />
//                 </a>
//                 <a href="#">
//                   <i className="fab fa-linkedin-in" />
//                 </a>
//                 <a href="#">
//                   <i className="fab fa-instagram" />
//                 </a>
//               </div>*/}
//             </div>
//           </div>
//         </div>
//       </div>
//     </footer>


//   );
// };



import React from 'react';
import './Footer.css';
import { useCompanyContext } from '../../Contexts/CompanyContext';
import NoLogo from './No_logo.png';
import Visa from './visa.png';
import MasterCard from './Mastercard-Logo.png';
import Amex from './amex.jpg';
import PayPal from './PayPal.png';
import DemoQR from './demoQR.png';
import { FaTwitter, FaFacebook, FaLinkedin, FaYoutube, FaInstagramSquare } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import { AiOutlineX } from "react-icons/ai";
import { GrInstagram } from "react-icons/gr";

const Footer = () => {
  const navigate = useNavigate();
  const { companyDetails } = useCompanyContext();
  const logoSrc = companyDetails ? `data:image/png;base64,${atob(companyDetails.company_image)}` : '';
  const companyName = localStorage.getItem('companyName');

  // const cleanText = (html) => {
  //   const cleanText = html.replace(/<[^>]+>/g, '')
  //   return cleanText;
  // };

  const cleanText = (html) => {
    if (typeof html !== 'string') {
      return ''; // Return an empty string for non-string inputs
    }
    return html.replace(/<[^>]+>/g, ''); // Remove HTML tags from strings
  };


  return (
    <footer className="footer">
      {/* <div className='footer-header'>
        {logoSrc ? <img src={logoSrc} className='header-logo' alt="Company Logo"
          style={{ height: '40px', width: '40px', borderRadius: '50%', border: '1px solid black', marginBottom: '1px' }} /> : <img src={NoLogo} className='header-logo' alt="Company Logo"
            style={{ height: '40px', width: '40px', borderRadius: '50%', marginTop: '4px', border: '1px solid black', marginBottom: '1px' }} />}
        <h4 style={{ marginLeft: '10%' }}>{companyDetails ? companyDetails.company_name : "Shop"}</h4>
      </div> */}
      <div className="footer-container">
        <div className="footer-section">
          <h4>Customer Service</h4>
          <ul>
            {companyDetails && (
              <>
                {companyDetails.helpCenter && cleanText(companyDetails.helpCenter) !== '' ? (
                  <li>
                    <a onClick={() => navigate('/help-center')}>Help Centre</a>
                  </li>
                ) : (
                  <li>
                    <span className="disabled-link">Help Centre</span>
                  </li>
                )}
                {companyDetails.return_refund && cleanText(companyDetails.return_refund) !== '' ? (
                  <li>
                    <a onClick={() => navigate('/return')}>Return & Refund</a>
                  </li>
                ) : (
                  <li>
                    <span className="disabled-link">Return & Refund</span>
                  </li>
                )}
                {companyDetails.warranty && cleanText(companyDetails.warranty) !== '' ? (
                  <li>
                    <a onClick={() => navigate('/warranty')}>Warranty Policy</a>
                  </li>
                ) : (
                  <li>
                    <span className="disabled-link">Warranty Policy</span>
                  </li>
                )}
              </>
            )}
          </ul>
          {/* <ul>
            {companyDetails && companyDetails.helpCenter !== '' &&
              <li><a onClick={() => navigate('/help-center')}>Help Centre</a></li>}
            {companyDetails && companyDetails.return_refund !== '' &&
              <li><a onClick={() => navigate('/return')}>Return & Refund</a></li>}
            {companyDetails && companyDetails.warranty !== '' &&
              <li><a onClick={() => navigate('/warranty')}>Warranty Policy</a></li>}
          </ul> */}
        </div>
        <div className="footer-section">
          <h4>About</h4>
          <ul>
            {companyDetails && (
              <>
                {companyDetails.contactUs && cleanText(companyDetails.contactUs) !== '' ? (
                  <li>
                    <a onClick={() => navigate('/contact')}>Contact Us</a>
                  </li>
                ) : (
                  <li>
                    <span className="disabled-link">Contact Us</span>
                  </li>
                )}
                {companyDetails.aboutUs && cleanText(companyDetails.aboutUs) !== '' ? (
                  <li>
                    <a onClick={() => navigate('/about')}>About Us</a>
                  </li>
                ) : (
                  <li>
                    <span className="disabled-link">About Us</span>
                  </li>
                )}
                {companyDetails.privacyPolicy && cleanText(companyDetails.privacyPolicy) !== '' ? (
                  <li>
                    <a onClick={() => navigate('/privacy-policy')}>Privacy Policy</a>
                  </li>
                ) : (
                  <li>
                    <span className="disabled-link">Privacy Policy</span>
                  </li>
                )}
              </>
            )}
          </ul>
          {/* <ul>
            {companyDetails && cleanText(companyDetails.contactUs) !== '' &&
              <li><a onClick={() => navigate('/contact')}>Contact Us</a></li>}
            {companyDetails && companyDetails.aboutUs !== '' &&
              <li><a onClick={() => navigate('/about')}>About Us</a></li>}
            {companyDetails && companyDetails.privacyPolicy !== '' &&
              <li><a onClick={() => navigate('/privacy-policy')}>Privacy Policy</a></li>}
            <li><a>Seller Centre</a></li>
          </ul> */}
        </div>
        <div className="footer-section">
          <h4>Payment</h4>
          <div className="payment-icons">
            <img src={Visa} alt="Visa" />
            <img src={MasterCard} alt="MasterCard" />
            <img src={Amex} alt="Amex" />
            <img src={PayPal} alt="PayPal" />
          </div>
        </div>
        {/* <div className="footer-section">
          <h4>Download App</h4>
          <div className="qr-code">
            <img src={DemoQR} alt="QR Code" />
          </div>
        </div> */}
        <div className="footer-section">
          <h4>{companyDetails ? companyDetails.company_name : "Digital Dukan"}</h4>
          <div className="qr-code">
            <img src={logoSrc ? logoSrc : NoLogo} alt="Dukan Logo" style={{ width: '90px', height: '90px', borderRadius: '50%' }} />
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div>
          {/* <select name="language" className="language-select">
            <option value="en">English</option>
            <option value="hi">Hindi</option>
          </select> */}
        </div>
        <div>© 2025 {companyDetails ? companyDetails.company_name : "Digital Dukan"}.  {companyDetails && cleanText(companyDetails.privacyPolicy) ? <> • <a onClick={() => navigate('/privacy-policy')}>Privacy</a> </> : <> • <span>Privacy</span> </>}  {companyDetails && cleanText(companyDetails.terms) ? <>• <a onClick={() => navigate('/terms')}>Terms</a> </> : <>• <span>Terms</span> </>}  {companyDetails && cleanText(companyDetails.agreement) ? <>• <a onClick={() => navigate('/agreement')}>Agreement</a> </> : <>• <span>Agreement</span> </>}</div>
        <div className="social-icons">
          {companyDetails && companyDetails.twitter &&
            <a href={companyDetails.twitter} target="_blank"><AiOutlineX style={{ color: '#000' }} /></a>}
          {companyDetails && companyDetails.facebook &&
            <a href={companyDetails.facebook} target="_blank"><FaFacebook style={{ color: '#254483' }} /></a>}
          {companyDetails && companyDetails.linkedin &&
            <a href={companyDetails.linkedin} target="_blank"><GrInstagram style={{ color: '#C13584' }} /></a>}
          {companyDetails && companyDetails.youtube &&
            <a href={companyDetails.youtube} target="_blank"><FaYoutube style={{ color: 'red' }} /></a>}
        </div>
      </div>
    </footer>
  );
};

export default Footer;

{/* <FaTwitter style={{ color: '#08a0e9' }} /> */ }
