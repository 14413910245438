import React, { createContext, useContext, useState, useEffect } from 'react';

const CartContext = createContext();

export const useCartContext = () => useContext(CartContext);

export const CartProvider = ({ children }) => {
  //cartItems state from local storage
  const [cartItems, setCartItems] = useState(() => {
    const storedCartItems = localStorage.getItem('cartItems');
    let parsedCartItems = [];

    try {
      if (storedCartItems) {
        parsedCartItems = JSON.parse(storedCartItems);
      }
    } catch (error) {
      console.error('Error parsing cart items:', error);
      // Handle the error here, such as resetting local storage or displaying a message to the user
      // For example, you could reset local storage to prevent further issues:
      localStorage.removeItem('cartItems');
    }

    return parsedCartItems;
  });

  // const addToCart = (product) => {
  //   const storedCartItems = JSON.parse(localStorage.getItem('cartItems')) || [];

  //   const existingProductIndex = storedCartItems.findIndex(item => item.id === product.id);
  //   if (existingProductIndex !== -1) {
  //     const updatedCartItems = [...storedCartItems];
  //     updatedCartItems[existingProductIndex].quantity += 1;
  //     setCartItems(updatedCartItems);
  //     localStorage.setItem('cartItems', JSON.stringify(updatedCartItems));
  //   } else {
  //     const updatedCartItems = [...storedCartItems, { ...product, quantity: 1 }];
  //     setCartItems(updatedCartItems);
  //     localStorage.setItem('cartItems', JSON.stringify(updatedCartItems));
  //   }
  // };

  const addToCart = (product) => {
    const storedCartItems = JSON.parse(localStorage.getItem('cartItems')) || [];

    // Remove unnecessary properties from the product
    const { parent_category_image, category_icon, ...productWithoutImages } = product;

    const existingProductIndex = storedCartItems.findIndex(item => item.id === product.id);
    if (existingProductIndex !== -1) {
      const updatedCartItems = [...storedCartItems];
      updatedCartItems[existingProductIndex].quantity += 1;
      setCartItems(updatedCartItems);
      localStorage.setItem('cartItems', JSON.stringify(updatedCartItems));
    } else {
      const updatedCartItems = [
        ...storedCartItems,
        { ...productWithoutImages, quantity: 1 },
      ];
      setCartItems(updatedCartItems);
      localStorage.setItem('cartItems', JSON.stringify(updatedCartItems));
    }
  };

  const addMultipleToCart = (products) => {
    const storedCartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
    const updatedCartItems = [...storedCartItems];

    products.forEach(product => {
      // Remove unnecessary properties
      const { parent_category_image, category_icon, ...productWithoutImages } = product;

      const existingProductIndex = updatedCartItems.findIndex(item => item.id === productWithoutImages.id);
      if (existingProductIndex !== -1) {
        updatedCartItems[existingProductIndex].quantity += productWithoutImages.quantity || 1;
      } else {
        updatedCartItems.push({
          ...productWithoutImages,
          quantity: productWithoutImages.quantity || 1,
        });
      }
    });

    setCartItems(updatedCartItems);
    localStorage.setItem('cartItems', JSON.stringify(updatedCartItems));
  };



  // const addMultipleToCart = (products) => {
  //   const storedCartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
  //   const updatedCartItems = [...storedCartItems];

  //   products.forEach(product => {
  //     const existingProductIndex = updatedCartItems.findIndex(item => item.id === product.id);

  //     if (existingProductIndex !== -1) {
  //       updatedCartItems[existingProductIndex].quantity += product.quantity || 1;
  //     } else {
  //       updatedCartItems.push({ ...product, quantity: product.quantity || 1 });
  //     }
  //   });

  //   setCartItems(updatedCartItems);
  //   localStorage.setItem('cartItems', JSON.stringify(updatedCartItems));
  // };

  const removeFromCart = (productId) => {
    const storedCartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
    const updatedCartItems = storedCartItems.filter(item => item.id !== productId);
    setCartItems(updatedCartItems);
    localStorage.setItem('cartItems', JSON.stringify(updatedCartItems));
  };

  const increaseQuantity = (productId) => {
    const storedCartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
    const existingProductIndex = storedCartItems.findIndex(item => item.id === productId);
    if (existingProductIndex !== -1) {
      const updatedCartItems = [...storedCartItems];
      updatedCartItems[existingProductIndex].quantity += 1;
      setCartItems(updatedCartItems);
      localStorage.setItem('cartItems', JSON.stringify(updatedCartItems));
    }
  };

  const decreaseQuantity = (productId) => {
    const storedCartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
    const existingProductIndex = storedCartItems.findIndex(item => item.id === productId);
    if (existingProductIndex !== -1) {
      const updatedCartItems = [...storedCartItems];
      if (updatedCartItems[existingProductIndex].quantity > 1) {
        updatedCartItems[existingProductIndex].quantity -= 1;
        setCartItems(updatedCartItems);
        localStorage.setItem('cartItems', JSON.stringify(updatedCartItems));
      }
    }
  };

  const setQuantity = (productId, quantity) => {
    const storedCartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
    const updatedCartItems = [...storedCartItems];
    const existingProductIndex = updatedCartItems.findIndex(item => item.id === productId);

    if (existingProductIndex !== -1) {
      if (quantity > 0) {
        updatedCartItems[existingProductIndex].quantity = quantity;
      } else {
        // Remove product if quantity is set to 0
        updatedCartItems.splice(existingProductIndex, 1);
      }
      setCartItems(updatedCartItems);
      localStorage.setItem('cartItems', JSON.stringify(updatedCartItems));
    } else if (quantity > 0) {
      // If the product is not in the cart, add it with the specified quantity
      updatedCartItems.push({ id: productId, quantity });
      setCartItems(updatedCartItems);
      localStorage.setItem('cartItems', JSON.stringify(updatedCartItems));
    }
  };


  const clearCart = () => {
    setCartItems([]);
    localStorage.removeItem('cartItems');
  };

  const addFilteredProductsToCart = (filteredProducts) => {
    const storedCartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
    const updatedCartItems = [...storedCartItems];

    // console.log("cart context filtered products ================ ",filteredProducts);

    // Loop through filteredProducts and add them to the cart
    filteredProducts.forEach(product => {
      const existingProductIndex = updatedCartItems.findIndex(item => item.id === product.id);
      if (existingProductIndex !== -1) {
        // If product exists in cart, increase quantity
        // updatedCartItems[existingProductIndex].quantity += 1;
      } else {
        // If product does not exist in cart, add it with quantity 1
        updatedCartItems.push({ ...product, quantity: product.quantity });
      }
    });

    setCartItems(updatedCartItems);
    localStorage.setItem('cartItems', JSON.stringify(updatedCartItems));
  };


  return (
    <CartContext.Provider value={{ cartItems, addToCart, addMultipleToCart, addFilteredProductsToCart, removeFromCart, clearCart, increaseQuantity, decreaseQuantity, setQuantity }}>
      {children}
    </CartContext.Provider>
  );
};
