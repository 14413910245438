import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { useCompanyContext } from '../../Contexts/CompanyContext';
import { loadStripe } from '@stripe/stripe-js';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import './Checkout.css';
import { Button, Checkbox, FormControlLabel } from '@mui/material';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Snackbar, Alert, Grid } from '@mui/material';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import StepBar from '../StepBar/StepBar';
import MobileFooter from '../MobileFooter/MobileFooter';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { FaChevronCircleLeft, FaChevronCircleRight } from "react-icons/fa";
import Swal from 'sweetalert2';
import phonepeLogo from './phonepay.png';
import Footer from '../Footer/Footer';
import NoImg from './No-Image.png';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import toast, { Toaster } from 'react-hot-toast';
import { FaLocationCrosshairs } from "react-icons/fa6";
import { GrMapLocation } from "react-icons/gr";


// Custom arrows
const CustomPrevArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick} style={{ display: 'block', zIndex: 1 }}>
      <FaChevronCircleLeft style={{ color: '#375E97', fontSize: '28px' }} />
    </div>
  );
};

const CustomNextArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick} style={{ display: 'block', zIndex: 1 }}>
      <FaChevronCircleRight style={{ color: '#375E97', fontSize: '28px' }} />
    </div>
  );
};

const decodeBase64Image = (base64) => {
  const decodedImage = new Image();
  decodedImage.src = `data:image/jpeg;base64,${atob(base64)}`;
  return decodedImage.src;
};

const apiUrl = process.env.REACT_APP_API_BASE_URL;
const getRateEndpoint = '/get_rate';
const createCheckoutEndpoint = '/convert-to-sales-order';
const updateOrderEndpoint = '/update-online-sales-order';
const url = `${apiUrl}${getRateEndpoint}`;
const checkouturl = `${apiUrl}${createCheckoutEndpoint}`;
const updateorderurl = `${apiUrl}${updateOrderEndpoint}`;


export const Checkout = () => {
  const navigate = useNavigate();
  const { companyDetails } = useCompanyContext();
  const [addresses, setAddresses] = useState([]);
  const [address, setAddress] = useState(null);
  const [isAddressSelected, setIsAddressSelected] = useState(false);
  const [open, setOpen] = useState(false);
  const [newAddress, setNewAddress] = useState({
    street: '',
    city: '',
    pincode: '',
    country: 'India',
    state: ''
  });
  const [errors, setErrors] = useState({});
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768);
  const { state } = useLocation();
  const filteredProducts = state.filteredProducts;
  const { cartItems = [], totalAmount = 0, additionalData = {} } = state || {};
  const [rate, setRate] = useState(null);
  const isLoggedIn = localStorage.getItem('user');
  const currentUser = JSON.parse(localStorage.getItem('user'));
  const companyName = localStorage.getItem('companyName');
  const [cod, setCod] = useState(false);
  const [phonepe, setPhonepe] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState('');
  const [selectedDelivery, setSelectedDelivery] = useState('');
  const [wishlist, setWishlist] = useState([]);
  const wishlist_endpoint = '/add_to_wishlist';
  const wishlistUrl = `${apiUrl}${wishlist_endpoint}`;
  const removeFromWishlistUrl = `${apiUrl}/remove_from_wishlist`;
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [showRemoveSnackbar, setShowRemoveSnackbar] = useState(false);
  const [location, setLocation] = useState({ latitude: null, longitude: null });
  const [error, setError] = useState('');

  const handlePaymentChange = (event) => {
    setSelectedPayment(event.target.value);
  };

  const handleDeliveryChange = async (event) => {
    const deliveryMethod = event.target.value;
    setSelectedDelivery(deliveryMethod);

    if (deliveryMethod === 'takeaway') {
      setRate(0);
      // setAddress(null);
      // setIsAddressSelected(false);
    } else if (deliveryMethod === 'dod' && address) {
      await fetchRateForAddress(address.id);
    }
  };

  const handlePlaceOrder = () => {
    if (selectedPayment === 'cod') {
      handlePlaceOrderClick(); // Trigger COD order logic
    } else if (selectedPayment === 'phonepe') {
      handlePhonePeOrderClick(); // Trigger PhonePe order logic
    }
  };


  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  useEffect(() => {
    const postPaymentMethod = async () => {
      const companyName = localStorage.getItem('companyName');
      try {
        const response = await axios.post(apiUrl + '/selected/payment/methods', {
          companyName: companyName,
        });
        if (response.data.result.success) {
          setCod(response.data.result.cod_selected)
          setPhonepe(response.data.result.phonepe_selected)
        } else {
        }
      } catch (error) {
        console.error('Error posting payment method:', error);
      }
    };

    postPaymentMethod();
  }, []);

  const validateFields = () => {
    let tempErrors = {};
    if (!newAddress.street) tempErrors.street = "Street is required";
    if (!newAddress.city) tempErrors.city = "City is required";
    if (!newAddress.pincode) tempErrors.pincode = "Pincode is required";
    if (!newAddress.country) tempErrors.country = "Country is required";
    if (!newAddress.state) tempErrors.state = "State is required";
    return tempErrors;
  };

  const handleSnackbarOpen = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const listAddresses = async () => {
    const listAddressesurl = apiUrl + '/list_addresses';
    try {
      const response = await fetch(listAddressesurl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ user: isLoggedIn })
      });

      if (!response.ok) {
        throw new Error('Network response was not ok ' + response.status);
      }

      const addresses = await response.json();
      setAddresses(addresses.result.addresses);
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      listAddresses();
    }
  }, [isLoggedIn, apiUrl]);

  const deleteAddress = async (addressId) => {
    try {
      const response = await fetch(`${apiUrl}/delete_address`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id: addressId, user: isLoggedIn })
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      listAddresses();
      window.location.reload();
    } catch (error) {
      console.error("Failed to delete address:", error);
    }
  }

  const capitalizeWords = (str) => {
    return str
      .toLowerCase()
      .split(" ")
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const reverseGeocode = async (latitude, longitude) => {
    try {
      const apiKey = 'EwaKsVz8Mx4bhaCEvyDcxzN8yMfd7vmTAqDY3emJ';
      const url = `https://api.olamaps.io/places/v1/reverse-geocode?latlng=${latitude},${longitude}&api_key=${apiKey}`;

      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
        },
      });

      // console.log("response in reverse geocode========", response)

      if (!response.ok) {
        throw new Error(`Error: ${response.status} ${response.statusText}`);
      }

      const data = await response.json();
      const formattedAddress = data.results[0].formatted_address;
      const addressComponents = data.results[0].address_components;

      const parseAddress = (formattedAddress) => {
        const parts = formattedAddress.split(",").map((part) => part.trim());
        return {
          country: parts.pop(),
          pincode: parts.pop(),
          state: parts.pop(),
          city: parts.pop(),
          street: parts.join(", ")
        };
      }

      const addressParts = parseAddress(formattedAddress);

      const newAddress = {
        pincode: addressParts.pincode,
        country: addressParts.country,
        state: capitalizeWords(addressParts.state),
        city: addressParts.city,
        street: addressParts.street
      };

      // const newAddress = {
      //   pincode: addressComponents[addressComponents.length - 1].short_name,
      //   country: addressComponents[0].short_name,
      //   state: capitalizeWords(addressComponents[1].short_name),
      //   city: addressComponents[2].short_name,
      //   street: addressComponents.slice(3, addressComponents.length - 1).map(component => component.short_name).join(", ")
      // };

      setNewAddress((prevAddress) => ({
        ...prevAddress,
        ...newAddress
      }));


      // const parseAddress = (address) => {
      //   const parts = address.split(",").map(part => part.trim());
      //   return {
      //     street: parts[0], // e.g., "B 31, Block B, Industrial Area, Sector 62"
      //     city: parts[1],   // e.g., "Noida"
      //     state: parts[2],  // e.g., "Uttar Pradesh"
      //     pincode: parts[3],// e.g., "201309"
      //     country: parts[4] // e.g., "India"
      //   };
      // };

      // const addressParts = parseAddress(formattedAddress);

      // setNewAddress((prevAddress) => ({
      //   ...prevAddress,
      //   street: addressParts.street,
      //   city: addressParts.city,
      //   pincode: addressParts.pincode,
      //   country: addressParts.country,
      //   state: addressParts.state
      // }));

      console.log("response data in reverse geocode========", data)
      console.log("response data in reverse geocode========", data.results[0])
    } catch (err) {
      setError(err.message);
    }
  };

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLocation({ latitude, longitude });
          reverseGeocode(latitude, longitude);
          setError(''); // Clear any previous errors
        },
        (err) => {
          toast.dismiss();
          toast('Please grant permission to access your location.', {
            icon: <GrMapLocation size={40} />,
          });
          // toast.error("Please grant permission to access your location.", { position: "top-center" });
          setError(err.message);
        }
      );
    } else {
      toast.dismiss();
      toast.error("Geolocation is not supported by this browser.", { position: "top-center" });
      setError('Geolocation is not supported by this browser.');
    }
  };

  const submitNewAddress = async () => {
    if (addresses && addresses.length >= 5) {
      handleSnackbarOpen("Cannot add more addresses. Please delete an existing address.");
      return;
    }

    const tempErrors = validateFields();
    if (Object.keys(tempErrors).length > 0) {
      setErrors(tempErrors);
      return;
    }

    try {
      const response = await fetch(apiUrl + '/add_address', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ newAddress: newAddress, user: isLoggedIn })
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      handleClose();
      listAddresses();
      setNewAddress({
        street: '',
        city: '',
        pincode: '',
        country: 'India',
        state: ''
      }
      )
      // window.location.reload();
    } catch (error) {
      console.error("Failed to add address:", error);
    }
  };

  const handleOpen = (event) => {
    event.preventDefault();
    if (addresses && addresses.length >= 5) {
      handleSnackbarOpen("Cannot add more addresses. Please delete an existing address.");
      return;
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setErrors({});
  };

  const handleChange = (e) => {
    setNewAddress({
      ...newAddress,
      [e.target.name]: e.target.value
    });
    if (errors[e.target.name]) {
      setErrors({
        ...errors,
        [e.target.name]: null
      });
    }
  };

  // const handleSelectAddress = async (selectedAddress) => {
  //   setAddress(selectedAddress);
  //   setIsAddressSelected(true);

  //   const address_id = selectedAddress.id;

  //   console.log("address in checkout ==========", address_id);
  //   console.log("companyName in checkout ==========", companyName);

  //   try {
  //     const response = await fetch(apiUrl + '/api/get_distance_between_addresses', {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify({
  //         companyName: companyName,
  //         address_2: address_id,
  //       }),
  //     });

  //     const data = await response.json();

  //     if (response.ok) {
  //       console.log("Distance Data:", data);
  //       if (data.result.price) {
  //         setRate(data.result.price);
  //       } else {
  //         setRate(0)
  //       }
  //     } else {
  //       console.error("Error:", data.error || "Unknown error occurred");
  //     }
  //   } catch (error) {
  //     console.error("Error in API call:", error);
  //   }
  // };

  const handleSelectAddress = async (selectedAddress) => {
    setAddress(selectedAddress);
    setIsAddressSelected(true);

    if (selectedDelivery === 'dod') {
      await fetchRateForAddress(selectedAddress.id);
    }
  };

  const fetchRateForAddress = async (address_id) => {
    try {
      const response = await fetch(apiUrl + '/api/get_distance_between_addresses', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          companyName: companyName,
          address_2: address_id,
        }),
      });

      const data = await response.json();

      if (response.ok) {
        setRate(data.result.price || 0);
      } else {
        console.error("Error:", data.error || "Unknown error occurred");
        setRate(0);
      }
    } catch (error) {
      console.error("Error in API call:", error);
      setRate(0);
    }
  };

  const handlePlaceOrderClick = () => {
    if (!isAddressSelected && selectedDelivery === "dod") {
      handleSnackbarOpen("Please select a delivery address");
      return;
    }
    makePayment(); // Proceed to place the order if an address is selected
  };

  const handlePhonePeOrderClick = () => {
    if (!isAddressSelected && selectedDelivery === "dod") {
      handleSnackbarOpen("Please select a delivery address");
      return;
    }
    // initiatePhonepePayment(); 
    placeOrderPhonePe();
  };

  const handlePincodeChange = (event) => {
    const { value, name } = event.target;
    // Regular expression to match numbers and specific characters such as hyphen and space
    const cleanedValue = value.replace(/[^0-9-\s]+/g, ''); // Adjust regex as needed for your use case
    setNewAddress(prev => ({
      ...prev,
      [name]: cleanedValue
    }));

    if (errors[name]) {
      setErrors({
        ...errors,
        [name]: null
      });
    }
  };



  const makePayment = async () => {
    const body = {
      products: cartItems.map(item => ({
        product_id: item.id,
      })),
      quotation_details: {
        orders: additionalData.result.orders.map(order => ({
          order_id: order.order_id,
          code: order.order_name,
        })),
      },
      address: address,
      rate: rate,
      user: JSON.parse(isLoggedIn),
      user_id: currentUser.user_id,
      deliveryMethod: selectedDelivery,
    }

    const headers = {
      'Content-Type': 'application/json',
    }

    const response = await fetch(checkouturl, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(body)
    });

    const responseData = await response.json();

    if (responseData.result.status === 'success') {
      localStorage.removeItem('cartItems');
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: "Your order has been placed successfully",
        customClass: {
          confirmButton: 'custom-confirm-button'
        }
      }).then(() => {
        setTimeout(() => {
          navigate('/myorders');
          window.location.reload();
        }, 1000);
      });
    }

  };

  // const placeOrderPhonePe = async () => {
  //   const body = {
  //     quotation_details: {
  //       orders: additionalData.result.orders.map(order => ({
  //         order_id: order.order_id,
  //         code: order.order_name,
  //       })),
  //     },
  //     rate: totalAmount,
  //     user_id: currentUser.user_id,
  //   };
  //   try {
  //     const response = await axios.post(apiUrl + '/send/order_details/phonepe', body);
  //     console.log(response.data);
  //     if (response.data.result.success) {
  //       window.location.href = apiUrl + response.data.result.odoo_page_url;
  //     } else {
  //       console.log("error fetching payment details");
  //     }
  //   } catch (error) {
  //     console.error("error ==== ", error);
  //   }
  // }

  const placeOrderPhonePe = async () => {
    const body = {
      quotation_details: {
        orders: additionalData.result.orders.map(order => ({
          order_id: order.order_id,
          code: order.order_name,
        })),
      },
      rate: totalAmount,
      user_id: currentUser.user_id,
      address: address,
      deliveryCharge: rate,
      companyName: companyName,
    };

    const updateBody = {
      products: cartItems.map(item => ({
        product_id: item.id,
      })),
      quotation_details: {
        orders: additionalData.result.orders.map(order => ({
          order_id: order.order_id,
          code: order.order_name,
        })),
      },
      address: address,
      rate: rate,
      user: JSON.parse(isLoggedIn),
      user_id: currentUser.user_id,
      deliveryMethod: selectedDelivery,
    }

    const headers = {
      'Content-Type': 'application/json',
    }

    const response = await fetch(updateorderurl, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(updateBody)
    });

    const responseData = await response.json();

    const queryParams = new URLSearchParams({
      quotation_details: JSON.stringify(body.quotation_details),
      rate: body.rate,
      user_id: body.user_id,
      address: body.address,
      deliveryCharge: body.deliveryCharge,
      companyName: body.companyName,
    }).toString();

    // const url = `http://localhost:6008/order_summary?${queryParams}`;
    const url = `https://www.o2btechnologies.com/order_summary?${queryParams}`;

    window.location.href = url;
  };


  const initiatePhonepePayment = async () => {
    const body = {
      quotation_details: {
        orders: additionalData.result.orders.map(order => ({
          order_id: order.order_id,
          code: order.order_name,
        })),
      },
      rate: totalAmount,
      user_id: currentUser.user_id,
    };

    try {
      const response = await axios.post(apiUrl + '/initiate/payment/phonepe', body);
      const paymentData = response.data.result;
      if (paymentData.success) {
        window.location.href = paymentData.pay_page_url;
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Payment Error',
          text: 'Payment initiation failed. Please try again.',
          customClass: {
            confirmButton: 'custom-confirm-button'
          }
        });
      }

      if (paymentData.success && paymentData.data.instrumentResponse) {
        const redirectUrl = paymentData.data.instrumentResponse.redirectInfo.url;
        const redirectMethod = paymentData.data.instrumentResponse.redirectInfo.method;

        if (redirectMethod === 'GET') {
          window.location.href = redirectUrl;
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Unsupported payment method. Please try again.',
            customClass: {
              confirmButton: 'custom-confirm-button'
            }
          });
        }
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Payment Error',
          text: 'Payment initiation failed. Please try again.',
          customClass: {
            confirmButton: 'custom-confirm-button'
          }
        });
      }
    } catch (error) {
      console.error("Payment initiation failed", error.response ? error.response.data : error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'An error occurred while initiating the payment.',
        customClass: {
          confirmButton: 'custom-confirm-button'
        }
      });
    }
  };


  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    arrows: true,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  // Function to group products into chunks of 4
  const groupProductsForMobile = (products) => {
    const groupedProducts = [];
    for (let i = 0; i < products.length; i += 4) {
      groupedProducts.push(products.slice(i, i + 4));
    }
    return groupedProducts;
  };

  const mobileGroupedProducts = groupProductsForMobile(filteredProducts);

  const truncateNameForURL = (name, maxLength) => {
    const words = name.split(' ');
    if (words.length <= maxLength) {
      return words.join('-'); // If the number of words is less than or equal to maxLength, use all words
    }
    return words.slice(0, maxLength).join('-'); // Otherwise, use only the first maxLength words
  };

  const fetchWishlist = async () => {
    const currentUser = JSON.parse(localStorage.getItem('user'));

    const response = await fetch(`${apiUrl}/check_wishlist/user/app`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ user_id: currentUser.user_id })
    });

    if (response.ok) {
      const result = await response.json();
      const parsedResult = JSON.parse(result.result);
      setWishlist(parsedResult.wishlist);
    } else {
      console.error('Failed to fetch wishlist');
    }
  };

  useEffect(() => {
    if (currentUser) {
      fetchWishlist();
    }
  }, []);

  const wishlistNames = new Set(wishlist.map(item => item.product_name));

  const addToWishlist = async (productDetail) => {
    try {
      const currentUserData = localStorage.getItem('user');
      const currentUser = JSON.parse(currentUserData);

      if (currentUser && currentUser.user_id) {
        const response = await fetch(wishlistUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ user_id: currentUser.user_id, product_detail: productDetail }),
        });
        if (!response.ok) {
          throw new Error('Failed to add to wishlist');
        }
        setShowSnackbar(true);
        fetchWishlist();
      } else {
        console.error('User ID not found in currentUser data');
        Swal.fire({
          html: `
          Hi there! Log in to your account or create one today to enjoy a personalized shopping experience!
          `,
          showCloseButton: false,
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonText: `
            Login
          `,
          confirmButtonAriaLabel: "Login",
          cancelButtonText: `
            Register
          `,
          cancelButtonAriaLabel: "Register",
          customClass: {
            confirmButton: 'custom-confirm-button',
            cancelButton: 'custom-confirm-button'
          }
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = '/login'; // Redirect to login page
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            window.location.href = '/register'; // Redirect to register page
          }
        });
        // navigate('/login');
      }
    } catch (error) {
      console.error('Error adding to wishlist:', error);
    }
  };

  const removeFromWishlist = async (productDetail) => {
    try {
      const currentUserData = localStorage.getItem('user');
      const currentUser = JSON.parse(currentUserData);

      if (currentUser && currentUser.user_id) {
        const response = await fetch(removeFromWishlistUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ user_id: currentUser.user_id, product_detail: productDetail }),
        });

        if (!response.ok) {
          throw new Error('Failed to remove from wishlist');
        }
        setShowRemoveSnackbar(true);
        fetchWishlist();
      } else {
        console.error('User ID not found in currentUser data');
        navigate('/login');
      }
    } catch (error) {
      console.error('Error removing from wishlist:', error);
    }
  };


  return (
    <>
      <main style={{ padding: isMobileView ? '0px 20px' : '0px 80px' }}>
        <div className="dlvr_adds" style={{ paddingTop: '100px', marginTop: '0px' }}>
          <div className="container-fluid">
            <StepBar />
            <div className="row">
              <div className="col-lg-8">
                <div className="dlvr_contt">
                  <h2>Select Shipping Address</h2>
                  <form className="shipp_address">
                    {addresses && addresses.map((address, index) => (
                      <div className="form-group" key={index}>
                        <div style={{ padding: '10px 5px 8px 5px' }}>
                          <input
                            type="radio"
                            name="accent-group"
                            className="accent-black black accent-color"
                            id={`accent-black${index}`}
                            onChange={() => handleSelectAddress(address)}
                            style={{ display: 'inline', width: 'auto' }}
                          />
                          <label htmlFor={`accent-black${index}`} style={{ display: 'inline', cursor: 'pointer' }}>
                            {address.street}, {address.city}, {address.zip}, {address.country}
                          </label>
                        </div>
                        <div style={{ padding: '6px 5px 8px 0px' }}>
                          <Button variant='outlined' color='error' size='small'
                            onClick={() => deleteAddress(address.id)}
                            sx={{ marginTop: '5px', height: '24px' }}>Delete</Button>
                        </div>
                      </div>
                    ))}
                    <div className="form__confirmation">
                      <Button variant='contained' size='small' onClick={handleOpen}>+ Add New Address</Button>
                    </div>
                  </form>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="ship-cart">
                  <div className="ship-cart-pr">
                    <h3>Summary</h3>
                    <ul>
                      <li>
                        <label>Subtotal</label>
                        <div className="value pull-right">₹{totalAmount.toFixed(2)}</div>
                      </li>
                      <li>
                        <label>Delivery Fee</label>
                        <div className="value pull-right">₹{rate ? rate.toFixed(2) : '00.00'}</div>
                      </li>
                      {/* <li>
                      <label>Payment Type</label>
                      <div className="value pull-right" >
                        COD
                      </div>
                    </li> */}
                      <li className="hr-border"></li>
                      <li>
                        <label>Total</label>
                        <div className="value pull-right" style={{ fontSize: 24, color: "#375E97" }}>
                          ₹{(totalAmount + (rate ? rate : 0)).toFixed(2)}
                        </div>
                      </li>
                    </ul>
                    <div className="ship-cart-shop">
                      <p style={{ marginBottom: 0 }}><b>Select Delivery Type:</b></p>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={selectedDelivery === 'takeaway'}
                            onChange={handleDeliveryChange}
                            value="takeaway"
                          />
                        }
                        label="Takeaway"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={selectedDelivery === 'dod'}
                            onChange={handleDeliveryChange}
                            value="dod"
                          />
                        }
                        label="Delivery at Door"
                      />
                    </div>

                    {/* {selectedDelivery === 'takeaway' &&
                    <div className="ship-cart-shop">
                      <p style={{ marginBottom: 0 }}><b>Dukan Address</b></p>
                      <p>{companyDetails?.company_address?.street},{companyDetails?.company_address?.city},{companyDetails?.company_address?.zip}, {companyDetails?.company_address?.country}</p>
                    </div>
                  } */}

                    <div className="ship-cart-shop">
                      <p style={{ marginBottom: 0 }}><b>Select Payment Method:</b></p>
                      {cod && (
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={selectedPayment === 'cod'}
                              onChange={handlePaymentChange}
                              value="cod"
                            // disabled={!isAddressSelected} 
                            />
                          }
                          label="Cash on Delivery (COD)"
                        />
                      )}
                      {phonepe && (
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={selectedPayment === 'phonepe'}
                              onChange={handlePaymentChange}
                              value="phonepe"
                            // disabled={!isAddressSelected} 
                            />
                          }
                          label={
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <img src={phonepeLogo} alt="PhonePe" style={{ width: '20px', marginRight: '8px' }} />
                              PhonePe
                            </div>
                          }
                        />
                      )}
                      {selectedPayment && selectedDelivery && (
                        <Button
                          variant="contained"
                          size="medium"
                          onClick={handlePlaceOrder}
                          style={{ backgroundColor: !isAddressSelected && selectedDelivery === "dod" ? 'grey' : '#ff7043', marginTop: '10px' }}
                          sx={{ width: '100%' }}
                        >
                          Place Order
                        </Button>
                      )}
                      {/* {cod && (
                      <Button
                        variant='contained'
                        size='medium'
                        onClick={handlePlaceOrderClick}
                        style={{ backgroundColor: !isAddressSelected ? 'grey' : '#375E97' }}
                        sx={{ width: '100%' }}
                      >
                        Place Order (COD)
                      </Button>
                    )}
                    {phonepe && (
                      <Button
                        variant='contained'
                        size='medium'
                        onClick={handlePhonePeOrderClick}
                        style={{ backgroundColor: !isAddressSelected ? 'grey' : '#375E97', marginTop: '5px' }}
                        sx={{ width: '100%' }}
                      >
                        Pay With PhonePe
                      </Button>
                    )} */}
                      {/* <Button variant='contained'
                      size='medium'
                      // onClick={makePayment}
                      onClick={handlePlaceOrderClick}
                      style={{ backgroundColor: !isAddressSelected ? 'grey' : '#375E97' }}
                      // disabled={!isAddressSelected}
                      sx={{ width: '100%' }}>
                      Place Order(COD)</Button>
                    <br />
                    <Button variant='contained'
                      size='medium'
                      onClick={handlePhonePeOrderClick}
                      style={{ backgroundColor: !isAddressSelected ? 'grey' : '#375E97', marginTop: '5px' }}
                      sx={{ width: '100%' }}>
                      Pay With Phonepe</Button> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Add New Address</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="street"
              name="street"
              label="Street"
              type="text"
              fullWidth
              required
              value={newAddress.street}
              onChange={handleChange}
              error={!!errors.street}
              helperText={errors.street}
            />
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  autoFocus
                  margin="dense"
                  id="city"
                  name="city"
                  label="City"
                  type="text"
                  fullWidth
                  required
                  value={newAddress.city}
                  onChange={handleChange}
                  error={!!errors.city}
                  helperText={errors.city}
                  sx={{ marginBottom: '15px' }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  margin="dense"
                  id="pincode"
                  name="pincode"
                  label="Pincode"
                  type="text"
                  fullWidth
                  required
                  value={newAddress.pincode}
                  onChange={handlePincodeChange}
                  error={!!errors.pincode}
                  helperText={errors.pincode}
                  sx={{ marginBottom: '15px' }}
                  inputProps={{ maxLength: 6 }}
                />
              </Grid>
            </Grid>
            <RegionDropdown
              country={newAddress.country}
              value={newAddress.state}
              onChange={(val) => setNewAddress({ ...newAddress, state: val })}
              classes="form-control"
              blankOptionLabel="Select State"
              style={{
                borderColor: errors.state ? 'red' : undefined, height: '56px',
                padding: '10px 14px', borderRadius: '4px'
              }}
            />
            {errors.state && <p style={{
              color: '#d32f2f', fontSize: '0.75rem', marginLeft: '14px', fontWeight: '400',
              marginTop: '3px'
            }}>{errors.state}</p>}
            <CountryDropdown
              value={newAddress.country}
              disabled
              onChange={(val) => setNewAddress({ ...newAddress, country: val })}
              classes="form-control"
              style={{
                borderColor: errors.country ? 'red' : undefined, height: '56px',
                padding: '10px 14px', borderRadius: '4px', marginTop: '15px'
              }}
            />
            {errors.country && <p style={{
              color: '#d32f2f', fontSize: '0.75rem', marginLeft: '14px', fontWeight: '400',
              marginTop: '3px'
            }}>{errors.country}</p>}
          </DialogContent>
          <DialogActions>
            <div style={{ width: '57%', textAlign: 'start', color: '#1976d2', cursor: 'pointer', fontWeight: '600', alignItems: 'baseline' }} onClick={getLocation} color="primary"><FaLocationCrosshairs /> Get Current Location</div>
            <Button onClick={handleClose} color="primary">Cancel</Button>
            <Button onClick={submitNewAddress} color="primary">Add Address</Button>
          </DialogActions>
        </Dialog>
        <Snackbar open={snackbarOpen} autoHideDuration={2000} onClose={handleSnackbarClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
          <Alert onClose={handleSnackbarClose} severity="error" sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
        <div>
          <br />
          <h3 style={{ textAlign: 'center' }}>Related products</h3>
          <br />
          <div className="desktop-carousel">
            <Slider {...sliderSettings}>
              {filteredProducts.map((item, index) => {
                const isInWishlist = wishlistNames.has(item.name);
                return (
                  <div key={index} className="item">
                    <div className="shop1">

                      <div className="image-containers">
                        {item.sold && <div className="ribbon">Sold Out</div>}
                        <div className="heart-icon">
                          {isInWishlist ? (
                            <FavoriteIcon style={{ color: 'red', marginLeft: '7px' }} onClick={() => removeFromWishlist(item)} />
                          ) : (
                            <FavoriteBorderIcon style={{ color: 'red', marginLeft: '7px' }} onClick={() => addToWishlist(item)} />
                          )}
                        </div>
                        <Link
                          to={`/${item.category_hierarchy.join('/')}?product=${encodeURIComponent(truncateNameForURL(item.name, 4))}-${encodeURIComponent(item.id)}`}
                          style={{ textDecoration: 'none', color: 'inherit' }}>
                          <img
                            src={item.image ? decodeBase64Image(item.image) : NoImg}
                            alt={item.name}
                            loading="lazy"
                            className="img-fluid"
                            style={{
                              objectFit: 'cover',
                              borderRadius: 0,
                              height: '200px',
                              width: '100%',
                            }}
                          />
                        </Link>
                      </div>
                      <div className="shop-content">
                        <h3>{item.name}</h3>
                        <p style={{ fontSize: '13px', margin: '0px 0px' }}>{item.product_label ? item.product_label : "1 piece"}</p>
                        <span className="old-price" style={{ fontSize: '16px', color: '#007BFF', fontWeight: '600' }}>
                          ₹{item.price}
                        </span>
                      </div>
                    </div>
                  </div>
                )
              })}
            </Slider>
          </div>

          {/* Mobile View Display 4 Products in One Div */}
          <div className="mobile-carousel">
            <Slider {...sliderSettings}>
              {mobileGroupedProducts.map((group, index) => (
                <div key={index} className="mobile-slide">
                  <div className="mobile-grid">
                    {group.map((item, idx) => (
                      <div key={idx} className="mobile-item">
                        <Link
                          to={`/${item.category_hierarchy.join('/')}?product=${encodeURIComponent(truncateNameForURL(item.name, 4))}-${encodeURIComponent(item.id)}`}
                          style={{ textDecoration: 'none', color: 'inherit' }}>
                          <img
                            src={item.image ? decodeBase64Image(item.image) : NoImg}
                            alt={item.name}
                            loading="lazy"
                            className="img-fluid"
                            style={{
                              objectFit: 'contain',
                              height: '120px',
                              width: '100%',
                            }}
                          />
                          <div className="mobile-shop-content">
                            <h3>{item.name}</h3>
                            <h3>₹ {item.price}</h3>
                          </div>
                        </Link>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>

      </main>
      <br />
      <br />
      <Footer />
      {isMobileView && (
        <MobileFooter />
      )}

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={showSnackbar}
        autoHideDuration={1000}
        onClose={() => setShowSnackbar(false)}
        message="Added to Wishlist"
      />
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={showRemoveSnackbar}
        autoHideDuration={1000}
        onClose={() => setShowRemoveSnackbar(false)}
        message="Removed from Wishlist"
      />

      <Toaster />
    </>
  );
};

export default Checkout;
